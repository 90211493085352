import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AXIOS_INSTANCE } from '../../service';
import { BASE_URL_BANKING } from '../../utils/constants';

const AddAccount = ({ onClose, getPaymentDetails }) => {
  const toast = useToast();
  const navigate = useNavigate();
  const Close = () => {
    onClose();
  };
  const [fullName, setFullName] = useState('');
  const [usdtAddress, setUsdtAddress] = useState('');
  const [upiId, setUpiId] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const [bankName, setBankName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [ifscCode, setIFSCCode] = useState('');
  const [accountHolderName, setAccountHolderName] = useState('');
  const [selectedOption, setSelectedOption] = useState(''); // State to track the selected option

  // Handler function to update the selected option
  const handleSelectChange = (event) => {
    if (event.target.value === 'NULL') {
      setSelectedOption('');
    } else {
      setSelectedOption(event.target.value);
    }
  };

  const handleSubmit = async () => {
    //e.preventDefault();

    // Prepare the data to send to the API
    const formData = {
      userName: fullName,
      paymentType: selectedOption,
      value: upiId,
      mobile_number: phoneNumber,
    };

    if (selectedOption === 'USDT') {
      formData.value = usdtAddress;
      delete formData.userName;
    }

    // Assuming you have an Axios instance
    try {
      // Make a POST request to your API endpoint
      const response = await AXIOS_INSTANCE.post(
        `${BASE_URL_BANKING}/api/acc/payment-details`,
        formData
      );

      // Handle the response as needed
      console.log('UpiResponse:', response);
      if (response.status == 200) {
        toast({
          title: 'Account Added Successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });

        onClose();
        getPaymentDetails();
        //navigate to withdrawl page
      }
    } catch (error) {
      // Handle errors
      console.error('Error:', error);
    }
  };

  const handleBankSubmit = async () => {
    //e.preventDefault();

    // Prepare the data to send to the API
    const payload = {
      bankName,
      accountNumber,
      ifscCode,
      userName: accountHolderName,
      paymentType: selectedOption,
    };

    // Assuming you have an Axios instance
    try {
      // Make a POST request to your API endpoint
      const response = await AXIOS_INSTANCE.post(
        `${BASE_URL_BANKING}/api/acc/payment-details`,
        payload
      );

      // Handle the response as needed
      console.log('Response:', response);
      if (response.data.statusCode == 200) {
        toast({
          title: 'Account Added Successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });

        onClose();
        getPaymentDetails();
      } else {
        toast({
          title: response.data.message || 'Error occured while adding account',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      // Handle errors
      console.error('Error:', error);
    }
  };

  return (
    <Box p={'25px'}>
      <Text>Adding bank details is necessary</Text>
      <Select mt='10px' mb='15px' onChange={handleSelectChange}>
        <option selected value='NULL'>
          Select Bank Type
        </option>
        <option value='BANK_SAVING'>Saving Bank</option>
        <option value='CURRENT_BANK'>Current Bank</option>
        <option value='UPI_PAYTM'>Paytm</option>
        <option value='UPI_GPAY'>Gpay</option>
        <option value='UPI_PHONEPE'>Phone Pe</option>
        <option value='UPI_BHIM'>Bhim</option>
        <option value='USDT'>USDT</option>
      </Select>
      {selectedOption &&
        (selectedOption === 'BANK_SAVING' ||
        selectedOption === 'CURRENT_BANK' ? (
          <Flex flexDir={'column'} gap={'20px'}>
            <FormControl>
              <FormLabel>Bank Name*</FormLabel>
              <Input
                type='text'
                placeholder='Enter Bank Name eg: HDFC'
                value={bankName}
                onChange={(e) => setBankName(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Account Number*</FormLabel>
              <Input
                type='number'
                placeholder='Enter Account Number'
                value={accountNumber}
                onChange={(e) => setAccountNumber(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>IFSC Code*</FormLabel>
              <Input
                type='text'
                placeholder='Enter IFSC Code'
                value={ifscCode}
                onChange={(e) => setIFSCCode(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Account Holder Name*</FormLabel>
              <Input
                type='text'
                placeholder='Enter Account Holder Name'
                value={accountHolderName}
                onChange={(e) => setAccountHolderName(e.target.value)}
              />
            </FormControl>
            <Button
              h={'50px'}
              mt={'10px'}
              className='green-button'
              onClick={handleBankSubmit}
              disabled={
                !bankName || !accountNumber || !ifscCode || !accountHolderName
              }
            >
              Submit
            </Button>
          </Flex>
        ) : selectedOption === 'USDT' ? (
          <Flex flexDir={'column'} gap={'20px'}>
            <FormControl>
              <FormLabel>USDT Address*</FormLabel>
              <Input
                type='text'
                placeholder='Enter USDT address'
                value={usdtAddress}
                onChange={(e) => setUsdtAddress(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Phone Number*</FormLabel>
              <Input
                type='text'
                placeholder='Enter Phone Number'
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </FormControl>
            <Button
              h={'50px'}
              mt={'10px'}
              className='green-button'
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Flex>
        ) : (
          <Flex flexDir={'column'} gap={'20px'}>
            <FormControl>
              <FormLabel>Full Name*</FormLabel>
              <Input
                type='text'
                placeholder='Enter Your Name'
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>UPI ID*</FormLabel>
              <Input
                type='text'
                placeholder='Enter UPI Id'
                value={upiId}
                onChange={(e) => setUpiId(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Phone Number*</FormLabel>
              <Input
                type='text'
                placeholder='Enter Phone Number'
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </FormControl>

            <Button
              h={'50px'}
              mt={'10px'}
              className='green-button'
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Flex>
        ))}
    </Box>
  );
};

export default AddAccount;
