import React, { useState } from "react";
import { Stack } from "@mui/material";
import { groupedImages } from "./index.utils";
import "../../../src/assets/styles/css/liveCasino.scss";
import CardGamesList from "../../pages/casino/CardGamesList";
import { ASSETS_URL } from "../../utils/constants";
import { Grid, GridItem, Image } from "@chakra-ui/react";

const CardGameImages = () => {
  const [casinoList, setCasinoList] = React.useState([
    {
      name: "Dragon Tiger-1 2020",
      image: "dt20.jpg",
      link: "/live-casino/dragon-tiger/20-20",
      group: "dragon-tiger",
    },
    {
      name: "Dragon Tiger 2020 2",
      image: "dt202.jpg",
      link: "/live-casino/dragon-tiger/20-20/2",
      group: "dragon-tiger",
    },
    {
      name: "Dragon Tiger 1 Day",
      image: "dt6.jpg",
      link: "/live-casino/dragon-tiger/1-day",
      group: "dragon-tiger",
    },
    {
      name: "Dragon Tiger Lion",
      image: "dtl20.jpg",
      link: "/live-casino/dragon-tiger/dtl",
      group: "dragon-tiger",
    },
    {
      name: "Teen Patti 2020",
      image: "teen20.jpg",
      link: "/live-casino/teenpatti/20-20",
      group: "teenpatti",
    },

    {
      name: "Teen Patti Test",
      image: "teen9.jpg",
      link: "/live-casino/teenpatti/test",
      group: "teenpatti",
    },
    {
      name: "Teen Patti Open",
      image: "teen8.jpg",
      link: "/live-casino/teenpatti/open",
      group: "teenpatti",
    },
    {
      name: "Casino Queen",
      image: "queen.jpg",
      link: "/live-casino/cn/queen",
      group: "casino",
    },
    {
      name: "32 Cards A",
      image: "card32.jpg",
      link: "/live-casino/card-32/a",
      group: "32-cards",
    },
    {
      name: "32 Cards B",
      image: "card32eu.jpg",
      link: "/live-casino/card-32/b",
      group: "32-cards",
    },

    {
      name: "Poker 20-20",
      image: "poker20 20.jpg",
      link: "/live-casino/poker/20-20",
      group: "poker",
    },
    {
      name: "Andar Bahar",
      image: "andar-bahar.jpg",
      link: "/live-casino/andar-bahar",
      group: "andar-bahar",
    },
    {
      name: "Andar Bahar 2",
      image: "andar-bahar2.jpg",
      link: "/live-casino/andar-bahar/2",
      group: "andar-bahar",
    },
    {
      name: "Baccart",
      image: "baccarat.jpg",
      link: "/live-casino/baccarat",
      group: "baccarat",
    },
    {
      name: "Baccart 2",
      image: "baccarat2.jpg",
      link: "/live-casino/baccarat/2",
      group: "baccarat",
    },
    {
      name: "1 Day Teen Patti",
      image: "teen.jpg",
      link: "/live-casino/teenpatti/1-day",
      group: "teenpatti",
    },
    {
      name: "Poker 6 Players",
      image: "poker6.jpg",
      link: "/live-casino/poker/poker-6-player",
      group: "poker",
    },
  ]);

  return (
    <>
      {/* <div className="main-live-stack">

      <CardGamesList  className="live-img" />
    </div> */}

      <Grid
        mt={{ base: "1px !important", md: "0px" }}
        gap={2}
        templateColumns={{ base: "repeat(3, 1fr)", md: "repeat(4, 1fr)" }}
        className="main-live-stack"
      >
        {casinoList.map((casino, index) => (
          <GridItem
            onClick={() => {
              window.location.href = casino.link;
            }}
            cursor="pointer"
            position="relative"
          >
            <Image
              h="100"
              width={"100%"}
              src={ASSETS_URL + casino.image}
              // className="live-img"
            />
          </GridItem>
        ))}
      </Grid>

      {/* {gamesList.length > 0 && (
        <div>
          {gamesList.map((game, index) => (
            <Stack key={index} direction="row" className="main-live-stack">
              <img
                src={ASSETS_URL + game.image}
                alt={game.name}
                className="live-img" 
              />
            </Stack>
          ))}
        </div>
      )} */}
    </>
  );
};

export default CardGameImages;
