import { useEffect, useState } from 'react';

export const useGenericTable = (initialRowData) => {
  const [perPageData, setPerPageData] = useState(10);
  const [radioValue, setRadioValue] = useState('all');
  const [tableFilteredRowData, setTableFilteredRowData] =
    useState(initialRowData);
  const [currentPage, setCurrentPage] = useState(1);

  const startIndex = (currentPage - 1) * perPageData;
  const endIndex = startIndex + perPageData;

  const handleDropdownChange = (event) => {
    setPerPageData(Number(event.target.value));
    setCurrentPage(1);
  };

  useEffect(() => {
    setTableFilteredRowData(initialRowData);
    // setCurrentPage(1);
  }, [initialRowData]);

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
    setCurrentPage(1);
  };

  const handleAllPreviousClick = () => {
    if (currentPage - 1 > 0) setCurrentPage(1);
  };

  const handlePreviousClick = () => {
    if (currentPage - 1 > 0) setCurrentPage(currentPage - 1);
  };

  const handleAllNextClick = () => {
    if (currentPage < Math.ceil(initialRowData.length / perPageData))
      setCurrentPage(Math.ceil(initialRowData.length / perPageData));
  };

  const handleNextClick = () => {
    if (currentPage < Math.ceil(initialRowData.length / perPageData))
      setCurrentPage(currentPage + 1);
  };

  return {
    perPageData,
    radioValue,
    tableFilteredRowData,
    setTableFilteredRowData,
    currentPage,
    handleDropdownChange,
    handleRadioChange,
    handleAllPreviousClick,
    handlePreviousClick,
    handleAllNextClick,
    handleNextClick,
    startIndex,
    endIndex,
  };
};
