/* eslint-disable jsx-a11y/iframe-has-title */
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Grid,
  GridItem,
  HStack,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams, useSearchParams } from "react-router-dom";

import Footer from "../../includes/footer";
import {
  getCricketEventDetailsAPI,
  getCricketScorecard,
  getMatchDetailsCricketAPI,
} from "../../service/cricket";
import {
  getMatchDetailsSoccerAPI,
  getMatchDetailsTennisAPI,
  getSoccerEventDetailsAPI,
  getSoccerOddsByMatchIdAPI,
  getTennisEventDetailsAPI,
  getTennisOddsByMatchIdAPI,
} from "../../service/soccer&tennis";
import {
  bgColor,
  buttonColors,
  createSocket,
  getProfit,
} from "../../utils/constants";
import PlaceBet from "./PlaceBet";
import MatchOdds_ from "./Ui/MatchOdds_";
import "./css/scorecard.css";
import moment from "moment";
import tennisScoreCardBg from "../../assets/scoreboard-bg.png";
import { useMemo } from "react";
import Iframe from "react-iframe";
import IframeComponent from "../../components/Iframe";
import isEmpty from "is-empty";
export default function GamePage() {
  var { id, game } = useParams();
  const location = useLocation();
  let [searchParams, setSearchParams] = useSearchParams();
  if (!id || !game) {
    var path = location.pathname.split("/");
    id = path[path.length - 1];
    game = path[path.length - 2];
  }
  const [gameEvent, setGameEvent] = useState({});
  const [scorecard, setScorecard] = useState({ data: [] });
  const dispatch = useDispatch();
  const gameOdds_ = useSelector((state) => state.gameData[game + "Odds"][id]);
  const gameOdds = useMemo(() => gameOdds_, [gameOdds_]);
  var gameSettings_ = useSelector((state) => state.gameData[game + "Settings"]);
  var gameSettings = useMemo(() => gameSettings_, [gameSettings_]);
  if (gameSettings && gameSettings[id]) {
    gameSettings = gameSettings[id];
  }
  const userData_ = useSelector((state) => state.account.userData);
  const userData = useMemo(() => userData_, [userData_]);

  // extracting sport from URL
  // const pathname = location.pathname;
  // let sport = '';
  // const gameIndex = pathname.indexOf('/game/');
  // if (gameIndex !== -1) {
  //     const subPath = pathname.substring(gameIndex + 6);
  //     const parts = subPath.split('/');
  //     if (parts.length > 0) {
  //         sport = parts[0];
  //     }
  // }

  const getSoccerOddsByEventId = useCallback(async () => {
    const res = await getSoccerOddsByMatchIdAPI(id);
    if (res.code === 200) {
      let response_ = {};
      response_[id] = res.response;
      dispatch({
        type: "gameData/setSoccerOdds",
        payload: response_,
      });
    }
  }, [id]);

  const getGameEventDetails = useCallback(async () => {
    if (game == "soccer") {
      const res = await getSoccerEventDetailsAPI(id);

      if (res.code === 200) {
        setGameEvent(res.response);
      }
    } else if (game == "tennis") {
      const res = await getTennisEventDetailsAPI(id);
      if (res.code === 200) {
        setGameEvent(res.response);
      }
    } else if (game == "cricket") {
      const res = await getCricketEventDetailsAPI(id);

      if (res.code === 200) {
        setGameEvent(res.response);
      }
    }
  }, [id, game]);

  useEffect(() => {
    dispatch({
      type: "accountData/setLoading",
      payload: true,
    });
    /*  if (game === "soccer") {
      getSoccerOddsByEventId();
    } else if (game === "tennis") {
      getTennisOddsByMatchId();
    } */

    getGameEventDetails();

    /*  let pollingInterval = null;
    if (game != "cricket") {
      pollingInterval = setInterval(() => {
        if (game === "soccer") {
          getSoccerOddsByEventId();
        } else {
          getTennisOddsByMatchId();
        }
      }, 1000);
    } else { */
    let socket = createSocket(game, dispatch, id);
    /*  } */
    /* const interval = setInterval(() => {
      dispatch({
        type: "accountData/setLoading",
        payload: false,
      });
    }, 3000); */
    return () => {
      /*  clearInterval(interval);
      clearInterval(pollingInterval); */
      socket?.disconnect();
    };
  }, [id]);

  useEffect(() => {
    dispatch({
      type: "gameData/setSelectedEventForBet",
      payload: null,
    });
  }, []);

  const setSelectedEventForBet = (data) => {
    if (data.isBookmaker) {
      dispatch({
        type: "gameData/setSelectedEventForBet",
        payload: {
          ...data,
          eventId: id,
          amount: 0,
          profit: getProfit(0, game, data).profit,
          loss: getProfit(0, game, data).loss,
        },
      });
    } else {
      dispatch({
        type: "gameData/setSelectedEventForBet",
        payload: {
          ...data,
          eventId: id,
          amount: 0,
          profit: getProfit(0, game, data).profit,
          loss: getProfit(0, game, data).loss,
        },
      });
    }
  };

  const getMatchDetailsSoccer = useCallback(async () => {
    const res = await getMatchDetailsSoccerAPI(id);
    if (res.code === 200) {
      dispatch({
        type: "gameData/setMatchSettings",
        payload: {
          game,
          settings: res.response,
          eventId: id,
        },
      });
    }
  }, [dispatch, game, id]);

  const getMatchDetailsTennis = useCallback(async () => {
    const res = await getMatchDetailsTennisAPI(id);
    if (res.code === 200) {
      dispatch({
        type: "gameData/setMatchSettings",
        payload: {
          game,
          settings: res.response,
          eventId: id,
        },
      });
    }
  }, [dispatch, game, id]);

  const getCricketMatchDetails = useCallback(async () => {
    const res = await getMatchDetailsCricketAPI(id);
    if (res.code === 200) {
      dispatch({
        type: "gameData/setMatchSettings",
        payload: {
          game,
          settings: res.response,
          eventId: id,
        },
      });
    }
  }, [dispatch, game, id]);

  useEffect(() => {
    if (userData.token) {
      if (game == "soccer") {
        getMatchDetailsSoccer();
      } else if (game == "tennis") {
        getMatchDetailsTennis();
      } else if (game == "cricket") {
        getCricketMatchDetails();
      }
      let interval = setInterval(() => {
        if (game == "soccer") {
          getMatchDetailsSoccer();
        } else if (game == "tennis") {
          getMatchDetailsTennis();
        } else if (game == "cricket") {
          getCricketMatchDetails();
        }
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [
    game,
    getCricketMatchDetails,
    getMatchDetailsSoccer,
    getMatchDetailsTennis,
    userData.token,
  ]);

  const getCricketScorecardHandler = useCallback(async () => {
    const res = await getCricketScorecard(searchParams.get("gameId"));
    console.log(res, "resData");
    if (res.status === 200 && res.data) {
      setScorecard(res.data);
    }
  }, [searchParams]);

  useEffect(() => {
    if (searchParams.get("gameId")) {
      getCricketScorecardHandler();
    }
  }, [getCricketScorecardHandler, searchParams]);

  const sessionMarketData = gameOdds?.fancyOdds?.filter(
    (j) => j.gameType === "Normal"
  );
  const overByOverMarketData = gameOdds?.fancyOdds?.filter(
    (j) => j.gameType === "Over By Over"
  );
  const ballByBallMarketData = gameOdds?.fancyOdds?.filter(
    (j) => j.gameType === "Ball By Ball"
  );
  const fancy1MarketData = gameOdds?.fancyOdds?.filter(
    (j) => j.gameType === "fancy1"
  );
  const meterMarketData = gameOdds?.fancyOdds?.filter(
    (j) => j.gameType === "meter"
  );
  const oddevenMarketData = gameOdds?.fancyOdds?.filter(
    (j) => j.gameType === "oddeven"
  );
  const khadoMarketData = gameOdds?.fancyOdds?.filter(
    (j) => j.gameType === "khado"
  );
  const gameWinner210MarketData = gameOdds?.fancyOdds?.filter(
    (j) => j.gameType === "Game Winner 2/10"
  );
  const gameWinner211MarketData = gameOdds?.fancyOdds?.filter(
    (j) => j.gameType === "Game Winner 2/11"
  );

  console.log(scorecard, "scorecard");
  // console.log(gameOdds, 'gameOdds');

  // const staticTennisScorecard = {
  //   data: [
  //     {
  //       score: {
  //         home: {
  //           name: "Player A",
  //           games: 6,
  //           sets: 2,
  //           score: 40,
  //           gameSequence: [6, 7, 6],
  //         },
  //         away: {
  //           name: "Player B",
  //           games: 4,
  //           sets: 1,
  //           score: 30,
  //           gameSequence: [4, 6, 2],
  //         },
  //       },
  //       currentSet: 3,
  //       currentGame: 4,
  //       fullTimeElapsed: {
  //         hour: 2,
  //         min: 45,
  //         sec: 30,
  //       },
  //     },
  //   ],
  // };

  return (
    <>
      <Box
        display="flex"
        flexDirection={{
          base: "column",
          sm: "column",
          lg: "row",
        }}
        minH="84.5vh"
        zIndex={100}
        className="game-page-box"
        mt="1"
      >
        <Box className="bet-for-mobile" display={{ base: "block", md: "none" }}>
          <Box
            p="2"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            w="100%"
            color="black"
            fontWeight="bold"
            pr="5"
            mt="2"
            className="game-top-main"
          >
            <Box display="flex" textColor="black" className="game-top-head-box">
              <Text>
                {game == "cricket"
                  ? gameEvent?.eventName
                  : gameEvent?.seriesName + " > " + gameEvent?.eventName}
              </Text>
              {gameEvent?.inPlay && (
                <Flex
                  ml="5px"
                  alignItems="center"
                  justifyContent="center"
                  id="gameInfoDate"
                  class="green"
                >
                  <img
                    class="icon-irun"
                    src={require("../../assets/img/icon-irun.png")}
                  />
                  <Text fontSize="sm" color="green.700">
                    In-Play
                  </Text>
                </Flex>
              )}
            </Box>
            <span>
              {moment(
                gameEvent?.eventDate
                  ? gameEvent?.eventDate
                  : gameEvent?.eventTime
                  ? gameEvent?.eventTime
                  : new Date()
              ).format("DD/MMM/YYYY hh:mm A")}
            </span>
          </Box>
          <PlaceBet
            w="100%"
            eventId={id}
            game={game}
            teamName={gameEvent?.eventName}
            forMobile
          >
            <Box
              className={`middle-content ${
                gameEvent?.isVirtual ? "virtual-game" : ""
              }`}
              mr="0.5"
              p="0"
              display={{ base: "block", md: "none" }}
            >
              {(gameEvent?.gameId || gameEvent?.eventId) && (
                <Box className="score-box">
                  {game === "cricket" ? (
                    gameEvent?.isVirtual && (
                      <IframeComponent
                        height="400px"
                        url={`${process.env.REACT_APP_IFRAME_URL}/rtv.php?eventId=${gameEvent?.channel}`}
                      />
                    )
                  ) : game === "soccer" ? null : game === "tennis" ? (
                    <>
                      {console.log("tennis screen 1")}
                      <div
                        className="banner scorecard-banner snipcss-5JlIz style-eJNOe"
                        id="style-eJNOe"
                      >
                        <div className="scorecard m-b-5">
                          <div className="row">
                            <div className="col-md-5">
                              {scorecard?.data[0]?.score?.home && (
                                <div className="team-1 row">
                                  <span className="team-name col-md-3">
                                    {scorecard?.data[0]?.score?.home?.name}
                                  </span>
                                  <span className="score col-md-9 text-right">
                                    Games:{" "}
                                    {scorecard?.data[0]?.score?.home?.games} |
                                    Sets:{" "}
                                    {scorecard?.data[0]?.score?.home?.sets} |
                                    Score:{" "}
                                    {scorecard?.data[0]?.score?.home?.score}
                                  </span>
                                </div>
                              )}
                              {scorecard?.data[0]?.score?.away && (
                                <div className="team-1 row m-t-10">
                                  <span className="team-name col-md-3">
                                    {scorecard?.data[0]?.score?.away?.name}
                                  </span>
                                  <span className="score col-md-9 text-right">
                                    Games:{" "}
                                    {scorecard?.data[0]?.score?.away?.games} |
                                    Sets:{" "}
                                    {scorecard?.data[0]?.score?.away?.sets} |
                                    Score:{" "}
                                    {scorecard?.data[0]?.score?.away?.score}
                                  </span>
                                </div>
                              )}
                              <div className="team-1 row m-t-10">
                                <span className="team-name col-md-3">
                                  Sets:
                                </span>
                                <span className="score col-md-9 text-right">
                                  {scorecard?.data[0]?.score?.home?.gameSequence.map(
                                    (homeScore, i) => (
                                      <span key={i}>
                                        {i + 1}: {homeScore} -{" "}
                                        {scorecard?.data[0]?.score?.away
                                          ?.gameSequence[i] || 0}{" "}
                                        &nbsp;
                                      </span>
                                    )
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-7">
                              <div className="row">
                                <div className="col-md-5">
                                  <div className="row">
                                    <span className="team-name">
                                      {scorecard?.data[0]?.currentSet && (
                                        <span>
                                          Current Set:{" "}
                                          {scorecard?.data[0]?.currentSet}
                                        </span>
                                      )}
                                      {scorecard?.data[0]?.currentGame && (
                                        <>
                                          &nbsp;|&nbsp;
                                          <span>
                                            Current Game:{" "}
                                            {scorecard?.data[0]?.currentGame}
                                          </span>
                                        </>
                                      )}
                                    </span>
                                  </div>
                                  <div className="row m-t-10">
                                    <span className="team-name">
                                      {scorecard?.data[0]?.fullTimeElapsed && (
                                        <span>
                                          Time Elapsed:{" "}
                                          {
                                            scorecard?.data[0]?.fullTimeElapsed
                                              .hour
                                          }
                                          :
                                          {
                                            scorecard?.data[0]?.fullTimeElapsed
                                              .min
                                          }
                                          :
                                          {
                                            scorecard?.data[0]?.fullTimeElapsed
                                              .sec
                                          }
                                        </span>
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                </Box>
              )}

              <Box
                p="2"
                alignItems="center"
                justifyContent="space-between"
                w="100%"
                color="black"
                fontWeight="bold"
                pr="5"
                mt="20"
                className="game-top-main"
                display={{ base: "none", md: "flex" }}
              >
                <Box
                  display="flex"
                  textColor="black"
                  className="game-top-head-box"
                >
                  <Text>
                    {game == "cricket"
                      ? gameEvent?.eventName
                      : gameEvent?.seriesName + " > " + gameEvent?.eventName}
                  </Text>
                  {gameEvent?.inPlay && (
                    <Flex
                      ml="5px"
                      alignItems="center"
                      justifyContent="center"
                      id="gameInfoDate"
                      class="green"
                    >
                      <img
                        class="icon-irun"
                        src={require("../../assets/img/icon-irun.png")}
                      />
                      <Text fontSize="sm" color="green.700">
                        In-Play
                      </Text>
                    </Flex>
                  )}
                </Box>
                {new Date(
                  gameEvent?.eventDate
                    ? gameEvent?.eventDate
                    : gameEvent?.eventTime
                ).toLocaleString("en-US", {
                  timeZone: "Asia/Kolkata",
                  hour12: false,
                })}
              </Box>
              {game == "cricket" && (
                <Accordion p="0" defaultIndex={[0]} allowMultiple>
                  <AccordionItem border="none" mt="1">
                    <AccordionButton bg="#262626">
                      <AccordionIcon />
                      <Box
                        as="span"
                        fontSize="xs"
                        textColor="white"
                        flex="1"
                        textAlign="center"
                        display="flex"
                        justifyContent="space-between"
                      >
                        <Text>MATCH ODDS</Text>
                        <Text
                          fontWeight="bold"
                          color="yellow.300"
                          justifySelf="center"
                        >
                          {/* Max:
                      {gameSettings?.maxStack && gameSettings?.maxStack} */}
                        </Text>
                      </Box>
                    </AccordionButton>
                    {gameOdds?.matchOdds?.length > 0 && (
                      <AccordionPanel
                        display="flex"
                        flexDirection="column"
                        p="0"
                        bg="#fff"
                        className="match-odds-accordion-panel"
                      >
                        <MatchOdds_
                          setSelectedEventForBet={setSelectedEventForBet}
                          currentKey={"matchOdds"}
                          data={gameOdds?.matchOdds}
                          game={game}
                          eventId={id}
                        />
                      </AccordionPanel>
                    )}
                  </AccordionItem>
                </Accordion>
              )}
              <Accordion p="0" defaultIndex={[0]} allowMultiple>
                {game == "tennis" || game == "soccer"
                  ? gameOdds && (
                      <AccordionItem border="none" mt="1">
                        <AccordionButton bg="#262626">
                          <AccordionIcon />
                          <Box
                            as="span"
                            fontSize="xs"
                            textColor="white"
                            flex="1"
                            textAlign="left"
                          >
                            MATCH ODDS
                          </Box>
                        </AccordionButton>
                        <AccordionPanel
                          bg="#fff"
                          display="flex"
                          flexDirection="column"
                          p="0"
                          className="soccer-tennis-match-odds"
                        >
                          <MatchOdds_
                            setSelectedEventForBet={setSelectedEventForBet}
                            game={game}
                            data={gameOdds?.matchOddsResponseDTO[0]}
                            eventId={id}
                            currentKey="matchOddsResponseDTO"
                          />
                        </AccordionPanel>
                      </AccordionItem>
                    )
                  : null}
              </Accordion>

              {game == "cricket" && (
                <Flex w="100%">
                  {gameOdds?.bookMakerOdds &&
                    gameOdds?.bookMakerOdds.length > 0 &&
                    gameOdds?.bookMakerOdds[0]?.bm1?.marketId && (
                      <Accordion
                        p="0"
                        w={"100%"}
                        defaultIndex={[0]}
                        allowMultiple
                      >
                        <AccordionItem border="none" mt="1">
                          <AccordionButton bg="#262626">
                            <AccordionIcon />
                            <Box
                              as="span"
                              fontSize="xs"
                              textColor="white"
                              flex="1"
                              textAlign="center"
                              display="flex"
                              justifyContent="space-between"
                            >
                              <Box>BOOKMAKER </Box>
                              <Text
                                fontWeight="bold"
                                color="yellow.300"
                                justifySelf="center"
                              >
                                {/* Max:
                          {gameSettings?.bookmakerMaxStack &&
                            gameSettings?.bookmakerMaxStack} */}
                              </Text>
                            </Box>
                          </AccordionButton>
                          <AccordionPanel
                            display="flex"
                            flexDirection="column"
                            px="0"
                            py="2"
                            bg="#fff"
                            className="bookmaker-accordion-panel"
                          >
                            <MatchOdds_
                              setSelectedEventForBet={setSelectedEventForBet}
                              currentKey={"bookMakerOdds"}
                              data={gameOdds?.bookMakerOdds}
                              game={game}
                              eventId={id}
                              bm="bm1"
                            />
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                    )}
                </Flex>
              )}

              {(game == "soccer" || game == "tennis") && (
                <Flex w="100%">
                  {gameOdds?.bookMakerOddsResponseDTO &&
                    gameOdds?.bookMakerOddsResponseDTO.length > 0 &&
                    gameOdds?.bookMakerOddsResponseDTO[0]?.bm1?.marketId && (
                      <Accordion
                        className="bookmaker-accordion"
                        p="0"
                        w={"100%"}
                        defaultIndex={[0]}
                        allowMultiple
                      >
                        <AccordionItem border="none" mt="1">
                          <AccordionButton bg="#262626">
                            <AccordionIcon />
                            <Box
                              as="span"
                              fontSize="xs"
                              textColor="white"
                              flex="1"
                              textAlign="center"
                              display="flex"
                              justifyContent="space-between"
                            >
                              <Box>BOOKMAKER </Box>
                              <Text
                                fontWeight="bold"
                                color="yellow.300"
                                justifySelf="center"
                              >
                                {/* Max:
                          {gameSettings?.bookmakerMaxStack &&
                            gameSettings?.bookmakerMaxStack} */}
                              </Text>
                            </Box>
                          </AccordionButton>
                          <AccordionPanel
                            display="flex"
                            flexDirection="column"
                            px="0"
                            py="2"
                            bg="#fff"
                            className="bookmaker-accordion-panel"
                          >
                            <MatchOdds_
                              setSelectedEventForBet={setSelectedEventForBet}
                              currentKey={"bookMakerOddsResponseDTO"}
                              data={gameOdds?.bookMakerOddsResponseDTO}
                              game={game}
                              eventId={id}
                              bm="bm1"
                              soccerTennis
                            />
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                    )}
                </Flex>
              )}
              {(game == "soccer" || game == "tennis") && (
                <>
                  <Box mt={2} className="soccer-tennis-fancy-box">
                    {gameOdds?.fancyOdds.map((fancy, i) => (
                      <Box className={`soccer-tennis-fancy-item `}>
                        <Accordion p="0" defaultIndex={[0]} allowMultiple>
                          <AccordionItem border="none">
                            <AccordionButton
                              bg={{ base: "#383838", md: "#262626" }}
                            >
                              <AccordionIcon />
                              <Box
                                as="span"
                                fontSize="xs"
                                textColor="white"
                                flex="1"
                                textAlign="left"
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Text color="#A6A6A6"> {fancy.gameType}</Text>
                                <Text
                                  fontWeight="bold"
                                  color="#A6A6A6"
                                  justifySelf="center"
                                >
                                  Max:
                                  {/* {gameSettings?.sessionMaxStack &&
                                convertToLacs(gameSettings?.sessionMaxStack)} */}
                                </Text>
                              </Box>
                            </AccordionButton>
                            <AccordionPanel
                              display="flex"
                              flexDirection="column"
                              pb={4}
                              bg={{ base: "transparent", md: "#383838" }}
                              className="fancy-accordion-panel soccer-panel"
                            >
                              <MatchOdds_
                                setSelectedEventForBet={setSelectedEventForBet}
                                currentKey={"fancyOdds"}
                                data={fancy}
                                game={game}
                                eventId={id}
                                soccerTennis
                              />
                            </AccordionPanel>
                          </AccordionItem>
                        </Accordion>
                      </Box>
                    ))}
                  </Box>
                </>
              )}
            </Box>
          </PlaceBet>
        </Box>

        <Box
          className={`middle-content ${
            gameEvent?.isVirtual ? "virtual-game" : ""
          }`}
          mr="0.5"
          p="0"
          display={{ base: "none", md: "block" }}
        >
          {(gameEvent?.gameId || gameEvent?.eventId) && (
            <Box className="score-box">
              {game === "cricket" ? (
                gameEvent?.isVirtual && (
                  <IframeComponent
                    height="400px"
                    url={`${process.env.REACT_APP_IFRAME_URL}/rtv.php?eventId=${gameEvent?.channel}`}
                  />
                )
              ) : game === "soccer" ? null : game === "tennis" ? (
                <>
                  {console.log("tennis screen 2")}
                  <div
                    className="banner scorecard-banner snipcss-5JlIz style-eJNOe"
                    id="style-eJNOe"
                  >
                    <div className="scorecard m-b-5">
                      <div className="row">
                        <div className="col-md-5">
                          {scorecard?.data[0]?.score?.home && (
                            <div className="team-1 row">
                              <span className="team-name col-md-3">
                                {scorecard?.data[0]?.score?.home?.name}
                              </span>
                              <span className="score col-md-9 text-right">
                                {/* Games: {scorecard?.data[0]?.score?.home?.games}{" "}
                                | Sets: {scorecard?.data[0]?.score?.home?.sets}{" "} */}
                                | Score:{" "}
                                {scorecard?.data[0]?.score?.home?.score}
                              </span>
                            </div>
                          )}
                          {scorecard?.data[0]?.score?.away && (
                            <div className="team-1 row m-t-10">
                              <span className="team-name col-md-3">
                                {scorecard?.data[0]?.score?.away?.name}
                              </span>
                              <span className="score col-md-9 text-right">
                                {/* Games: {scorecard?.data[0]?.score?.away?.games}{" "}
                                | Sets: {scorecard?.data[0]?.score?.away?.sets}{" "} */}
                                | Score:{" "}
                                {scorecard?.data[0]?.score?.away?.score}
                              </span>
                            </div>
                          )}
                          <div className="team-1 row m-t-10">
                            <span className="team-name col-md-3">
                              Sets: {scorecard?.data[0]?.currentSet}
                            </span>
                          </div>
                        </div>
                        {/* <div className="col-md-7">
                          <div className="row">
                            <div className="col-md-5">
                              <div className="row">
                                <span className="team-name">
                                  {scorecard?.data[0]?.currentSet && (
                                    <span>
                                      Current Set:{" "}
                                      {scorecard?.data[0]?.currentSet}
                                    </span>
                                  )}
                                  {scorecard?.data[0]?.currentGame && (
                                    <>
                                      &nbsp;|&nbsp;
                                      <span>
                                        Current Game:{" "}
                                        {scorecard?.data[0]?.currentGame}
                                      </span>
                                    </>
                                  )}
                                </span>
                              </div>
                               <div className="row m-t-10">
                                <span className="team-name">
                                  {scorecard?.data[0]?.fullTimeElapsed && (
                                    <span>
                                      Time Elapsed:{" "}
                                      {scorecard?.data[0]?.fullTimeElapsed.hour}
                                      :{scorecard?.data[0]?.fullTimeElapsed.min}
                                      :{scorecard?.data[0]?.fullTimeElapsed.sec}
                                    </span>
                                  )}
                                </span>
                              </div> 
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </Box>
          )}

          <Box
            p="2"
            alignItems="center"
            justifyContent="space-between"
            w="100%"
            color="black"
            fontWeight="bold"
            pr="5"
            className="game-top-main"
            display={{ base: "none", md: "flex" }}
          >
            <Box display="flex" textColor="black" className="game-top-head-box">
              <Text>
                {game == "cricket"
                  ? gameEvent?.eventName
                  : gameEvent?.seriesName + " > " + gameEvent?.eventName}
              </Text>
              {gameEvent?.inPlay && (
                <Flex
                  ml="5px"
                  alignItems="center"
                  justifyContent="center"
                  id="gameInfoDate"
                  class="green"
                >
                  <img
                    class="icon-irun"
                    src={require("../../assets/img/icon-irun.png")}
                    alt=""
                  />
                  <Text fontSize="sm" color="green.700">
                    In-Play
                  </Text>
                </Flex>
              )}
            </Box>
            <Text
              style={{
                fontSize: "13px",
                fontWeight: 600,
                fontFamily: "'Inter',Helvetica",
              }}
            >
              {new Date(
                gameEvent?.eventDate
                  ? gameEvent?.eventDate
                  : gameEvent?.eventTime
              ).toLocaleString("en-US", {
                timeZone: "Asia/Kolkata",
                hour12: false,
              })}
            </Text>
          </Box>
          {game == "cricket" && (
            <>
              <div
                className="banner scorecard-banner snipcss-5JlIz style-eJNOe"
                id="style-eJNOe"
              >
                <div className="scorecard m-b-5">
                  <div className="row">
                    <div className="col-md-5">
                      {scorecard && (
                        <>
                          {/* Home Team Latest Inning */}
                          {scorecard?.data[0]?.score?.home?.inning2?.runs ? (
                            <p className="team-1 row">
                              <span className="team-name col-md-3">
                                {scorecard?.data[0]?.score?.home?.name} (2nd
                                Innings)
                              </span>
                              <span className="score col-md-9 text-right">
                                {scorecard?.data[0]?.score?.home?.inning2?.runs}
                                /
                                {
                                  scorecard?.data[0]?.score?.home?.inning2
                                    ?.wickets
                                }{" "}
                                (
                                {
                                  scorecard?.data[0]?.score?.home?.inning2
                                    ?.overs
                                }{" "}
                                overs)
                              </span>
                            </p>
                          ) : (
                            scorecard?.data[0]?.score?.home?.inning1?.runs && (
                              <p className="team-1 row">
                                <span className="team-name col-md-3">
                                  {scorecard?.data[0]?.score?.home?.name}
                                </span>
                                <span className="score col-md-9 text-right">
                                  {
                                    scorecard?.data[0]?.score?.home?.inning1
                                      ?.runs
                                  }
                                  /
                                  {
                                    scorecard?.data[0]?.score?.home?.inning1
                                      ?.wickets
                                  }{" "}
                                  (
                                  {
                                    scorecard?.data[0]?.score?.home?.inning1
                                      ?.overs
                                  }{" "}
                                  overs)
                                </span>
                              </p>
                            )
                          )}
                          {/* Away Team Latest Inning */}
                          {scorecard?.data[0]?.score?.away?.inning2?.runs ? (
                            <p className="team-1 row m-t-10">
                              <span className="team-name col-md-3">
                                {scorecard?.data[0]?.score?.away?.name} (2nd
                                Innings)
                              </span>
                              <span className="score col-md-9 text-right">
                                {scorecard?.data[0]?.score?.away?.inning2?.runs}
                                /
                                {
                                  scorecard?.data[0]?.score?.away?.inning2
                                    ?.wickets
                                }{" "}
                                (
                                {
                                  scorecard?.data[0]?.score?.away?.inning2
                                    ?.overs
                                }{" "}
                                overs)
                              </span>
                            </p>
                          ) : (
                            scorecard?.data[0]?.score?.away?.inning1?.runs && (
                              <p className="team-1 row m-t-10">
                                <span className="team-name col-md-3">
                                  {scorecard?.data[0]?.score?.away?.name}
                                </span>
                                <span className="score col-md-9 text-right">
                                  {
                                    scorecard?.data[0]?.score?.away?.inning1
                                      ?.runs
                                  }
                                  /
                                  {
                                    scorecard?.data[0]?.score?.away?.inning1
                                      ?.wickets
                                  }{" "}
                                  (
                                  {
                                    scorecard?.data[0]?.score?.away?.inning1
                                      ?.overs
                                  }{" "}
                                  overs)
                                </span>
                              </p>
                            )
                          )}
                        </>
                      )}
                    </div>
                    <div className="col-md-7">
                      <div className="row">
                        <div className="col-md-5">
                          <div className="row">
                            <span className="team-name">
                              {scorecard?.data[0]?.currentDay && (
                                <span>
                                  Day: {scorecard?.data[0]?.currentDay}
                                </span>
                              )}
                              {scorecard?.data[0]?.matchType && (
                                <>
                                  &nbsp;|&nbsp;
                                  <span>
                                    Match Type: {scorecard?.data[0]?.matchType}
                                  </span>
                                </>
                              )}
                              {scorecard?.data[0]?.matchStatus && (
                                <>
                                  &nbsp;|&nbsp;
                                  <span>
                                    Status: {scorecard?.data[0]?.matchStatus}
                                  </span>
                                </>
                              )}
                            </span>
                          </div>
                          <div className="row m-t-10">
                            <span className="team-name">
                              {scorecard?.data[0]?.stateOfBall?.bowlerName && (
                                <span>
                                  Bowler:{" "}
                                  {scorecard?.data[0]?.stateOfBall?.bowlerName}
                                </span>
                              )}
                              {scorecard?.data[0]?.stateOfBall?.batsmanName && (
                                <>
                                  &nbsp;|&nbsp;
                                  <span>
                                    Batsman:{" "}
                                    {
                                      scorecard?.data[0]?.stateOfBall
                                        ?.batsmanName
                                    }
                                  </span>
                                </>
                              )}
                            </span>
                          </div>
                        </div>
                        {/* <div className="col-md-7">
            <div className="text-right"></div>
            <div className="row">
              <div className="col-md-12">
              </div>
            </div>
          </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Accordion p="0" defaultIndex={[0]} allowMultiple>
                <AccordionItem border="none" mt="1">
                  <AccordionButton bg="#262626">
                    <AccordionIcon />
                    <Box
                      as="span"
                      fontSize="xs"
                      textColor="white"
                      flex="1"
                      textAlign="center"
                      display="flex"
                      justifyContent="space-between"
                    >
                      <Text>MATCH ODDS</Text>
                      <Text
                        fontWeight="bold"
                        color="yellow.300"
                        justifySelf="center"
                      >
                        Maximum Bet :
                        {gameSettings?.maxStack && gameSettings?.maxStack}
                      </Text>
                    </Box>
                  </AccordionButton>
                  {gameOdds?.matchOdds?.length > 0 && (
                    <AccordionPanel
                      display="flex"
                      flexDirection="column"
                      p="0"
                      bg="#fff"
                      className="match-odds-accordion-panel"
                    >
                      <MatchOdds_
                        setSelectedEventForBet={setSelectedEventForBet}
                        currentKey={"matchOdds"}
                        data={gameOdds?.matchOdds}
                        game={game}
                        eventId={id}
                      />
                    </AccordionPanel>
                  )}
                </AccordionItem>
              </Accordion>
              <Flex w="100%">
                {gameOdds?.bookMakerOdds &&
                  gameOdds?.bookMakerOdds.length > 0 &&
                  gameOdds?.bookMakerOdds[0]?.bm1?.marketId && (
                    <Accordion
                      p="0"
                      w={"100%"}
                      defaultIndex={[0]}
                      allowMultiple
                    >
                      <AccordionItem border="none" mt="1">
                        <AccordionButton bg="#262626">
                          <AccordionIcon />
                          <Box
                            as="span"
                            fontSize="xs"
                            textColor="white"
                            flex="1"
                            textAlign="center"
                            display="flex"
                            justifyContent="space-between"
                          >
                            <Box>BOOKMAKER </Box>
                            <Text
                              fontWeight="bold"
                              color="yellow.300"
                              justifySelf="center"
                            >
                              {/* Max:
                          {gameSettings?.bookmakerMaxStack &&
                            gameSettings?.bookmakerMaxStack} */}
                            </Text>
                          </Box>
                        </AccordionButton>
                        <AccordionPanel
                          display="flex"
                          flexDirection="column"
                          px="0"
                          py="2"
                          bg="#fff"
                          className="bookmaker-accordion-panel"
                        >
                          <MatchOdds_
                            setSelectedEventForBet={setSelectedEventForBet}
                            currentKey={"bookMakerOdds"}
                            data={gameOdds?.bookMakerOdds}
                            game={game}
                            eventId={id}
                            bm="bm1"
                          />
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  )}
              </Flex>
              <Grid templateColumns="repeat(2,1fr)" gap={2}>
                {sessionMarketData?.length > 0 && (
                  <>
                    <Box>
                      {sessionMarketData?.map((fancy) => (
                        <Accordion p="0" defaultIndex={[0]} allowMultiple>
                          <AccordionItem border="none" mt="1">
                            <AccordionButton bg={"#262626"}>
                              <AccordionIcon />
                              <Box
                                as="span"
                                fontSize="xs"
                                textColor="white"
                                flex="1"
                                textAlign="left"
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Text textTransform="uppercase">
                                  {" "}
                                  {fancy.gameType === "Normal"
                                    ? "Session Market"
                                    : fancy.gameType}
                                </Text>
                                <Text
                                  fontWeight="bold"
                                  color="yellow.300"
                                  justifySelf="center"
                                >
                                  {/* Max:
                    {gameSettings?.sessionMaxStack &&
                      gameSettings?.sessionMaxStack} */}
                                </Text>
                              </Box>
                            </AccordionButton>
                            {gameOdds?.fancyOdds?.length > 0 && (
                              <AccordionPanel
                                display="flex"
                                flexDirection="column"
                                pb={4}
                                bg="#fff"
                                className="fancy-accordion-panel"
                              >
                                <MatchOdds_
                                  setSelectedEventForBet={
                                    setSelectedEventForBet
                                  }
                                  currentKey={"fancyOdds"}
                                  data={fancy}
                                  game={game}
                                  eventId={id}
                                  fromSession
                                />
                              </AccordionPanel>
                            )}
                          </AccordionItem>
                        </Accordion>
                      ))}
                    </Box>
                  </>
                )}
                {(overByOverMarketData?.length > 0 ||
                  ballByBallMarketData?.length > 0) && (
                  <>
                    <Box>
                      {overByOverMarketData?.map((fancy) => (
                        <Accordion p="0" defaultIndex={[0]} allowMultiple>
                          <AccordionItem border="none" mt="1">
                            <AccordionButton bg={"#262626"}>
                              <AccordionIcon />
                              <Box
                                as="span"
                                fontSize="xs"
                                textColor="white"
                                flex="1"
                                textAlign="left"
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Text textTransform="uppercase">
                                  {" "}
                                  {fancy.gameType === "Normal"
                                    ? "Session Market"
                                    : fancy.gameType}
                                </Text>
                                <Text
                                  fontWeight="bold"
                                  color="yellow.300"
                                  justifySelf="center"
                                >
                                  {/* Max:
                    {gameSettings?.sessionMaxStack &&
                      gameSettings?.sessionMaxStack} */}
                                </Text>
                              </Box>
                            </AccordionButton>
                            {gameOdds?.fancyOdds?.length > 0 && (
                              <AccordionPanel
                                display="flex"
                                flexDirection="column"
                                pb={4}
                                bg="#fff"
                                className="fancy-accordion-panel"
                              >
                                <MatchOdds_
                                  setSelectedEventForBet={
                                    setSelectedEventForBet
                                  }
                                  currentKey={"fancyOdds"}
                                  data={fancy}
                                  game={game}
                                  eventId={id}
                                  fromSession
                                />
                              </AccordionPanel>
                            )}
                          </AccordionItem>
                        </Accordion>
                      ))}
                      {ballByBallMarketData?.map((fancy) => (
                        <Accordion
                          mt={2}
                          p="0"
                          defaultIndex={[0]}
                          allowMultiple
                        >
                          <AccordionItem border="none" mt="1">
                            <AccordionButton bg={"#262626"}>
                              <AccordionIcon />
                              <Box
                                as="span"
                                fontSize="xs"
                                textColor="white"
                                flex="1"
                                textAlign="left"
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Text textTransform="uppercase">
                                  {" "}
                                  {fancy.gameType === "Normal"
                                    ? "Session Market"
                                    : fancy.gameType}
                                </Text>
                                <Text
                                  fontWeight="bold"
                                  color="yellow.300"
                                  justifySelf="center"
                                >
                                  {/* Max:
                    {gameSettings?.sessionMaxStack &&
                      gameSettings?.sessionMaxStack} */}
                                </Text>
                              </Box>
                            </AccordionButton>
                            {gameOdds?.fancyOdds?.length > 0 && (
                              <AccordionPanel
                                display="flex"
                                flexDirection="column"
                                pb={4}
                                bg="#fff"
                                className="fancy-accordion-panel"
                              >
                                <MatchOdds_
                                  setSelectedEventForBet={
                                    setSelectedEventForBet
                                  }
                                  currentKey={"fancyOdds"}
                                  data={fancy}
                                  game={game}
                                  eventId={id}
                                  fromSession
                                />
                              </AccordionPanel>
                            )}
                          </AccordionItem>
                        </Accordion>
                      ))}
                    </Box>
                  </>
                )}
              </Grid>
              {gameOdds?.fancyOdds?.map((fancy) => {
                if (
                  fancy.gameType !== "Normal" &&
                  fancy.gameType !== "Over By Over" &&
                  fancy.gameType !== "Ball By Ball" &&
                  fancy.gameType !== "fancy1" &&
                  fancy.gameType !== "meter" &&
                  fancy.gameType !== "oddeven" &&
                  fancy.gameType !== "khado"
                ) {
                  return (
                    <GridItem colSpan={2}>
                      <Accordion p="0" defaultIndex={[0]} allowMultiple>
                        <AccordionItem border="none" mt="1">
                          <AccordionButton bg={"#262626"}>
                            <AccordionIcon />
                            <Box
                              as="span"
                              fontSize="xs"
                              textColor="white"
                              flex="1"
                              textAlign="left"
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Text textTransform="uppercase">
                                {" "}
                                {fancy.gameType === "Normal"
                                  ? "Session Market"
                                  : fancy.gameType}
                              </Text>
                              <Text
                                fontWeight="bold"
                                color="yellow.300"
                                justifySelf="center"
                              >
                                {/* Max:
                        {gameSettings?.sessionMaxStack &&
                          gameSettings?.sessionMaxStack} */}
                              </Text>
                            </Box>
                          </AccordionButton>
                          {gameOdds?.fancyOdds?.length > 0 && (
                            <AccordionPanel
                              display="flex"
                              flexDirection="column"
                              pb={4}
                              bg="#fff"
                              className="fancy-accordion-panel"
                            >
                              <MatchOdds_
                                setSelectedEventForBet={setSelectedEventForBet}
                                currentKey={"fancyOdds"}
                                data={fancy}
                                game={game}
                                eventId={id}
                                fromSession
                              />
                            </AccordionPanel>
                          )}
                        </AccordionItem>
                      </Accordion>
                    </GridItem>
                  );
                }
              })}
            </>
          )}

          <Accordion p="0" defaultIndex={[0]} allowMultiple>
            {game == "tennis" || game == "soccer"
              ? gameOdds && (
                  <AccordionItem border="none" mt="1">
                    <AccordionButton bg="#262626">
                      <AccordionIcon />
                      <Box
                        as="span"
                        fontSize="xs"
                        textColor="white"
                        flex="1"
                        textAlign="left"
                      >
                        MATCH ODDS
                      </Box>
                    </AccordionButton>
                    <AccordionPanel
                      bg="#fff"
                      display="flex"
                      flexDirection="column"
                      p="0"
                      className="soccer-tennis-match-odds"
                    >
                      <MatchOdds_
                        setSelectedEventForBet={setSelectedEventForBet}
                        game={game}
                        data={gameOdds?.matchOddsResponseDTO[0]}
                        eventId={id}
                        currentKey="matchOddsResponseDTO"
                      />
                    </AccordionPanel>
                  </AccordionItem>
                )
              : null}
          </Accordion>
          {(game == "soccer" || game == "tennis") && (
            <Flex w="100%">
              {gameOdds?.bookMakerOddsResponseDTO &&
                gameOdds?.bookMakerOddsResponseDTO.length > 0 &&
                gameOdds?.bookMakerOddsResponseDTO[0]?.bm1?.marketId && (
                  <Accordion
                    className="bookmaker-accordion"
                    p="0"
                    w={"100%"}
                    defaultIndex={[0]}
                    allowMultiple
                  >
                    <AccordionItem border="none" mt="1">
                      <AccordionButton bg="#262626">
                        <AccordionIcon />
                        <Box
                          as="span"
                          fontSize="xs"
                          textColor="white"
                          flex="1"
                          textAlign="center"
                          display="flex"
                          justifyContent="space-between"
                        >
                          <Box>BOOKMAKER </Box>
                          <Text
                            fontWeight="bold"
                            color="yellow.300"
                            justifySelf="center"
                          >
                            {/* Max:
                          {gameSettings?.bookmakerMaxStack &&
                            gameSettings?.bookmakerMaxStack} */}
                          </Text>
                        </Box>
                      </AccordionButton>
                      <AccordionPanel
                        display="flex"
                        flexDirection="column"
                        px="0"
                        py="2"
                        bg="#fff"
                        className="bookmaker-accordion-panel"
                      >
                        <MatchOdds_
                          setSelectedEventForBet={setSelectedEventForBet}
                          currentKey={"bookMakerOddsResponseDTO"}
                          data={gameOdds?.bookMakerOddsResponseDTO}
                          game={game}
                          eventId={id}
                          bm="bm1"
                        />
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                )}
            </Flex>
          )}
          {(game == "soccer" || game == "tennis") && (
            <>
              <Box mt={2} className="soccer-tennis-fancy-box">
                {gameOdds?.fancyOdds.map((fancy, i) => (
                  <Box
                    className={`soccer-tennis-fancy-item ${
                      !fancy.areAllThreeOddsAvailable
                        ? i % 2
                          ? "even"
                          : "odd"
                        : ""
                    }`}
                    width={!fancy.areAllThreeOddsAvailable ? "49.2%" : "100%"}
                  >
                    <Accordion p="0" defaultIndex={[0]} allowMultiple>
                      <AccordionItem border="none">
                        <AccordionButton
                          bg={{ base: "#383838", md: "#262626" }}
                        >
                          <AccordionIcon />
                          <Box
                            as="span"
                            fontSize="xs"
                            textColor="white"
                            flex="1"
                            textAlign="left"
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Text color="#A6A6A6"> {fancy.gameType}</Text>
                            <Text
                              fontWeight="bold"
                              color="#A6A6A6"
                              justifySelf="center"
                            >
                              Max:
                              {/* {gameSettings?.sessionMaxStack &&
                                convertToLacs(gameSettings?.sessionMaxStack)} */}
                            </Text>
                          </Box>
                        </AccordionButton>
                        <AccordionPanel
                          display="flex"
                          flexDirection="column"
                          pb={4}
                          bg={{ base: "transparent", md: "#383838" }}
                          className="fancy-accordion-panel soccer-panel"
                        >
                          <MatchOdds_
                            setSelectedEventForBet={setSelectedEventForBet}
                            currentKey={"fancyOdds"}
                            data={fancy}
                            game={game}
                            eventId={id}
                            soccerTennis
                          />
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  </Box>
                ))}
              </Box>
            </>
          )}
        </Box>
        <Box className="bet-box-right">
          {gameEvent?.eventId && (
            <PlaceBet
              w="100%"
              eventId={id}
              game={game}
              teamName={gameEvent?.eventName}
              gameId={gameEvent?.eventId}
            />
          )}
        </Box>
      </Box>
    </>
  );
}
