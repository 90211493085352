import React from "react";
import { Stack, Link, Typography } from "@mui/material";
import "./link.scss";

export const Links = () => {

  return (
    <Stack className="link-header" direction={"row"}>
      <Link
        href={"#"}
        underline="always"
        className="link-style"
      >
        {"Click here"}
      </Link>
      <Typography className="link-text">
        to watch the video tutorial!
      </Typography>
    </Stack>
  );
};
