import { BASE_URL, getToken } from '../utils/constants';
import store from '../redux/store';
import { AXIOS_INSTANCE } from '.';

export const loginAPI = async (username, password) => {
  try {
    const res = await AXIOS_INSTANCE.post(`${BASE_URL}/auth/signin`, {
      username,
      password,
    });
    return res;
  } catch (error) {
    return error;
  }
};
export const registerAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/auth/self/signup`,
      payload
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const verifyUsernameAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/user/sendOtp?username=${payload}`,
      payload
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const sendOtpAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/sendOtp?username=${payload}`,
      payload
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const verifyOtpAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/user/verifyOtp`,
      payload
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const updatePasswordAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/auth/self/signup`,
      payload
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const changePasswordAPI = async (payload) => {
  try {
    const res = await AXIOS_INSTANCE.put(`${BASE_URL}/auth/password`, payload, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const checkValidEmailAPI = async (email) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/auth/valid/email/${email}`
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const checkValidUsernameAPI = async (username) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/auth/valid/username/${username}`
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getUserByIdAPI = async (id) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(`${BASE_URL}/auth/user/${id}`);
    return data;
  } catch (error) {
    return error;
  }
};

export const getButtonValuesAPI = async () => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/user/client-button2`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const changeButtonValuesAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${BASE_URL}/user/update-client-button2`,
      {
        ...payload,
      },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const betHistoryAPI = async (payload) => {
  try {
    const res = await AXIOS_INSTANCE.post(
      `${BASE_URL}/user/bet-history`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return res;
  } catch (error) {
    return error;
  }
};
export const unSetteledBetsAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/user/unsettled-bets`,
      payload
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getCurrentBets = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/user/current-bets`,
      payload
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const profitLossReportAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/user/profit-loss-report`,
      payload
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const currentBetsAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/user/current-bets`,
      payload
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getActivityLogAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/user/activity-log`,
      payload
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getExposureTableAPI = async () => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/user/exposure-table`
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const searchAPI = async (search) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/user/home-page/search`,
      {
        params: {
          search,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const scoreAPI = async (eventId) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `http://marketsarket.in:3002/scoreurl/${eventId}`
    );
    return data?.score;
  } catch (error) {
    return error;
  }
};
export const getMatchScoreAPI = async (eventId) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/auth/score-sport`,
      {},
      {
        params: {
          eventId,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const upcomingFixtureAPI = async () => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/auth/upcoming-fixture`
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const dashboardBannerAPI = async () => {
  try {
    const data = await AXIOS_INSTANCE.get(`${BASE_URL}/auth/dashboard-banner`);
    return data;
  } catch (error) {
    return error;
  }
};

export const welcomeBannerAPI = async () => {
  try {
    const data = await AXIOS_INSTANCE.get(`${BASE_URL}/auth/welcome-banner`);
    return data;
  } catch (error) {
    return error;
  }
};

export const getAnnouncementAPI = async () => {
  try {
    const res = await AXIOS_INSTANCE.get(`${BASE_URL}/auth/announcement`);
    return res;
  } catch (error) {
    return error;
  }
};

export const getSignedUrlAPI = async (key) => {
  try {
    const data = await AXIOS_INSTANCE.post(
      `${BASE_URL}/auth/presigned-url?fileKey=${key}`
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getBetsFromAccountLogs = async (id) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/user/bets-from-log`,
      {
        params: {
          accountLogId: id,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getLiveStreamingUrlAPI = async (id) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/auth/streaming/${id}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getUserByUsernameAPI = async (username) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(`${BASE_URL}/auth/user/${username}`);
    return data;
  } catch (error) {
    return error;
  }
};
