import React from "react";
import { Tabs, Tab, Stack } from "@mui/material";
import "../../../assets/styles/css/rulesTabs.scss";

const RulesTabs = ({ tabLabels, defaultTab }) => {
  const [value, setValue] = React.useState(defaultTab);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Stack className="rules-tabs-main-stack">
      <Tabs value={value} onChange={handleChange} className="rules-tabs-tabs">
        {tabLabels.map((label, index) => (
          <Tab
            disableRipple
            className="rules-tab"
            label={<p className="rules-text">{label}</p>}
            key={index}
          />
        ))}
      </Tabs>
    </Stack>
  );
};

export default RulesTabs;
