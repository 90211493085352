import React from 'react';

const CricketMenuIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='22'
      viewBox='0 0 20 22'
      fill='none'
    >
      <path
        d='M19.9998 3.15609C19.9998 3.23973 19.9678 3.3204 19.9103 3.38113C19.2473 4.0811 18.5849 4.78109 17.9279 5.47515C17.5711 5.8521 16.9703 5.84983 16.6162 5.47033C16.4645 5.3077 16.311 5.14319 16.1563 4.97729C16.1225 4.94104 16.1166 4.88309 16.1504 4.84686C16.9741 3.95574 17.7972 3.06518 18.6219 2.17461C18.8682 1.9085 19.0233 1.90682 19.2618 2.17629C19.4851 2.4285 19.7027 2.68696 19.9214 2.9437C19.972 3.00302 19.9998 3.07816 19.9998 3.15609Z'
        fill='#3156FF'
      />
      <path
        d='M4.84172 21.0745C4.69026 21.2094 4.42919 21.2339 4.28912 21.0872C3.5824 20.3453 2.87881 19.5995 2.1773 18.8521C1.96226 18.6229 1.97478 18.4496 2.21436 18.1935C4.43161 15.8243 6.64939 13.4556 8.86612 11.0863C10.6914 9.13582 12.5161 7.18418 14.3409 5.2331C14.5951 4.9614 14.6488 4.96476 14.9724 5.11347C15.3978 5.30913 15.7554 5.58866 16.0711 5.9526C16.459 6.39984 16.9031 6.79005 17.1114 7.39215C17.2226 7.7136 17.2341 7.81032 17.0028 8.05798C13.7156 11.5789 10.4278 15.0998 7.14055 18.6207C6.38998 19.4246 5.64098 20.2302 4.88728 21.0302C4.8728 21.0457 4.85757 21.0604 4.84172 21.0745Z'
        fill='#3156FF'
      />
      <path
        d='M5.78791 13.3253C5.77193 13.3193 5.76129 13.3044 5.76129 13.2874C5.76129 13.2231 5.76129 13.1588 5.76129 13.095C5.76129 9.44944 5.76129 5.80444 5.76129 2.15888C5.76129 1.71164 5.85054 1.61437 6.26027 1.61437C6.42939 1.61437 6.59902 1.60934 6.76761 1.6166C6.97848 1.62555 7.10062 1.7614 7.11158 1.98725C7.11471 2.04987 7.11262 2.11248 7.11262 2.17565C7.11262 5.34714 7.11106 8.51863 7.1168 11.6901C7.11732 11.874 7.06408 11.9998 6.94455 12.125C6.56905 12.5162 6.20313 12.9177 5.8339 13.3151C5.82231 13.3276 5.80389 13.3312 5.78791 13.3253Z'
        fill='#3156FF'
      />
      <path
        d='M13.8818 12.4489C13.8933 12.4558 13.9 12.4681 13.9 12.4814C13.9 12.543 13.9 12.6046 13.9 12.6662C13.9 15.3424 13.9 18.018 13.9 20.6942C13.9 21.1185 13.7988 21.2275 13.4047 21.2286C13.2617 21.2292 13.1187 21.2303 12.9751 21.2281C12.6578 21.2242 12.5398 21.0978 12.5398 20.7573C12.5393 18.6458 12.5508 16.5343 12.5299 14.4228C12.5257 14.0046 12.6223 13.7049 12.9193 13.4321C13.2453 13.1332 13.5319 12.7846 13.8342 12.4558C13.8464 12.4425 13.8664 12.4397 13.8818 12.4489Z'
        fill='#3156FF'
      />
      <path
        d='M9.16634 9.71208C9.15455 9.70576 9.14691 9.69324 9.14691 9.67986C9.14691 9.61943 9.14691 9.55896 9.14691 9.49804C9.14691 7.05164 9.14691 4.60525 9.14691 2.15885C9.14691 1.71608 9.24138 1.61377 9.64903 1.61377C9.78578 1.61377 9.92253 1.61321 10.0588 1.61377C10.3928 1.61489 10.5055 1.73397 10.5055 2.08841C10.5061 3.95619 10.4915 5.82453 10.516 7.69231C10.5223 8.14794 10.4194 8.47946 10.0911 8.77296C9.77563 9.0551 9.50158 9.39072 9.21087 9.7045C9.19937 9.7169 9.18124 9.72007 9.16634 9.71208Z'
        fill='#3156FF'
      />
      <path
        d='M6.81406 0.895883C6.60834 0.88075 6.43213 0.721886 6.44231 0.515864C6.4517 0.322992 6.55244 0.206711 6.72625 0.170932C6.80194 0.155278 6.88179 0.15919 6.95956 0.15919C8.86678 0.158631 10.774 0.165341 12.6812 0.153601C12.7349 0.153255 12.7847 0.155336 12.8306 0.160355C13.3798 0.220466 12.7057 0.748253 12.2793 1.09951C12.1026 1.24482 11.9857 1.42192 11.9195 1.63096C11.8609 1.81604 11.7068 1.96884 11.5127 1.96884C11.4665 1.96884 11.4199 1.96884 11.3728 1.96884C11.2711 1.96884 11.1842 1.89496 11.1691 1.79441C11.0939 1.30468 10.7061 0.922854 10.2447 0.898815C9.99785 0.885957 9.74992 0.888191 9.50304 0.893782C8.9482 0.90664 8.56039 1.27617 8.4774 1.86373C8.46869 1.92591 8.41495 1.97219 8.35216 1.97219C8.28171 1.97219 8.21149 1.97219 8.1408 1.97219C7.94999 1.97219 7.79754 1.82365 7.74079 1.64148C7.6738 1.42642 7.55552 1.24152 7.37086 1.0928C7.20034 0.955213 7.01196 0.910441 6.81406 0.895883Z'
        fill='#3156FF'
      />
      <path
        d='M9.2056 17.4271C9.66829 16.9247 10.5063 17.2566 10.5063 17.9396C10.5063 17.9488 10.5063 17.958 10.5063 17.9671C10.5063 18.9002 10.5068 19.8333 10.5058 20.7663C10.5053 21.095 10.3878 21.2225 10.0835 21.2275C9.90136 21.2303 9.7192 21.2326 9.53756 21.2264C9.28285 21.218 9.15027 21.0822 9.14923 20.8105C9.14557 19.7382 9.14662 18.666 9.14975 17.5931C9.14975 17.5367 9.17011 17.4651 9.2056 17.4271Z'
        fill='#3156FF'
      />
      <path
        d='M13.8553 4.73451C13.3874 5.24249 12.5396 4.90762 12.5395 4.21696C12.5394 3.46689 12.5398 2.72675 12.5421 1.98679C12.5426 1.76373 12.6804 1.62284 12.8882 1.61614C13.1095 1.60887 13.3308 1.60831 13.5521 1.61614C13.7588 1.6234 13.895 1.76317 13.8966 1.98902C13.9028 2.86058 13.9002 3.73214 13.8976 4.60314C13.8976 4.6473 13.883 4.70433 13.8553 4.73451Z'
        fill='#3156FF'
      />
      <path
        d='M1.65804 5.77819C1.00798 6.47338 0.0272552 6.02553 0.607552 5.27113C0.670183 5.1897 0.737636 5.11011 0.809902 5.0324C0.907228 4.92765 1.00782 4.83249 1.11157 4.74685C1.81568 4.1656 2.28164 5.11129 1.65804 5.77819Z'
        fill='#3156FF'
      />
      <path
        d='M3.68066 8.42492C4.36916 7.68864 5.38662 8.15149 4.75175 8.93449C4.59093 9.13284 4.40765 9.30766 4.20971 9.45436C3.4729 10.0004 3.05427 9.09477 3.68066 8.42492Z'
        fill='#3156FF'
      />
      <path
        d='M4.66396 5.54846C4.76382 5.4415 4.94054 5.46306 5.01655 5.5881C5.06785 5.67249 5.05445 5.77707 4.98707 5.84927C3.85632 7.06091 2.73636 8.26098 1.59666 9.48221C1.52194 9.56228 1.40841 9.58149 1.31817 9.51944C1.20697 9.44299 1.18131 9.27972 1.27338 9.18106C2.40283 7.97084 3.52576 6.76761 4.66396 5.54846Z'
        fill='#3156FF'
      />
      <path
        d='M0.766534 8.54689C0.657508 8.664 0.474858 8.65784 0.391758 8.52111C0.336641 8.43042 0.338183 8.30025 0.409251 8.22144C0.419721 8.20982 0.430207 8.19836 0.440816 8.18699C1.53953 7.01076 2.64137 5.83731 3.73433 4.6538C3.87161 4.50566 3.97443 4.47826 4.12058 4.61076C4.21033 4.6927 4.23042 4.82495 4.14766 4.91395C3.02455 6.12176 1.90171 7.32756 0.766534 8.54689Z'
        fill='#3156FF'
      />
      <path
        d='M6.30643 20.5736C6.59393 20.2651 7.11821 20.4688 7.108 20.8903C7.10278 21.0961 6.97177 21.209 6.79065 21.2207C6.55734 21.2358 6.32194 21.2358 6.08915 21.2179C5.94207 21.2064 5.92218 20.9861 6.02272 20.8781C6.11794 20.7758 6.21237 20.6745 6.30643 20.5736Z'
        fill='#3156FF'
      />
    </svg>
  );
};

export default CricketMenuIcon;
