import React, { useState } from "react";
import { Stack, Select, MenuItem, Button } from "@mui/material";

import "../../../assets/styles/css/selectType.scss";

export const SelectType = ({selectedType,handleTypeChange,options}) => {

  return (
    
      <Stack className="select-main-stack-inner">
        <p className="select-text">Type</p>
        <Select
          value={selectedType}
          onChange={handleTypeChange}
          className="select-type"
        >
          
          {options?.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              className="select-menu-item"
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </Stack>
      
  
  );
};
