
import { Box, Flex, Select, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { AXIOS_INSTANCE } from "../../service";
import { BASE_URL_BANKING } from "../../utils/constants";
import { Button } from "@mui/material";
import { paymentDetailsApi } from "../../service/WalletService";
import {
  addUserBankData,
  addUserGpayData,
  addUserPaytmData,
  addUserPhonePeData,
} from "../../redux/features/paymentSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import isEmpty from "is-empty";

const Withdrawal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userBankData = useSelector(
    (state) => state.paymentDetails.userBankData
  );
  // const userPaytmData = useSelector(
  //   (state) => state.paymentDetails.userPaytmData
  // );
  // const userGpayData = useSelector(
  //   (state) => state.paymentDetails.userGpayData
  // );
  // const userPhonepeData = useSelector(
  //   (state) => state.paymentDetails.userPhonePeData
  // );
  // const userBhimData = useSelector(
  //   (state) => state.paymentDetails.userBhimData
  // );
  const toast = useToast();
  const [transferMode, setTransferMode] = useState("Bank");
  const [bankDetailId, setBankDetailId] = useState("");
  const [selectedBankType, setSelectedBankType] = useState("");
  const [selectedUPIId, setSelectedUPIId] = useState("");
  const [selectedUPIType, setSelectedUPIType] = useState("");
  const [isRedirect, setIsRedirect] = useState(false);
  const [formData, setFormData] = useState({
    amount: "",
    accountName: "",
    accountNumber: "",
    transferType: "IMPS",
    ifscCode: "",
    bankName: "",
    bankBranch: "",
    amount: "",
    accountName: "",
    accountNumber: "",
    transferType: "IMPS",
    ifscCode: "",
    bankName: "",
    bankBranch: "",
    // Add more fields as needed
  });
  const [selectedAccountNumber, setSelectedAccountNumber] = useState("");
  const [selectedBankHolderName, setSelectedBankHolderName] = useState("");
  const [selctedIfscCode, setSelectedIfscCode] = useState("");
  const [selectedBankName, setSelectedBankName] = useState("");
  const [selectedBranchName, setSelectedBranchName] = useState("");
  const [paymentModes, setPaymentModes] = useState([]);
  const [selectedUPINumber, setSelectedUPINumber] = useState("");
  const [selectedUPIUserName, setSelectedUPIUserName] = useState("");
  const [usdtData, setUsdtData] = useState("");

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleTransferModeChange = (event) => {
    setTransferMode(event.target.value);
  };

  const handleAccountNumberChange = (e) => {
    const accountselectedNumber = e.target.value;
    setSelectedAccountNumber(accountselectedNumber);
    const selectedObj = paymentModes.find(
      (item) => item.accountNumber === accountselectedNumber
    );
    if (selectedObj) {
      setSelectedBankHolderName(selectedObj.userName);
      setSelectedIfscCode(selectedObj.ifscCode);
      setSelectedBankName(selectedObj.bankName);
      setSelectedBankType(selectedObj.type);
      setSelectedBranchName(selectedObj.bankName);
      setBankDetailId(selectedObj.paymentBankDetailsId);
    }
  };

  const handleUPINumberChange = (e) => {
    const selectedNumber = e.target.value;
    setSelectedUPINumber(selectedNumber);

    // Find the corresponding object based on the selected UPI number
    const selectedObject = paymentModes.find(
      (item) => item.value === selectedNumber
    );

    // Update the state with the corresponding details
    if (selectedObject) {
      setSelectedUPIUserName(selectedObject.userName);
      setSelectedUPIId(selectedObject.paymentUpiDetailsId);
      setSelectedUPIType(selectedObject.type);
    } else {
      setSelectedUPIUserName("");
      setSelectedUPIId("");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let id;
    let paymentType;
    let upiId;
    if (transferMode === "Bank") {
      id = bankDetailId;
      paymentType = selectedBankType;
    } else if (transferMode === "USDT") {
      id = usdtData.find(
        (item) => item.value === selectedUPINumber
      ).paymentUpiDetailsId;
      paymentType = transferMode;
    } else {
      upiId = selectedUPIId;
      paymentType = selectedUPIType;
    }

    const payload = {
      coins: formData.amount,
      paymentType: paymentType,
      paymentBankDetailsId: id,
      paymentUpiDetailsId: upiId,
    };

    try {
      // Make the API request using Axios
      const response = await AXIOS_INSTANCE.post(
        `${BASE_URL_BANKING}/api/acc/withdraw-coins`,
        payload
      );
      console.log(" WithdrawResponse:", response);
      if (response?.data?.statusCode === 200) {
        //logic here

        toast({
          title: "Withdraw Request Sent",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: response?.data?.message || "error occured",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }

      // Handle success or navigate to a different page
    } catch (error) {
      // Handle error
      console.error("API error:", error);
    }
  };

  useEffect(() => {
    const paymentDetails = async () => {
      try {
        const res = await paymentDetailsApi();
        console.log();

        if (res?.status == 200) {
          //setBankDetails(res?.data?.data?.UserBankDetails)
          if (
            res?.data?.data?.UserBankDetails.length === 0 &&
            // res?.data?.data?.UserUPIDetails.bhim.length === 0 &&
            // res?.data?.data?.UserUPIDetails.gpay.length === 0 &&
            // res?.data?.data?.UserUPIDetails.paytm.length === 0 &&
            // res?.data?.data?.UserUPIDetails.phonepe.length === 0 &&
            res?.data?.data?.UserUPIDetails.usdt.length === 0
          ) {
            setIsRedirect(true);
          }

          dispatch(addUserBankData(res?.data?.data?.UserBankDetails));
          //setBankDetails(res?.data?.data?.UserBankDetails);
          //setGpay(res?.data?.data?.UserUPIDetails?.gpay);

          // dispatch(addUserGpayData(res?.data?.data?.UserUPIDetails?.gpay));
          //setPaytm(res?.data?.data?.UserUPIDetails?.paytm);

          // dispatch(addUserPaytmData(res?.data?.data?.UserUPIDetails?.paytm));
          //setPhonePe(res?.data?.data?.UserUPIDetails?.phonepe);

          // dispatch(
          //   addUserPhonePeData(res?.data?.data?.UserUPIDetails?.phonepe)
          // );

          setUsdtData(res?.data?.data?.UserUPIDetails?.usdt);

          //console.log("bankDetails", bankDetails);
        } else {
        }
      } catch (error) {
        console.log(error);
      }
    };

    paymentDetails();
  }, []);

  useEffect(() => {
    console.log(isRedirect, "redirect")
    if (isRedirect) {
      toast({
        title: "Please add bank account details first",
        status: "warning",
        duration: 9000,
        isClosable: true,
      });

      navigate("/bank-account");
    } else {
      let paymentModes_ = [];
      userBankData.map((item) =>
        paymentModes_.push({
          ...item,
          type: item.bankType,
          name: item.bankName,
        })
      );
      // userGpayData.map((item) =>
      //   paymentModes_.push({ ...item, type: "UPI_GPAY", name: "Google Pay" })
      // );
      // userPhonepeData.map((item) =>
      //   paymentModes_.push({ ...item, type: "UPI_PHONEPE", name: "PhonePe" })
      // );
      // userPaytmData.map((item) =>
      //   paymentModes_.push({ ...item, type: "UPI_PAYTM", name: "Paytm UPI" })
      // );
      // userBhimData.map((item) =>
      //   paymentModes_.push({ ...item, type: "UPI_BHIM", name: "BHIM UPI" })
      // );
      //setIsLoading(false);
      setPaymentModes(paymentModes_);
    }
  }, [userBankData, isRedirect]);

  return (
    <Box>
      <form
        onSubmit={handleSubmit}
        className="text-left form-validate"
        id="payment-withdraw-form"
      >
        <input
          type="hidden"
          name="_token"
          value="sKSy09j7QJahhRcMcehxhwnqEM01Sk6xpgz7FcxZ"
        />

        <div className="valresponse"></div>
        <div className="form-group mb-4">
          <label>Amount</label>
          <input
            id="amount"
            type="number"
            name="amount"
            required="true"
            className="form-control"
            placeholder="Amount"
            value={formData.amount}
            onChange={handleChange}
            min="1000"
            autocomplete="off"
            max="10000000"
          />
        </div>
        {/* {transferMode === "UPI" ? (
          <h5 className="text-center">UPI Details</h5>
        ) : ( */}
          <h5 className="text-center">Account Details</h5>
        {/* )} */}

        <Flex className="form-group" gap="20px" align={"center"}>
          <Flex gap="10px" align={"center"}>
            <input
              type="radio"
              className="transfer_mode"
              checked={transferMode === "Bank"}
              value="Bank"
              id="bank_mode"
              onChange={handleTransferModeChange}
            />
            <label style={{ marginBottom: 0 }}>Bank</label>
          </Flex>
          <Flex gap="10px" align={"center"}>
            <input
              type="radio"
              className="transfer_mode"
              checked={transferMode === "USDT"}
              value="USDT"
              id="usdt_mode"
              onChange={handleTransferModeChange}
            />
            <label style={{ marginBottom: 0 }}>USDT</label>
          </Flex>
          {/* <Flex gap="10px" align={"center"}>
            <input
              type="radio"
              className="transfer_mode"
              checked={transferMode === "UPI"}
              value="UPI"
              id="upi_mode"
              onChange={handleTransferModeChange}
            />
            <label style={{ marginBottom: 0 }}>UPI</label>
          </Flex> */}
        </Flex>

        {transferMode === "Bank" && (
          <div className="bank-details info-sec">
            <div className="row">
              <div className="col-6">
                <label>Account Number</label>
                <Select
                  value={selectedAccountNumber}
                  onChange={handleAccountNumberChange}
                  className="form-control bank-input"
                >
                  <option value="">Select Account Number</option>
                  {paymentModes.map((item, index) => (
                    <option key={index} value={item.accountNumber}>
                      {item.accountNumber}
                    </option>
                  ))}
                </Select>
                {/* <div className="form-group"><label>Account number</label>
                     <input id="account_number" type="text" data-maxlen="24" 
                     name="accountNumber" required="true" className="form-control bank-input do-alphn"
                      placeholder="Account number" autocomplete="off"
                      value={formData.accountNumber}
                     onChange={handleChange}
                      /></div> */}
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label>Name</label>
                  <input
                    id="account_name"
                    type="text"
                    name="accountName"
                    required="true"
                    className="form-control bank-input do-alphn-s"
                    placeholder="Account Holder Name"
                    data-maxlen="28"
                    autocomplete="off"
                    value={selectedBankHolderName}
                  />
                </div>
              </div>
            </div>
           
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label>Bank name</label>
                  <input
                    id="bank_name"
                    type="text"
                    data-maxlen="40"
                    name="bankName"
                    required="true"
                    className="form-control bank-input do-alphn-s"
                    placeholder="Bank Name"
                    autocomplete="off"
                    value={selectedBankName}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label>Bank branch</label>
                  <input
                    id="bank_branch"
                    type="text"
                    data-maxlen="40"
                    name="bankBranch"
                    required="true"
                    className="form-control bank-input do-alphn-s"
                    placeholder="Bank Branch"
                    autocomplete="off"
                    value={selectedBranchName}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              {/* <div className="col-6">
                <div className="form-group">
                  <label>Transfer Type</label>
                  <select
                    name="transferType"
                    className="form-control bank-input"
                    id="transfer_type"
                    value={formData.transferType} // Set the value to the corresponding state variable
                    onChange={handleChange}
                  >
                    <option value="IMPS">IMPS</option>
                    <option value="NEFT">NEFT</option>
                  </select>
                </div>
              </div> */}
              <div className="col-6">
                <div className="form-group">
                  <label>IFSC code</label>
                  <input
                    id="ifsc_code"
                    type="text"
                    name="ifscCode"
                    required="true"
                    className="form-control bank-input do-alphn"
                    placeholder="IFSC Code"
                    minlength="11"
                    data-maxlen="11"
                    autocomplete="off"
                    value={selctedIfscCode}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {transferMode === "USDT" && (
          <div className="upi-details info-sec">
            <div className="form-group">
              <div>
                <label>USDT Address</label>
                <Select
                  value={selectedUPINumber}
                  onChange={handleUPINumberChange}
                  className="form-select text-black w-100 border-4"
                >
                  <option value="">Select USDT Address</option>
                  {Array.isArray(usdtData) && usdtData.length && (
                    usdtData.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.value}
                      </option>
                    ))
                  )}
                </Select>
              </div>
            </div>
          </div>
        )}
        {/* {transferMode === "UPI" && (
          <div className="upi-details info-sec">
            <div className="form-group">
              <div>
                <label>UPI Number</label>
                <Select
                  value={selectedUPINumber}
                  onChange={handleUPINumberChange}
                  className="form-select text-black w-100 border-4"
                >
                  <option value="">Select UPI Number</option>
                  {paymentModes.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.value}
                    </option>
                  ))}
                </Select>
              </div>

              <br />
              <div>
                <label>User Name:</label>
                <input
                  type="text"
                  value={selectedUPIUserName}
                  className="form-control"
                  readOnly
                />
              </div>
              <div>
                <label>UPI ID:</label>
                <input
                  type="text"
                  value={selectedUPIId}
                  className="form-control"
                  readOnly
                />
              </div>
            </div>
          </div>
        )} */}
        

        <div className="form-group text-center mt-1">
          <Button type="submit" variant="contained" className="submit-button">
            Withdraw
          </Button>
        </div>
      </form>
    </Box>
  );
};

export default Withdrawal;
