import { Box, Button, Input, Select, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomTable from "../../components/Table";
import { profitLossReportAPI } from "../../service/AuthService";
import { getCurrentDateTimeLocal } from "../../utils/constants";
import moment from "moment";

export default function ProfitLossReport() {
  const [from, setFrom] = useState(moment().format("YYYY-MM-DDTHH:MM"));
  const [to, setTo] = useState(moment().format("YYYY-MM-DDTHH:MM"));
  const [pageNumber, setPageNumber] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [totalPages, setTotalPages] = React.useState(0);
  const [data, setData] = useState([]);
  const getProfitLossReport = async () => {
    const res = await profitLossReportAPI({
      from: from,
      to: to,
    });
    if (res.code === 200) {
      setData(res.response);
    }
  };
  useEffect(() => {
    getProfitLossReport();
  }, []);

  return (
    <Box className="col-md-12 report-main-content setting-page-main">
      <Box
        bg="#4F4F4F"
        p="2"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        w="100%"
        fontWeight="bold"
        color="white"
        background="#ae4600bd"
        padding={2}
        marginTop="-5px"
      >
        <Text fontSize="xl" color="#fff !important">
          Profit Loss Report
        </Text>
      </Box>
      <Box mt="2" display="flex" mb="2" w="50%" className="input-box-head">
        <Input
          value={from}
          onChange={(e) => {
            setFrom(e.target.value);
          }}
          mx="1"
          type="datetime-local"
          max={from}
        />
        <Input
          onChange={(e) => setTo(e.target.value)}
          value={to}
          mx="1"
          type="datetime-local"
          min={from}
          max={to}
        />
        <button
          style={{
            backgroundColor: "#0079FB",
            color: "white",
            paddingLeft: "15px",
            paddingRight: "15px",
            borderRadius: "5px",
            marginLeft: "10px",
          }}
          onClick={getProfitLossReport}
        >
          Submit
        </button>
      </Box>
      <CustomTable
        columns={[
          {
            name: "Event Type",
            id: "eventType",
          },
          {
            name: "Event Name",
            id: "eventName",
          },
          {
            name: "Amount",
            id: "amount",
          },
        ]}
        rows={data}
        showPagination={false}
        totalPages_={totalPages}
        rowsPerPage_={rowsPerPage}
        currentPage_={pageNumber}
      />
    </Box>
  );
}
