import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Image,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  WrapItem,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FaLock } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Flip, Flipr } from '../../../components/Flip';
import {
  getCasinoLastTenResultsAPI,
  getCasinoPlExposureAPI,
  placeCasinoBetAPI,
} from '../../../service/casino';
import PlaceBet from '../../game/PlaceBet';
import CardDrawer from '../../../components/CardDrawer/CardDrawer';
import CasinoVideoRules from '../../../components/CasinoVideoRules';
import { useMemo } from 'react';
import Iframe from 'react-iframe';
import IframeComponent from '../../../components/Iframe';
import LastResults from '../../../components/LastResults';
import { setGameKey } from '../../../redux/features/gameSlice';
import { getService } from '../../../utils/commonFunctions';
import { useLocation } from 'react-router-dom';

export default function TeenPatti() {
  const location = useLocation();
  const odds_ = useSelector((state) => state.gameData.teenPatti20Odds);
  //useMemo
  const odds = useMemo(() => odds_, [odds_]);
  const [selectedEventForBet, setSelectedEventForBet] = useState(null);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [isLocked, setIsLocked] = useState(false);
  const [lastTenResult, setLastTenResult] = useState([]);
  const userData = useSelector((state) => state.account.userData);
  const authDetails = useSelector((state) => state.account.authDetails);
  const [plExposure, setPlExposure] = useState(null);
  const toast = useToast();
  const gameName = 'TEEN_20';
  const dispatch = useDispatch();
  const CustomButton = ({ text, item, ml, size, children, ...rest }) => (
    <Button
      size={'lg'}
      my='0'
      ml={ml}
      display='flex'
      justifyContent='center'
      alignItems='center'
      className='pattiButton'
      onClick={isLocked ? null : rest.onClick}
      flexDirection='column'
    >
      {isLocked ? <FaLock /> : item ? item : text}
      {!isLocked && <Text className='expos-text'>{children}</Text>}
    </Button>
  );
  const getData = (id) => {
    let data = odds?.oddDetailsDTOS?.find((item) => item.runnerName == id);
    return data ? data : {};
  };
  const placeBet = async () => {
    try {
      let payload = {};
      payload.amount = parseInt(selectedAmount);
      payload.casinoGames = gameName;
      payload.profit = getProfit();
      payload.loss = parseInt(selectedAmount);
      payload.back = selectedEventForBet.back1;
      payload.selectionId = selectedEventForBet.selectionId;
      payload.marketId = odds.marketId;
      payload.runnerName = selectedEventForBet.runnerName;
      const { response, code } = await placeCasinoBetAPI(
        payload,
        userData.token
      );
      if (code == 200) {
        toast({
          title: 'Bet Placed',
          description: 'Your bet has been placed successfully',
          status: 'success',
        });
      } else {
        toast({
          description: response,
          status: 'error',
        });
      }
    } catch (e) {
      toast({
        description: e,
        status: 'error',
      });
    }
  };
  const getProfit = (selectedEventForBet) => {
    return (selectedEventForBet.back1 - 1) * selectedAmount;
  };
  const getEntity = (entity) => {
    return entity == '1' ? 'A' : 'B';
  };
  const setEvent = (id) => {
    let selectedEventForBet = getData(id);
    let payload = {};
    payload.amount = parseInt(selectedAmount);
    payload.casinoGames = gameName;
    payload.profit = getProfit(selectedEventForBet);
    payload.loss = parseInt(selectedAmount);
    payload.selectedOdd = selectedEventForBet.back1;
    payload.selectedId = selectedEventForBet.selectionId;
    payload.marketId = odds.marketId;
    payload.runnerName = selectedEventForBet.runnerName;
    payload.type = 'back';

    dispatch({
      type: 'gameData/setSelectedEventForBet',
      payload,
    });
  };

  useEffect(() => {
    dispatch(setGameKey('teenPatti20'));
  }, [dispatch]);

  useEffect(() => {
    if (odds?.autoTime <= 5) {
      setIsLocked(true);
    } else {
      setIsLocked(false);
    }
  }, [odds, getData]);

  const getPlExposure = async (id) => {
    const res = await getCasinoPlExposureAPI(
      getService(location.pathname).port,
      getService(location.pathname).service,
      id
    );
    if (res && res.code == 200) {
      setPlExposure(res.response);
    }
  };
  const getExposureForEntity = (selectionName, selectionId) => {
    if (plExposure) {
      let data = plExposure?.find((item) =>
        item?.casinoPLExposure?.find(
          (exposure) => exposure.selectionName == selectionName
        )
      );

      return data ? data : null;
    }
  };
  useEffect(() => {
    let interval = setInterval(() => {
      if (odds?.marketId) {
        getPlExposure(odds?.marketId);
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [odds?.marketId]);
  return (
    <Box
      w='100%'
      display='flex'
      flexDirection={{
        base: 'column',
        md: 'row',
      }}
    >
      <Box
        w={{
          base: '100%',
          md: '70%',
        }}
      >
        <Box h='60vh' mb='2' pos='relative' bg='black'>
          {odds?.cards && (
            <CardDrawer
              multiLevel={true}
              cards={[odds?.cards[0], odds?.cards[1]]}
              labels={['PLAYER A', 'PLAYER B']}
            />
          )}

          <div className='market-title'>20-20 TEENPATTI</div>

          <IframeComponent
            url={`${process.env.REACT_APP_IFRAME_URL}?id=3030`}
          />
          <Box
            bottom='0'
            p='2'
            pos='absolute'
            right='2'
            display='flex'
            bg='black'
          >
            <Flip value={odds?.autoTime} />
          </Box>
          <CasinoVideoRules
            custom={
              <div className='rules-body'>
                <img
                  src='https://sitethemedata.com/casino-new-rules-images/teen20b.jpg'
                  className='rules-body'
                />
              </div>
            }
          />
        </Box>
        <div style={{marginTop:"30px"}}>
          <div className='main-market m-t-0 table-responsive'>
            {/* removed "table-responsive" from this class */}
            <table className='table coupon-table table table-bordered '>
              <thead>
                <tr>
                  <th className='box-5'></th>
                  <th className='box-2 back-color'>BACK</th>
                  <th className='box-3 back-color'></th>
                </tr>
              </thead>
              <tbody>
                <tr className='bet-info'>
                  <td className='box-5'>
                    <b>Player A</b>
                  </td>
                  <td
                    className={`text-center box-2 back ${
                      isLocked ? 'suspendedtd' : ''
                    }`}
                  >
                    <button
                      onClick={() => setEvent('Player A')}
                      className='back'
                    >
                      <span className='d-block text-bold odd'>
                        <b>{getData('Player A').back1}</b>
                      </span>{' '}
                      <span className='d-block'>
                        {getExposureForEntity('Player A')?.exposure}
                      </span>
                    </button>
                  </td>
                  <td
                    className={`text-center box-3 back ${
                      isLocked ? 'suspendedtd' : ''
                    }`}
                  >
                    <button
                      onClick={() => setEvent('Pair plus A')}
                      className='back'
                    >
                      <span className='d-block text-bold odd'>
                        <b>Pair plus A</b>
                      </span>{' '}
                      <span className='d-block'>
                        {getData('Pair plus A').back1}
                      </span>
                    </button>
                  </td>
                </tr>
                <tr className='bet-info'>
                  <td className='box-5'>
                    <b>Player B</b>
                  </td>
                  <td
                    className={`text-center back box-2 ${
                      isLocked ? 'suspendedtd' : ''
                    }`}
                  >
                    <button
                      onClick={() => setEvent('Player B')}
                      className='back'
                    >
                      <span className='d-block text-bold odd'>
                        {console.log(getData('Total B').back1,"textttt")}
                        <b>{getData('Player B').back1}</b>
                      </span>{' '}
                      <span className='d-block'>
                        {getExposureForEntity('Player B')?.exposure}
                      </span>
                    </button>
                  </td>
                  <td
                    className={`text-center box-3 back  ${
                      isLocked ? 'suspendedtd' : ''
                    }`}
                  >
                    <button
                      onClick={() => setEvent('Pair plus B')}
                      className='back'
                    >
                      <span className='d-block text-bold odd'>
                        <b>Pair plus B</b>
                      </span>{' '}
                      <span className='d-block'>
                        {getData('Pair plus B').back1}
                      </span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
       
<br/>
        {/* <Grid
          className='teenpatti-bg'
          paddingRight={2}
          templateRows='repeat(6,1fr)'
          display={{ base: 'block', md: 'none' }}
        >
          <Grid templateColumns={'repeat(3,1fr)'} mb={2}>
            <GridItem>
              <Text color='red'></Text>
            </GridItem>
            <GridItem textAlign='center'>
              <Text color='red'>Player A</Text>
            </GridItem>
            <GridItem textAlign='center' borderLeft='0px'>
              <Text color='yellow'>Player B</Text>
            </GridItem>
          </Grid>
          <Grid templateColumns={'repeat(3,1fr)'} mb={2}>
            <GridItem
              textAlign='left'
              display={'flex'}
              alignItems={'center'}
              justifyContent={'flex-start'}
              bg='#00000047'
            >
              <Text fontSize='small' ml='1'>
                PLAYER
              </Text>
            </GridItem>
            <CustomButton
              onClick={() => setEvent('Player A')}
              text={getData('Player A').back1}
            />
            <CustomButton
              onClick={() => setEvent('Player B')}
              text={getData('Player B').back1}
              ml='2'
            />
          </Grid>
          <Grid templateColumns={'repeat(3,1fr)'} mb={2}>
            <GridItem
              textAlign='left'
              display={'flex'}
              alignItems={'center'}
              justifyContent={'flex-start'}
              bg='#00000047'
            >
              <Text fontSize='small' ml='1'>
                3 Baccarat
              </Text>
            </GridItem>
            <CustomButton
              onClick={() => setEvent('3 Baccarat A')}
              text={getData('3 Baccarat A').back1}
            />
            <CustomButton
              onClick={() => setEvent('3 Baccarat B')}
              text={getData('3 Baccarat B').back1}
              ml='2'
            />
          </Grid>
          <Grid templateColumns={'repeat(3,1fr)'} mb={2}>
            <GridItem
              textAlign='left'
              display={'flex'}
              alignItems={'center'}
              justifyContent={'flex-start'}
              bg='#00000047'
            >
              <Text fontSize='small' ml='1'>
                TOTAL
              </Text>
            </GridItem>
            <CustomButton
              onClick={() => setEvent('TOTAL A')}
              text={getData('TOTAL A').back1}
            />
            <CustomButton
              onClick={() => setEvent('TOTAL B')}
              text={getData('TOTAL B').back1}
              ml='2'
            />
          </Grid>
          <Grid templateColumns={'repeat(3,1fr)'} mb={2}>
            <GridItem
              textAlign='left'
              display={'flex'}
              alignItems={'center'}
              justifyContent={'flex-start'}
              bg='#00000047'
            >
              <Text fontSize='small' ml='1'>
                Pair plus
              </Text>
            </GridItem>
            <CustomButton
              onClick={() => setEvent('Pair plus A')}
              text={getData('Pair plus A').back1}
            />
            <CustomButton
              onClick={() => setEvent('Pair plus B')}
              text={getData('Pair plus B').back1}
              ml='2'
            />
          </Grid>
          <Grid templateColumns={'repeat(3,1fr)'} mb={2}>
            <GridItem
              textAlign='center'
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              bg='#00000047'
            >
              <Box display='flex'>
                <Image
                  boxSize='30px'
                  src={require('../../../assets/img/casino/spade.png')}
                />
                <Image
                  boxSize='30px'
                  src={require('../../../assets/img/casino/club.png')}
                />
              </Box>
            </GridItem>
            <CustomButton
              onClick={() => setEvent('Black A')}
              text={getData('Black A').back1}
            />
            <CustomButton
              onClick={() => setEvent('Black B')}
              text={getData('Black B').back1}
              ml='2'
            />
          </Grid>
          <Grid templateColumns={'repeat(3,1fr)'} mb={2}>
            <GridItem
              textAlign='center'
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              bg='#00000047'
            >
              <Box display='flex'>
                <Image
                  boxSize='30px'
                  src={require('../../../assets/img/casino/diamond.png')}
                />
                <Image
                  boxSize='30px'
                  src={require('../../../assets/img/casino/heart.png')}
                />
              </Box>
            </GridItem>
            <CustomButton
              onClick={() => setEvent('Red A')}
              text={getData('Red A').back1}
            />
            <CustomButton
              onClick={() => setEvent('Red B')}
              text={getData('Red B').back1}
              ml='2'
            />
          </Grid>
        </Grid> */}
        <LastResults eventId={gameName} />
      </Box>

      <PlaceBet
        game='casino'
        eventId={gameName}
        inningsRule={
          <div className='card m-b-10'>
            <div className='card-header' style={{ backgroundColor: '#9c3b3b' }}>
              <h6 className='card-title d-inline-block'>Rules</h6>
            </div>
            <div
              className='card-body'
              style={{ padding: '10px', backgroundColor: 'white' }}
            >
              <table className='table table-bordered rules-table'>
                <tbody>
                  <tr className='text-center'>
                    <th colspan='2'>Pair Plus</th>
                  </tr>
                  <tr>
                    <td width='60%'>Pair (Double)</td>
                    <td>1 To 1</td>
                  </tr>

                  <tr>
                    <td width='60%'>Flush (Color)</td>
                    <td>1 To 4</td>
                  </tr>

                  <tr>
                    <td width='60%'>Straight (Rown)</td>
                    <td>1 To 6</td>
                  </tr>

                  <tr>
                    <td width='60%'>Trio (Teen)</td>
                    <td>1 To 35</td>
                  </tr>

                  <tr>
                    <td width='60%'>Straight Flush (Pakki Rown)</td>
                    <td>1 To 45</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        }
      />
    </Box>
  );
}
