import { Box, FormLabel, Input, Select } from "@chakra-ui/react";
import React from "react";

export default function CustomSelect({
  label,
  className,
  containerStyle,
  ...rest
}) {
  return (
    <Box style={containerStyle} className={className}>
      {label && (
        <FormLabel color="#A6A6A6" fontSize="sm">
          {label}
        </FormLabel>
      )}
      <Select size="sm" {...rest}>
        {rest.options.map((option) => (
          <option
            style={{
              color: "black",
            }}
            key={option.value}
            value={option.value}
          >
            {option.label}
          </option>
        ))}
      </Select>
    </Box>
  );
}
