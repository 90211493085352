export const getService = (path) => {
  let service;
  let port;

  if (path.includes('teenpatti')) {
    service = 's1';
    port = 9002;
  }
  if (path.includes('dragon-tiger')) {
    service = 's2';
    port = 9003;
  }
  if (path.includes('card-32')) {
    service = 's3';
    port = 9004;
  }
  if (path.includes('lucky7')) {
    service = 's3';
    port = 9004;
  }
  if (path.includes('andar-bahar')) {
    service = 's4';
    port = 9005;
  }
  if (path.includes('race')) {
    service = 's4';
    port = 9005;
  }

  if (path.includes('poker')) {
    service = 's5';
    port = 9006;
  }
  if (path.includes('baccarat')) {
    service = 's5';
    port = 9006;
  }
  if (path.includes('cricket')) {
    service = 's6';
    port = 9007;
  }
  if (path.includes('worli')) {
    service = 's6';
    port = 9007;
  }
  if (path.includes('cn')) {
    service = 's7';
    port = 9008;
  }

  return { service, port };
};

export const getFingerprint = () => {
  const navigatorInfo = window.navigator;
  const screenInfo = window.screen;
  const { userAgent, language, platform, languages } = navigatorInfo;
  const { colorDepth, height, width, pixelDepth } = screenInfo;

  return [
    userAgent,
    language,
    platform,
    JSON.stringify(languages),
    colorDepth,
    height,
    width,
    pixelDepth,
    new Date().getTimezoneOffset(),
  ].join('::');
};

export const hashFingerprint = (fingerprint) => {
  let hash = 0,
    i,
    chr;
  if (fingerprint.length === 0) return hash;
  for (i = 0; i < fingerprint.length; i++) {
    chr = fingerprint.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};
