import React, { useState } from "react";
import { Box, FormControl, FormLabel, Input, InputGroup, InputRightElement, InputLeftElement } from "@chakra-ui/react";

const CustomInput = ({
  onChange,
  name,
  value,
  label,
  placeholder,
  className,
  iconPosition = "right", // Default to right
  icon,
  type = "text", // Default to text
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <Box>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <FormControl mb="3" className={`custom_input ${className}`}>
        <InputGroup>
          {icon && iconPosition === "left" && (
            <InputLeftElement
            pointerEvents="none"
            children={
              <Box className="icon_text" display="flex" alignItems="center">
                {icon}
              </Box>
            }
          />
          )}
          <Input
            {...rest}
            onChange={onChange}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            name={name}
            type={type}
            value={value}
            placeholder={placeholder}
            autoComplete="off"
            fontFamily="'Inter',Helvetica"
            fontSize="14px"
            fontWeight="500"
            color="#4e4e4e"
            height="40px"
            padding="10px"
            border="none"
            outline="none"
            paddingRight={icon && iconPosition === "right" ? "2.5rem" : "0"}
          />
          {icon && iconPosition === "right" && (
            <InputRightElement
            children={
              <Box className="icon_text" display="flex" alignItems="center" mt='18px'>
                {icon}
              </Box>
            }
          />
          )}
        </InputGroup>
      </FormControl>
    </Box>
  );
};

export default CustomInput;
