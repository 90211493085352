import React from "react";
import { Stack } from "@mui/material";
import SearchTextField from "../SearchField";
import "../../../assets/styles/css/searchWithText.scss";

export const SearchWithText = ({ text,onSearchClick }) => {
  return (
    <Stack direction={"row"} className="search-with-text-satck">
      <Stack className="search-with-text-satck-inner-1">
        <p className="search-with-text-style">{text}</p>
      </Stack>
      <Stack className="search-with-text-satck-inner-2">
        <SearchTextField onSearchClick={onSearchClick} />
      </Stack>
    </Stack>
  );
};
