import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Table,
  Tbody,
  Td,
  Text,
  Image,
  Th,
  Thead,
  Tr,
  useToast,
  WrapItem,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaLock } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Flip, Flipr } from "../../../components/Flip";
import {
  getCasinoPlExposureAPI,
  placeCasinoBetAPI,
} from "../../../service/casino";
import PlaceBet from "../../game/PlaceBet";
import { getCasinoLastTenResultsAPI } from "../../../service/casino";
import "./css/style.css";
import CardDrawer from "../../../components/CardDrawer/CardDrawer";
import { ASSETS_URL } from "../../../utils/constants";
import CasinoVideoRules from "../../../components/CasinoVideoRules";
import { useMemo } from "react";
import Iframe from "react-iframe";
import IframeComponent from "../../../components/Iframe";
import LastResults from "../../../components/LastResults";
import { setGameKey } from "../../../redux/features/gameSlice";
import { getService } from "../../../utils/commonFunctions";
import { useLocation } from "react-router-dom";

export default function DragonTiger20() {
  const location = useLocation();
  const odds_ = useSelector((state) => state.gameData.dragonTiger20Odds);
  //useMemo
  const odds = useMemo(() => odds_, [odds_]);
  const authDetails = useSelector((state) => state.account.authDetails);
  const [plExposure, setPlExposure] = useState(null);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [isLocked, setIsLocked] = useState(false);
  const userData = useSelector((state) => state.account.userData);
  const toast = useToast();
  const dispatch = useDispatch();
  const gameName = "DRAGON_TIGER_20";
  const tigerCard = [
    {
      id: "Tiger Card 1",
      img: "A.png",
    },
    {
      id: "Tiger Card 2",
      img: "2.png",
    },
    {
      id: "Tiger Card 3",
      img: "3.png",
    },
    {
      id: "Tiger Card 4",
      img: "4.png",
    },
    {
      id: "Tiger Card 5",
      img: "5.png",
    },
    {
      id: "Tiger Card 6",
      img: "6.png",
    },
    {
      id: "Tiger Card 7",
      img: "7.png",
    },
    {
      id: "Tiger Card 8",
      img: "8.png",
    },
    {
      id: "Tiger Card 9",
      img: "9.png",
    },
    {
      id: "Tiger Card 10",
      img: "10.png",
    },
    {
      id: "Tiger Card J",
      img: "J.png",
    },
    {
      id: "Tiger Card Q",
      img: "Q.png",
    },
    {
      id: "Tiger Card K",
      img: "K.png",
    },
  ];
  const dragonCard = [
    {
      id: "Dragon Card 1",
      img: "A.png",
    },
    {
      id: "Dragon Card 2",
      img: "2.png",
    },
    {
      id: "Dragon Card 3",
      img: "3.png",
    },
    {
      id: "Dragon Card 4",
      img: "4.png",
    },
    {
      id: "Dragon Card 5",
      img: "5.png",
    },
    {
      id: "Dragon Card 6",
      img: "6.png",
    },
    {
      id: "Dragon Card 7",
      img: "7.png",
    },
    {
      id: "Dragon Card 8",
      img: "8.png",
    },
    {
      id: "Dragon Card 9",
      img: "9.png",
    },
    {
      id: "Dragon Card 10",
      img: "10.png",
    },
    {
      id: "Dragon Card J",
      img: "J.png",
    },
    {
      id: "Dragon Card Q",
      img: "Q.png",
    },
    {
      id: "Dragon Card K",
      img: "K.png",
    },
  ];
  const CustomButton = ({ text }) => (
    <Button size="sm" my="2" className="casinoButton">
      {isLocked ? <FaLock /> : text}
    </Button>
  );
  const getData = (id) => {
    let data = odds?.oddDetailsDTOS?.find((item) => item.runnerName == id);
    return data ? data : {};
  };

  const getProfit = (selectedEventForBet) => {
    return (selectedEventForBet.back1 - 1) * selectedAmount;
  };
  const setEvent = (id) => {
    if (isLocked) return;
    let selectedEventForBet = getData(id);
    let payload = {};
    payload.amount = parseInt(selectedAmount);
    payload.casinoGames = gameName;
    payload.profit = getProfit(selectedEventForBet);
    payload.loss = parseInt(selectedAmount);
    payload.selectedOdd = selectedEventForBet.back1;
    payload.selectedId = selectedEventForBet.selectionId;
    payload.marketId = odds.marketId;
    payload.runnerName = selectedEventForBet.runnerName;
    payload.type = "back";
    dispatch({
      type: "gameData/setSelectedEventForBet",
      payload,
    });
  };

  useEffect(() => {
    dispatch(setGameKey("dragonTiger20"));
  }, [dispatch]);
  useEffect(() => {
    if (odds.autoTime <= 5) {
      setIsLocked(true);
    } else {
      setIsLocked(false);
    }
  }, [odds, getData]);
  const getPlExposure = async (id) => {
    const res = await getCasinoPlExposureAPI(
      getService(location.pathname).port,
      getService(location.pathname).service,
      id,
      authDetails?.uid
    );
    if (res && res?.code == 200) {
      setPlExposure(res.response);
    }
  };
  const getExposureForEntity = (selectionName, selectionId) => {
    if (plExposure) {
      let data;
      for (let i = 0; i < plExposure.length; i++) {
        data = plExposure[i]?.casinoPLExposure?.find(
          (item) => item.selectionName == selectionName
        );
        if (data) {
          return data;
        }
      }
      return data ? data : null;
    }
  };
  useEffect(() => {
    let interval = setInterval(() => {
      if (odds?.marketId) {
        getPlExposure(odds?.marketId);
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [odds?.marketId]);

  return (
    <Box
      display="flex"
      flexDirection={{ base: "column", sm: "column", lg: "row" }}
      mt="0"
    >
      <Box mr="1" w={{ base: "100%", sm: "100%", lg: "70%" }}>
        <Box className="casino-video" mb="2" pos="relative">
          {odds.cards && <CardDrawer cards={odds?.cards[0]} />}
          <div className="market-title">20-20 DRAGON TIGER </div>

          <IframeComponent
            url={`${process.env.REACT_APP_IFRAME_URL}?id=3035`}
          />
          <Box
            bottom="0"
            p="2"
            pos="absolute"
            right="2"
            display="flex"
            bg="black"
          >
            <Flip value={odds?.autoTime} />
          </Box>

          <CasinoVideoRules image={`${ASSETS_URL}dragon-tiger-20-rules.jpg`} />
        </Box>
        <Grid className="grid-btn-group dt-container">
          <div className="d-t-box m-b-10 m-t-10">
            <div className="row">
              <div className="col-md-9">
                <div className="row">
                  <div className="col-md-5 text-center">
                    <b>{getData("Dragon").back1}</b>
                    <div className="m-t-10">
                      <button
                        className={`text-uppercase btn-theme low-high-btn ${
                          isLocked ? "suspended" : ""
                        }`}
                        onClick={() => setEvent("Dragon")}
                      >
                        <b>Dragon</b>
                      </button>{" "}
                      <span className="d-block m-t-5">
                        {getExposureForEntity("Dragon")?.exposure}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-2 text-center">
                    <b>{getData("Tie").back1}</b>
                    <div className="m-t-10">
                      <button
                        className={`text-uppercase  btn-theme low-high-btn ${
                          isLocked ? "suspended" : ""
                        }`}
                        onClick={() => setEvent("Tie")}
                      >
                        <b>Tie</b>
                      </button>{" "}
                      <span className="d-block m-t-5">
                        {getExposureForEntity("Tie")?.exposure}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-5 text-center">
                    <b>{getData("Tiger").back1}</b>
                    <div className="m-t-10">
                      <button
                        className={`text-uppercase btn-theme low-high-btn ${
                          isLocked ? "suspended" : ""
                        }`}
                        onClick={() => setEvent("Tiger")}
                      >
                        <b>Tiger</b>
                      </button>{" "}
                      <span className="d-block m-t-5">
                        {getExposureForEntity("Tiger")?.exposure}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 pair-block  text-center">
                <b>{getData("Pair").back1}</b>
                <div className="m-t-10">
                  <button
                    className={`text-uppercase btn-theme low-high-btn ${
                      isLocked ? "suspended" : ""
                    }`}
                    onClick={() => setEvent("Pair")}
                  >
                    <b>Pair</b>
                  </button>{" "}
                  <span className="d-block m-t-5">
                    {getExposureForEntity("Pair")?.exposure}
                  </span>
                </div>
              </div>
              <div className="col-md-12 m-t-10 text-right">
                <span className="m-r-10">
                  <b>Min: </b>
                  <span>100</span>
                </span>{" "}
                <span>
                  <b>Max: </b>
                  <span>300000</span>
                </span>
              </div>
            </div>
            {/* <Button
              className={`btn-theme ${isLocked ? "suspended" : ""}`}
              onClick={() => setEvent("Dragon")}
            >
              <Text>Dragon</Text>
              {isLocked ? (
                <FaLock />
              ) : (
                <Text fontWeight="bold">{getData("Dragon").back1}</Text>
              )}
              {getExposureForEntity("Dragon")?.exposure}
            </Button>
            <Button
              className={`btn-theme ${isLocked ? "suspended" : ""}`}
              onClick={() => setEvent("Tie")}
            >
              <Text>Tie</Text>
              {isLocked ? (
                <FaLock />
              ) : (
                <Text fontWeight="bold">{getData("Tie").back1}</Text>
              )}
              {getExposureForEntity("Tie")?.exposure}
            </Button>
            <Button
              className={`btn-theme ${isLocked ? "suspended" : ""}`}
              onClick={() => setEvent("Tiger")}
            >
              <Text>Tiger</Text>
              {isLocked ? (
                <FaLock />
              ) : (
                <Text fontWeight="bold">{getData("Tiger").back1}</Text>
              )}
              {getExposureForEntity("Tiger")?.exposure}
            </Button>
            <Button
              className={`btn-theme ${isLocked ? "suspended" : ""}`}
              onClick={() => setEvent("Pair")}
            >
              <Text>Pair</Text>
              {isLocked ? (
                <FaLock />
              ) : (
                <Text fontWeight="bold">{getData("Pair").back1}</Text>
              )}
              {getExposureForEntity("Pair")?.exposure}
            </Button> */}
          </div>
          <div className="row row5 ">
            <div className="col-md-6">
              <div className="d-t-box m-b-10">
                <div className="row ">
                  <div className="col-12">
                    <h4 className="m-b-0 text-center text-uppercase">Dragon</h4>
                  </div>
                  <div
                    className="col-md-6 text-center"
                    style={{ height: "90px" }}
                  >
                    <b>{getData("Dragon Even").back1}</b>
                    <div className="m-t-10">
                      <button
                        onClick={() => setEvent("Dragon Even")}
                        className={`text-uppercase btn-theme low-high-btn ${
                          isLocked ? "suspended" : ""
                        }`}
                      >
                        <b>Even</b>
                      </button>{" "}
                      <span className="d-block m-t-5">
                        {getExposureForEntity("Dragon Even")?.exposure}
                      </span>
                    </div>
                  </div>
                  <div
                    className="col-md-6 text-center"
                    style={{ height: "90px" }}
                  >
                    <b>{getData("Dragon Odd").back1}</b>
                    <div className="m-t-10">
                      <button
                        className={`text-uppercase btn-theme low-high-btn ${
                          isLocked ? "suspended" : ""
                        }`}
                        onClick={() => setEvent("Dragon Odd")}
                      >
                        <b>Odd</b>
                      </button>{" "}
                      <span className="d-block m-t-5">
                        {getExposureForEntity("Dragon Odd")?.exposure}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12 m-t-10 text-right">
                    <span className="m-r-10">
                      <b>Min:</b>
                      <span>100</span>
                    </span>{" "}
                    <span>
                      <b>Max: </b>
                      <span>25000</span>
                    </span>
                  </div>
                  <div
                    className="col-md-6 text-center m-t-10 flex-wrap"
                    style={{ height: "90px" }}
                  >
                    <b>{getData("Dragon Red").back1}</b>
                    <div className="m-t-10">
                      <button
                        onClick={() => setEvent("Dragon Red")}
                        className={`text-uppercase btn-theme low-high-btn ${
                          isLocked ? "suspended" : ""
                        }`}
                      >
                        <div className="color-card"></div>
                        <span className="card-icons">
                          <span className="card-red">
                            {" "}
                            <img src={ASSETS_URL + "heart.png"} />
                          </span>
                        </span>{" "}
                        <span className="card-icons">
                          <span className="card-red">
                            {" "}
                            <img src={ASSETS_URL + "diamond.png"} />
                          </span>
                        </span>
                      </button>
                      <span className="d-block m-t-5">
                        {getExposureForEntity("Dragon Red")?.exposure}
                      </span>
                    </div>
                  </div>
                  <div
                    className="col-md-6 text-center m-t-10 flex-wrap"
                    style={{ height: "90px" }}
                  >
                    <b>{getData("Dragon Black").back1}</b>
                    <div className="m-t-10">
                      <button
                        onClick={() => setEvent("Dragon Black")}
                        className={`text-uppercase btn-theme low-high-btn ${
                          isLocked ? "suspended" : ""
                        }`}
                      >
                        <div className="color-card"></div>
                        <span className="card-icons">
                          <span className="card-black">
                            {" "}
                            <img src={ASSETS_URL + "spade.png"} />
                          </span>
                        </span>{" "}
                        <span className="card-icons">
                          <span className="card-black">
                            {" "}
                            <img src={ASSETS_URL + "club.png"} />
                          </span>
                        </span>
                      </button>
                      <span className="d-block m-t-5">
                        {getExposureForEntity("Dragon Black")?.exposure}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12 m-t-10 text-right">
                    <span className="m-r-10">
                      <b>Min:</b>
                      <span>100</span>
                    </span>{" "}
                    <span>
                      <b>Max: </b>
                      <span>25000</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-t-box m-b-10">
                <div className="row ">
                  <div className="col-12">
                    <h4 className="m-b-0 text-center text-uppercase">Tiger</h4>
                  </div>
                  <div
                    className="col-md-6 text-center"
                    style={{ height: "90px" }}
                  >
                    <b>{getData("Tiger Even").back1}</b>
                    <div className="m-t-10">
                      <button
                        onClick={() => setEvent("Tiger Even")}
                        className={`text-uppercase btn-theme low-high-btn ${
                          isLocked ? "suspended" : ""
                        }`}
                      >
                        <b>Even</b>
                      </button>{" "}
                      <span className="d-block m-t-5">
                        {getExposureForEntity("Tiger Even")?.exposure}
                      </span>
                    </div>
                  </div>
                  <div
                    className="col-md-6 text-center"
                    style={{ height: "90px" }}
                  >
                    <b>{getData("Tiger Odd").back1}</b>
                    <div className="m-t-10">
                      <button
                        onClick={() => setEvent("Tiger Odd")}
                        className={`text-uppercase btn-theme low-high-btn ${
                          isLocked ? "suspended" : ""
                        }`}
                      >
                        <b>Odd</b>
                      </button>{" "}
                      <span className="d-block m-t-5">
                        {getExposureForEntity("Tiger Odd")?.exposure}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12 m-t-10 text-right">
                    <span className="m-r-10">
                      <b>Min:</b>
                      <span>100</span>
                    </span>{" "}
                    <span>
                      <b>Max: </b>
                      <span>25000</span>
                    </span>
                  </div>
                  <div
                    className="col-md-6 text-center m-t-10"
                    style={{ height: "90px" }}
                  >
                    <b>{getData("Tiger Red").back1}</b>
                    <div className="m-t-10">
                      <button
                        onClick={() => setEvent("Tiger Red")}
                        className={`text-uppercase btn-theme low-high-btn ${
                          isLocked ? "suspended" : ""
                        }`}
                      >
                        <div className="color-card"></div>
                        <span className="card-icons">
                          <span className="card-red">
                            {" "}
                            <img src={ASSETS_URL + "heart.png"} />
                          </span>
                        </span>{" "}
                        <span className="card-icons">
                          <span className="card-red">
                            {" "}
                            <img src={ASSETS_URL + "diamond.png"} />
                          </span>
                        </span>
                      </button>
                      <span className="d-block m-t-5">
                        {getExposureForEntity("Tiger Red")?.exposure}
                      </span>
                    </div>
                  </div>
                  <div
                    className="col-md-6 text-center m-t-10"
                    style={{ height: "90px" }}
                  >
                    <b>{getData("Tiger Black").back1}</b>
                    <div className="m-t-10">
                      <button
                        onClick={() => setEvent("Tiger Black")}
                        className={`text-uppercase btn-theme low-high-btn ${
                          isLocked ? "suspended" : ""
                        }`}
                      >
                        <div className="color-card"></div>
                        <span className="card-icons">
                          <span className="card-black">
                            {" "}
                            <img src={ASSETS_URL + "spade.png"} />
                          </span>
                        </span>{" "}
                        <span className="card-icons">
                          <span className="card-black">
                            {" "}
                            <img src={ASSETS_URL + "club.png"} />
                          </span>
                        </span>
                      </button>
                      <span className="d-block m-t-5">
                        {getExposureForEntity("Tiger Black")?.exposure}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12 m-t-10 text-right">
                    <span className="m-r-10">
                      <b>Min:</b>
                      <span>100</span>
                    </span>{" "}
                    <span>
                      <b>Max: </b>
                      <span>25000</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row row5">
            <div className="col-md-6">
              <div className="d-t-box m-b-10">
                <div className="row ">
                  <div className="col-md-12">
                    <h4 className="m-b-0 text-center text-uppercase">
                      Dragon {getData("Dragon Card 1").back1}
                    </h4>
                  </div>
                  <div className="card-dt col-md-12 text-center m-t-10 d-flex flex-wrap justify-content-center">
                    {dragonCard.map(({ id, img }, i) => (
                      <>
                        <div
                          onClick={() => setEvent(id)}
                          className=" m-r-5 card-image d-inline-block"
                        >
                          <div className={`${isLocked ? "suspended" : ""}`}>
                            <img src={ASSETS_URL + img} />
                          </div>
                          <div className="ubook text-center m-t-5">
                            <b>
                              {" "}
                              {getExposureForEntity(id)?.selectionName === id &&
                                getExposureForEntity(id)?.exposure}
                            </b>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                  <div className="col-md-12 m-t-10 text-right">
                    <span className="m-r-10">
                      <b>Min:</b>
                      <span>100</span>
                    </span>{" "}
                    <span>
                      <b>Max: </b>
                      <span>25000</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-t-box m-b-10">
                <div className="row">
                  <div className="col-md-12">
                    <h4 className="m-b-0 text-center text-uppercase">
                      Tiger {getData("Tiger Card 1").back1}
                    </h4>
                  </div>
                  <div className="card-dt col-md-12 text-center m-t-10 d-flex flex-wrap justify-content-center">
                    {tigerCard.map(({ id, img }, i) => (
                      <>
                        <div
                          onClick={() => setEvent(id)}
                          className=" m-r-5 card-image d-inline-block"
                        >
                          <div className={`${isLocked ? "suspended" : ""}`}>
                            <img src={ASSETS_URL + img} />
                          </div>
                          <div className="ubook text-center m-t-5">
                            <b>
                              {" "}
                              {getExposureForEntity(id)?.selectionName === id &&
                                getExposureForEntity(id)?.exposure}
                            </b>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                  <div className="col-md-12 m-t-10 text-right">
                    <span className="m-r-10">
                      <b>Min:</b>
                      <span>100</span>
                    </span>{" "}
                    <span>
                      <b>Max: </b>
                      <span>25000</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <LastResults eventId={gameName} />
      </Box>
      <PlaceBet game="casino" eventId={gameName} />
    </Box>
  );
}
