import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  WrapItem,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaLock } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Flip, Flipr } from "../../../components/Flip";
import { placeCasinoBetAPI } from "../../../service/casino";
import PlaceBet from "../../game/PlaceBet";
import "./css/cm.css";
import CardDrawer from "../../../components/CardDrawer/CardDrawer";
import { ASSETS_URL } from "../../../utils/constants";
import { useMemo } from "react";
import Iframe from "react-iframe";
import IframeComponent from "../../../components/Iframe";
import CasinoVideoRules from "../../../components/CasinoVideoRules";
import LastResults from "../../../components/LastResults";
import { setGameKey } from "../../../redux/features/gameSlice";
import "../games/css/cricket2020.css"

export default function Cricket2020() {
  const odds_ = useSelector((state) => state.gameData.cmatch20Odds);
  //useMemo
  const odds = useMemo(() => odds_, [odds_]);
  const selectedEventForBet = useSelector(
    (state) => state.gameData.selectedEventForBet
  );
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [isLocked, setIsLocked] = useState(false);
  const [plExposure, setPlExposure] = useState(null);
  const userData = useSelector((state) => state.account.userData);
  const toast = useToast();
  const dispatch = useDispatch();
  const gameName = "CRICKET_MATCH_20";
  const CustomButton = ({ text }) => (
    <Button size="sm" my="2" className="casinoButton">
      {isLocked ? <FaLock /> : text}
    </Button>
  );
  const getData = (id) => {
    let data = odds?.oddDetailsDTOS?.find((item) => item.runnerName == id);
    return data ? data : {};
  };

  const getProfit = (selectedEventForBet) => {
    return (selectedEventForBet.back1 - 1) * selectedAmount;
  };
  const setEvent = (id, isBack = true) => {
    let selectedEventForBet = getData(id);

    let payload = {};
    payload.amount = selectedAmount;
    payload.casinoGames = gameName;
    payload.profit = getProfit(
      isBack ? selectedEventForBet.back1 : selectedEventForBet.lay1
    )
      ? getProfit(isBack ? selectedEventForBet.back1 : selectedEventForBet.lay1)
      : 0;
    payload.loss = parseInt(selectedAmount);
    payload.selectedOdd = isBack
      ? selectedEventForBet.back1
      : selectedEventForBet.lay1;
    payload.selectedId = selectedEventForBet.selectionId;
    payload.marketId = odds.marketId;
    payload.runnerName = selectedEventForBet.runnerName;
    payload.type = isBack ? "back" : "lay";

    dispatch({
      type: "gameData/setSelectedEventForBet",
      payload,
    });
  };

  const getExposureForEntity = (selectionName, selectionId) => {
    if (plExposure) {
      let data = plExposure?.find((item) =>
        item?.casinoPLExposure?.find(
          (exposure) => exposure.selectionName == selectionName
        )
      );
      return data ? data : null;
    }
  };
  
  useEffect(() => {
    if (odds.autoTime <= 5) {
      setIsLocked(true);
    } else {
      setIsLocked(false);
    }
  }, [odds, getData]);

  useEffect(() => {
    dispatch(setGameKey("cmatch20"));
  }, [dispatch]);

  return (
    <Box
      display="flex"
      flexDirection={{ base: "column", sm: "column", lg: "row" }}
    >
      <Box mt="1" mr="1" w={{ base: "100%", sm: "100%", lg: "70%" }}>
        <Box
          h="60vh"
          overflow="hidden"
          mb="2"
          pos="relative"
          className="casino-video"
        >
          {odds.cards && (
            <CardDrawer
              labels={"DEALER"}
              multiLevel={true}
              cards={[odds?.cards[0][0]]}
            />
          )}
          <div className="market-title">Cricket Match 20-20</div>

          <IframeComponent
            url={`${process.env.REACT_APP_IFRAME_URL}?id=3045`}
          />
          <Box
            bottom="0"
            p="2"
            pos="absolute"
            right="2"
            display="flex"
            bg="black"
          >
            <Flip value={odds?.autoTime} />
          </Box>
          <CasinoVideoRules
            custom={
              <div className="rules-body">
                <img
                  src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/front/img/rules/cmatch20-rules.jpg"
                  className="rules-body"
                />
              </div>
            }
          />
        </Box>

        {/* <div className="row row5">
        

          <div className="col-6 full-mobile">
            {[2, 3, 4, 5, 6].map((item) => (
              <div class={`score-box btn-theme ${isLocked ? "suspended" : ""}`}>
                <img
                  src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/balls/ball2.png"
                  className="img-fluid ball-image"
                />{" "}
                <img
                  src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/balls/score-bat-icon.png"
                  className="score-img"
                />
                <div className="team-score">
                  <div>
                    <span>Team A</span>{" "}
                    <span className="ml-1">
                      {" "}
                      {odds?.cards?.length > 1 && (
                        <div className="text-center">
                          <span className="ml-1">
                            {odds.cards[1][0]}/{odds.cards[1][1]}
                          </span>
                          <span className="ml-1">{odds.cards[1][2]} Overs</span>
                        </div>
                      )}
                    </span>
                  </div>
                  <div>
                    <span>Team B</span>{" "}
                    <span className="ml-1">
                      {" "}
                      {odds?.cards?.length > 1 && (
                        <div className="text-center">
                          <span className="ml-1">
                            {odds.cards[2][0]}/{odds.cards[2][1]}
                          </span>
                          <span className="ml-1">{odds.cards[2][2]} Overs</span>
                        </div>
                      )}
                    </span>
                  </div>
                </div>
                <div className="min-max">
                  <span>
                    Min:<span>100</span>
                    Max:<span>300K</span>
                  </span>
                </div>
                <div className="back backbox">
                  <span className="odds d-block">0.00</span>
                </div>
                <div className="lay laybox">
                  <span className="odds d-block">0.00</span>
                </div>
              </div>
            ))}
          </div>
          <div className="col-6 full-mobile">
            {[7, 8, 9, 10].map((item) => (
              <div class={`score-box btn-theme ${isLocked ? "suspended" : ""}`}>
                <img
                  src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/balls/ball2.png"
                  className="img-fluid ball-image"
                />{" "}
                <img
                  src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/balls/score-bat-icon.png"
                  className="score-img"
                />
                <div className="team-score">
                  <div>
                    <span>Team A</span>{" "}
                    <span className="ml-1">
                      {" "}
                      {odds?.cards?.length > 1 && (
                        <div className="text-center">
                          <span className="ml-1">
                            {odds.cards[1][0]}/{odds.cards[1][1]}
                          </span>
                          <span className="ml-1">{odds.cards[1][2]} Overs</span>
                        </div>
                      )}
                    </span>
                  </div>
                  <div>
                    <span>Team B</span>{" "}
                    <span className="ml-1">
                      {" "}
                      {odds?.cards?.length > 1 && (
                        <div className="text-center">
                          <span className="ml-1">
                            {odds.cards[2][0]}/{odds.cards[2][1]}
                          </span>
                          <span className="ml-1">{odds.cards[2][2]} Overs</span>
                        </div>
                      )}
                    </span>
                  </div>
                </div>
                <div className="min-max">
                  <span>
                    Min:<span>100</span>
                    Max:<span>300K</span>
                  </span>
                </div>
                <div className="back backbox">
                  <span className="odds d-block">0.00</span>
                </div>
                <div className="lay laybox">
                  <span className="odds d-block">0.00</span>
                </div>
              </div>
            ))}
          </div>

          <div className="casino-remark snipcss-iPU8f">
            <div className="remark-icon">
              <img
                src="https://sitethemedata.com/v128/static/front/img/icons/remark.png"
                alt="Remark"
              />
            </div>
            <marquee>
              Team B Need 12 Runs to WIN Match. If The Score is Tie Team B will
              WIN.
            </marquee>
          </div>
        </div> */}

<div className="teen20casino-container">
          <div
            className="teen20left"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div className="score-box-card">
              <div className="team-score-card">
                <div>
                  <div className="">
                    <b>Team A</b>
                  </div>
                  <span>
                    {odds?.cards?.length > 1 && (
                      <div className="">
                        <span>
                          {odds.cards[1][0]}/{odds.cards[1][1]}
                        </span>
                        <span>{odds.cards[1][2]} Overs</span>
                      </div>
                    )}
                  </span>
                </div>
                <div>
                  <div className="">
                    <b>Team B</b>
                  </div>
                  <span>
                    {odds?.cards?.length > 1 && (
                      <div className="">
                        <span>
                          {odds.cards[1][0]}/{odds.cards[1][1]}
                        </span>
                        <span>{odds.cards[1][2]} Overs</span>
                      </div>
                    )}
                  </span>
                </div>
              </div>
              <div className="ball-icon">
                <img src="https://wver.sprintstaticdata.com/v30/static/front/img/balls/ball2.png" />
              </div>
              <div className={`blbox ${isLocked ? "suspended" : ""}`}>
                <div className="back" onClick={() => setEvent("Run 2")}>
                  <span className="odds d-block">{getData("Run 2").back1}</span>
                  <br />
                  {getExposureForEntity("Run 2")?.exposure}
                </div>
                <div className="lay" onClick={() => setEvent("Run 2", false)}>
                  <span className="odds d-block">{getData("Run 2").lay1}</span>
                  <br />
                  {getExposureForEntity("Run 2")?.exposure}
                </div>
              </div>
            </div>
            <div className="score-box-card">
              <div className="team-score-card">
                <div>
                  <div className="">
                    <b>Team A</b>
                  </div>
                  <span>
                    {odds?.cards?.length > 1 && (
                      <div className="">
                        <span>
                          {odds.cards[1][0]}/{odds.cards[1][1]}
                        </span>
                        <span>{odds.cards[1][2]} Overs</span>
                      </div>
                    )}
                  </span>
                </div>
                <div>
                  <div className="">
                    <b>Team B</b>
                  </div>
                  <span>
                    {odds?.cards?.length > 1 && (
                      <div className="">
                        <span>
                          {odds.cards[1][0]}/{odds.cards[1][1]}
                        </span>
                        <span>{odds.cards[1][2]} Overs</span>
                      </div>
                    )}
                  </span>
                </div>
              </div>
              <div className="ball-icon">
                <img src="https://wver.sprintstaticdata.com/v30/static/front/img/balls/ball3.png" />
              </div>
              <div className={`blbox ${isLocked ? "suspended" : ""}`}>
                <div className="back" onClick={() => setEvent("Run 3")}>
                  <span className="odds d-block">{getData("Run 3").back1}</span>
                  <br />
                  {getExposureForEntity("Run 3")?.exposure}
                </div>
                <div className="lay" onClick={() => setEvent("Run 3", false)}>
                  <span className="odds d-block">{getData("Run 3").lay1}</span>
                  <br />
                  {getExposureForEntity("Run 3")?.exposure}
                </div>
              </div>
            </div>
            <div className="score-box-card">
              <div className="team-score-card">
                <div>
                  <div className="">
                    <b>Team A</b>
                  </div>
                  <span>
                    {odds?.cards?.length > 1 && (
                      <div className="">
                        <span>
                          {odds.cards[1][0]}/{odds.cards[1][1]}
                        </span>
                        <span>{odds.cards[1][2]} Overs</span>
                      </div>
                    )}
                  </span>
                </div>
                <div>
                  <div className="">
                    <b>Team B</b>
                  </div>
                  <span>
                    {odds?.cards?.length > 1 && (
                      <div className="">
                        <span>
                          {odds.cards[1][0]}/{odds.cards[1][1]}
                        </span>
                        <span>{odds.cards[1][2]} Overs</span>
                      </div>
                    )}
                  </span>
                </div>
              </div>
              <div className="ball-icon">
                <img src="https://wver.sprintstaticdata.com/v30/static/front/img/balls/ball4.png" />
              </div>
              <div className={`blbox ${isLocked ? "suspended" : ""}`}>
                <div className="back" onClick={() => setEvent("Run 4")}>
                  <span className="odds d-block">{getData("Run 4").back1}</span>
                  <br />
                  {getExposureForEntity("Run 4")?.exposure}
                </div>
                <div className="lay" onClick={() => setEvent("Run 4", false)}>
                  <span className="odds d-block">{getData("Run 4").lay1}</span>
                  <br />
                  {getExposureForEntity("Run 4")?.exposure}
                </div>
              </div>
            </div>
            <div className="score-box-card">
              <div className="team-score-card">
                <div>
                  <div className="">
                    <b>Team A</b>
                  </div>
                  <span>
                    {odds?.cards?.length > 1 && (
                      <div className="">
                        <span>
                          {odds.cards[1][0]}/{odds.cards[1][1]}
                        </span>
                        <span>{odds.cards[1][2]} Overs</span>
                      </div>
                    )}
                  </span>
                </div>
                <div>
                  <div className="">
                    <b>Team B</b>
                  </div>
                  <span>
                    {odds?.cards?.length > 1 && (
                      <div className="">
                        <span>
                          {odds.cards[1][0]}/{odds.cards[1][1]}
                        </span>
                        <span>{odds.cards[1][2]} Overs</span>
                      </div>
                    )}
                  </span>
                </div>
              </div>
              <div className="ball-icon">
                <img src="https://wver.sprintstaticdata.com/v30/static/front/img/balls/ball5.png" />
              </div>
              <div className={`blbox ${isLocked ? "suspended" : ""}`}>
                <div className="back" onClick={() => setEvent("Run 5")}>
                  <span className="odds d-block">{getData("Run 5").back1}</span>
                  <br />
                  {getExposureForEntity("Run 5")?.exposure}
                </div>
                <div className="lay" onClick={() => setEvent("Run 5", false)}>
                  <span className="odds d-block">{getData("Run 5").lay1}</span>
                  <br />
                  {getExposureForEntity("Run 5")?.exposure}
                </div>
              </div>
            </div>
            <div className="score-box-card">
              <div className="team-score-card">
                <div>
                  <div className="">
                    <b>Team A</b>
                  </div>
                  <span>
                    {odds?.cards?.length > 1 && (
                      <div className="">
                        <span>
                          {odds.cards[1][0]}/{odds.cards[1][1]}
                        </span>
                        <span>{odds.cards[1][2]} Overs</span>
                      </div>
                    )}
                  </span>
                </div>
                <div>
                  <div className="">
                    <b>Team B</b>
                  </div>
                  <span>
                    {odds?.cards?.length > 1 && (
                      <div className="">
                        <span>
                          {odds.cards[1][0]}/{odds.cards[1][1]}
                        </span>
                        <span>{odds.cards[1][2]} Overs</span>
                      </div>
                    )}
                  </span>
                </div>
              </div>
              <div className="ball-icon">
                <img src="https://wver.sprintstaticdata.com/v30/static/front/img/balls/ball6.png" />
              </div>
              <div className={`blbox ${isLocked ? "suspended" : ""}`}>
                <div className="back" onClick={() => setEvent("Run 6")}>
                  <span className="odds d-block">{getData("Run 6").back1}</span>
                  <br />
                  {getExposureForEntity("Run 6")?.exposure}
                </div>
                <div className="lay" onClick={() => setEvent("Run 6", false)}>
                  <span className="odds d-block">{getData("Run 6").lay1}</span>
                  <br />
                  {getExposureForEntity("Run 6")?.exposure}
                </div>
              </div>
            </div>
          </div>

          <div
            className="teen20right"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div className="score-box-card">
              <div className="team-score-card">
                <div>
                  <div className="">
                    <b>Team A</b>
                  </div>
                  <span>
                    {odds?.cards?.length > 1 && (
                      <div className="">
                        <span>
                          {odds.cards[1][0]}/{odds.cards[1][1]}
                        </span>
                        <span>{odds.cards[1][2]} Overs</span>
                      </div>
                    )}
                  </span>
                </div>
                <div>
                  <div className="">
                    <b>Team B</b>
                  </div>
                  <span>
                    {odds?.cards?.length > 1 && (
                      <div className="">
                        <span>
                          {odds.cards[1][0]}/{odds.cards[1][1]}
                        </span>
                        <span>{odds.cards[1][2]} Overs</span>
                      </div>
                    )}
                  </span>
                </div>
              </div>
              <div className="ball-icon">
                <img src="https://wver.sprintstaticdata.com/v30/static/front/img/balls/ball7.png" />
              </div>
              <div className={`blbox ${isLocked ? "suspended" : ""}`}>
                <div className="back" onClick={() => setEvent("Run 7")}>
                  <span className="odds d-block">{getData("Run 7").back1}</span>
                  <br />
                  {getExposureForEntity("Run 7")?.exposure}
                </div>
                <div className="lay" onClick={() => setEvent("Run 7", false)}>
                  <span className="odds d-block">{getData("Run 7").lay1}</span>
                  <br />
                  {getExposureForEntity("Run 7")?.exposure}
                </div>
              </div>
            </div>
            <div className="score-box-card">
              <div className="team-score-card">
                <div>
                  <div className="">
                    <b>Team A</b>
                  </div>
                  <span>
                    {odds?.cards?.length > 1 && (
                      <div className="">
                        <span>
                          {odds.cards[1][0]}/{odds.cards[1][1]}
                        </span>
                        <span>{odds.cards[1][2]} Overs</span>
                      </div>
                    )}
                  </span>
                </div>
                <div>
                  <div className="">
                    <b>Team B</b>
                  </div>
                  <span>
                    {odds?.cards?.length > 1 && (
                      <div className="">
                        <span>
                          {odds.cards[1][0]}/{odds.cards[1][1]}
                        </span>
                        <span>{odds.cards[1][2]} Overs</span>
                      </div>
                    )}
                  </span>
                </div>
              </div>
              <div className="ball-icon">
                <img src="https://wver.sprintstaticdata.com/v30/static/front/img/balls/ball8.png" />
              </div>
              <div className={`blbox ${isLocked ? "suspended" : ""}`}>
                <div className="back" onClick={() => setEvent("Run 8")}>
                  <span className="odds d-block">{getData("Run 8").back1}</span>
                  <br />
                  {getExposureForEntity("Run 8")?.exposure}
                </div>
                <div className="lay" onClick={() => setEvent("Run 8", false)}>
                  <span className="odds d-block">{getData("Run 8").lay1}</span>
                  <br />
                  {getExposureForEntity("Run 8")?.exposure}
                </div>
              </div>
            </div>
            <div className="score-box-card">
              <div className="team-score-card">
                <div>
                  <div className="">
                    <b>Team A</b>
                  </div>
                  <span>
                    {odds?.cards?.length > 1 && (
                      <div className="">
                        <span>
                          {odds.cards[1][0]}/{odds.cards[1][1]}
                        </span>
                        <span>{odds.cards[1][2]} Overs</span>
                      </div>
                    )}
                  </span>
                </div>
                <div>
                  <div className="">
                    <b>Team B</b>
                  </div>
                  <span>
                    {odds?.cards?.length > 1 && (
                      <div className="">
                        <span>
                          {odds.cards[1][0]}/{odds.cards[1][1]}
                        </span>
                        <span>{odds.cards[1][2]} Overs</span>
                      </div>
                    )}
                  </span>
                </div>
              </div>
              <div className="ball-icon">
                <img src="https://wver.sprintstaticdata.com/v30/static/front/img/balls/ball9.png" />
              </div>
              <div className={`blbox ${isLocked ? "suspended" : ""}`}>
                <div className="back" onClick={() => setEvent("Run 9")}>
                  <span className="odds d-block">{getData("Run 9").back1}</span>
                  <br />
                  {getExposureForEntity("Run 9")?.exposure}
                </div>
                <div className="lay" onClick={() => setEvent("Run 9", false)}>
                  <span className="odds d-block">{getData("Run 9").lay1}</span>
                  <br />
                  {getExposureForEntity("Run 9")?.exposure}
                </div>
              </div>
            </div>
            <div className="score-box-card">
              <div className="team-score-card">
                <div>
                  <div className="">
                    <b>Team A</b>
                  </div>
                  <span>
                    {odds?.cards?.length > 1 && (
                      <div className="">
                        <span>
                          {odds.cards[1][0]}/{odds.cards[1][1]}
                        </span>
                        <span>{odds.cards[1][2]} Overs</span>
                      </div>
                    )}
                  </span>
                </div>
                <div>
                  <div className="">
                    <b>Team B</b>
                  </div>
                  <span>
                    {odds?.cards?.length > 1 && (
                      <div className="">
                        <span>
                          {odds.cards[1][0]}/{odds.cards[1][1]}
                        </span>
                        <span>{odds.cards[1][2]} Overs</span>
                      </div>
                    )}
                  </span>
                </div>
              </div>
              <div className="ball-icon">
                <img src="https://wver.sprintstaticdata.com/v30/static/front/img/balls/ball10.png" />
              </div>
              <div className={`blbox ${isLocked ? "suspended" : ""}`}>
                <div className="back" onClick={() => setEvent("Run 10")}>
                  <span className="odds d-block">
                    {getData("Run 10").back1}
                  </span>
                  <br />
                  {getExposureForEntity("Run 10")?.exposure}
                </div>
                <div className="lay" onClick={() => setEvent("Run 10", false)}>
                  <span className="odds d-block">{getData("Run 10").lay1}</span>
                  <br />
                  {getExposureForEntity("Run 10")?.exposure}
                </div>
              </div>
            </div>
            <div className="casino-remark">
              <div className="remark-icon">
                <img src="https://wver.sprintstaticdata.com/v30/static/front/img/icons/remark.png" />
              </div>
              <marquee>
                Team B Need 12 Runs to WIN Match. If The Score is Tie Team B
                will WIN.
              </marquee>
            </div>
          </div>
        </div>

        <LastResults eventId={gameName} />
      </Box>

      <PlaceBet game="casino" eventId={gameName} isImage={true} />
    </Box>
  );
}
