import {
  Box,
  Button,
  Flex,
  Input,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import CustomTable from "../../components/Table";
import { unSetteledBetsAPI } from "../../service/AuthService";
import { getCurrentDateTimeLocal } from "../../utils/constants";

export default function UnSetteledBet() {
  const [from, setFrom] = React.useState(getCurrentDateTimeLocal(true));
  const [to, setTo] = React.useState(getCurrentDateTimeLocal());
  const [selectedCategory, setSelectedCategory] = React.useState("MATCHED");
  const [page, setPage] = React.useState(0);
  const [size, setSize] = React.useState(10);
  const [data, setData] = React.useState([]);
  const getUnSetteledBets = async () => {
    let payload = {
      pageNumber: page,
      pageSize: size,
      search: "",
      betStatus: selectedCategory,
    };
    const {
      response: { content },
    } = await unSetteledBetsAPI(payload);
    let data_ = content.map((item, index) => ({
      ...item,
      no: index + 1,
      side: item.back_rate || item.back_run ? "Back" : "Lay",
      rate: item.back_run ? item.back_run : item.lay_run,
    }));
    setData(data_);
  };
  useEffect(() => {
    getUnSetteledBets();
  }, [selectedCategory]);
  return (
    <Box
      borderRadius="4"
      border="1px solid lightgray"
      className="setting-page-main unsetteled-main"
    >
      <Box
        p="2"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        w="100%"
        fontWeight="bold"
        color="white"
        background="#ae4600bd"
        padding={2}
        marginTop="-5px"
      >
        <Text fontSize="xl" color="#fff !important">
          Un-Setteled Bet
        </Text>
      </Box>
      <Box mt="5" marginLeft={'-90px'} display="flex" p="2" w="40%" justifyContent={'center'}>
        <RadioGroup onChange={(value) => setSelectedCategory(value)}>
          <Stack direction="row">
            <Box></Box>
            <Radio value="MATCHED">Matched</Radio>
            <Radio value="UNMATCHED">Un-Matched</Radio>
            <Radio value="DELETED">Deleted</Radio>
          </Stack>
        </RadioGroup>
      </Box>
      <Box p="2">
        <CustomTable
          currentPage_={page}
          pageSize_={size}
          total_={data.length}
          showPagination={true}
          rowsPerPage_={[10, 20, 30, 40, 50]}
          columns={[
            {
              name: "No",
              id: "no",
            },
            {
              name: "Event Name",
              id: "event_name",
            },
            {
              name: "Nation",
              id: "runner_name",
            },
            {
              name: "Event Type",
              id: "event_name",
            },
            {
              name: "Market Name",
              id: "marketName",
            },
            {
              name: "Side",
              id: "side",
            },
            {
              name: "Rate",
              id: "rate",
            },
            {
              name: "Amount",
              id: "amount",
            },
            {
              name: "Place Date",
              id: "created_date",
            },
            {
              name: "Match Date",
              id: "created_date",
            },
          ]}
          rows={data}
        />
      </Box>
    </Box>
  );
}
