import { Box } from "@chakra-ui/react";
import React from "react";
import SimpleImageSlider from "react-simple-image-slider";
const ImageSlider = ({
  width,
  height,
  images,
  isMobile,
  showNavs = true,
  showBullets = true,
}) => {
  return (
    <Box pos="relative" w="100%" id={`${isMobile ? "main_wrap" : ""}`}>
      <SimpleImageSlider
        width={width}
        height={height || 213}
        images={images}
        showBullets={showBullets}
        showNavs={showNavs}
        autoPlay={true}
      />
    </Box>
  );
};

export default ImageSlider;
