import React, { useCallback, useEffect, useState } from "react";
import { Button, Stack } from "@mui/material";
import { SearchWithText } from "../shared/SearchWithText";
import CustomTab from "../shared/Tabs";
import { GenericTable } from "../shared/GenericTable";
import RadioGroups from "../shared/RadioGroups";
import "../../assets/styles/css/current.scss";
import { betHistoryAPI } from "../../service/AuthService";
import BasicDatePicker from "../shared/DatePicker";
import moment from "moment";
import { useSelector } from "react-redux";
import { SelectType } from "../shared/SelectType";
import { sportsOptions } from "../shared/SelectType/index.utils";

export const BetsHistory = () => {
  const userData = useSelector((state) => state.account.userData);
  const [currentBetsData, setCurrentBetsData] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [fromDate, setFromDate] = useState(
    moment().subtract(7, "days").toDate()
  );
  const [toDate, setToDate] = useState(moment().toDate());
  const [betStatus, setBetStatus] = useState("MATCHED");
  const [type, setType] = useState("CRICKET");
  const [selectedTab, setSelectedTab] = useState("Sports");

  const handleFromDateChange = (date, isFromDate) => {
    const _from = new Date(date);
    if (isFromDate) {
      _from.setUTCHours(23, 59, 59, 999);
      setFromDate(_from);
    } else {
      _from.setUTCHours(0, 0, 0, 0);
      setToDate(_from);
    }
  };

  const handleToDateChange = (date, isFromDate) => {
    const _to = new Date(date);
    if (isFromDate) {
      _to.setUTCHours(0, 0, 0, 0);
      setFromDate(_to);
    } else {
      _to.setUTCHours(23, 59, 59, 999);
      setToDate(_to);
    }
  };

  const fetchCurrentBetsData = useCallback(async () => {
    const payload = {
      from: moment(fromDate).startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS"),
      matched: betStatus === "MATCHED",
      page: 0,
      size: 10,
      sport: type,
      to: moment(toDate).endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS"),
      userName: userData.username,
    };
    if (selectedTab === "Casino") {
      payload.sport = "CASINO";
    }
    try {
      const response = await betHistoryAPI(payload);
      console.log(response, "response");
      setCurrentBetsData(response.data.response.content || []);
      setFilteredTableData(response.data.response.content || []);
    } catch (error) {
      console.error("Error fetching current bets data:", error);
    }
  }, [betStatus, userData.username, selectedTab, fromDate, toDate, type]);

  useEffect(() => {
    fetchCurrentBetsData();
  }, [fetchCurrentBetsData]);

  const currentBetsTableHeader = [
    "No",
    "Event Name",
    "Nation",
    "User Rate",
    "Amount",
    "Profit/Loss",
    "Place Date",
    "Match Date",
  ];

  const currentBetsTableRow = filteredTableData.map((data, index) => ({
    No: index + 1,
    "Event Name": data.event_name || "",
    Nation: data.runner_name || "",
    "Bet Type": data.bet_type || "",
    "User Rate": data.lay_rate || "",
    Amount: data.amount || "",
    "Profit/Loss": data.profit || data.loss,
    back_run: data.back_run,
    lay_run: data.lay_run,
    back: data.back_run ? true : false,
    lay: data.lay_run ? true : false,
    "Place Date": new Date(data.created_date).toLocaleString("en-GB", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric" || "",
    }),
    "Match Date": new Date(data.created_date).toLocaleString("en-GB", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric" || "",
    }),
  }));

  const handleSearch = (searchTerm) => {
    const formattedSearchTerm = searchTerm.toLowerCase();

    const filteredData = currentBetsData.filter((data) => {
      const srNoMatch = String(data.No)
        .toLowerCase()
        .includes(formattedSearchTerm);
      const event_typeMatch = String(data.event_type)
        .toLowerCase()
        .includes(formattedSearchTerm);
      const event_name = String(data.event_name)
        .toLowerCase()
        .includes(formattedSearchTerm);
      const bet_type = String(data.runner_name)
        .toLowerCase()
        .includes(formattedSearchTerm);
      const profit_loss = String(data.profit_loss)
        .toLowerCase()
        .includes(formattedSearchTerm);
      const lay_rate = String(data.lay_rate)
        .toLowerCase()
        .includes(formattedSearchTerm);
      const amount = String(data.amount)
        .toLowerCase()
        .includes(formattedSearchTerm);
      const created_date = String(data.created_date)
        .toLowerCase()
        .includes(formattedSearchTerm);
      const match_date = String(data.match_date)
        .toLowerCase()
        .includes(formattedSearchTerm);

      return (
        srNoMatch ||
        event_typeMatch ||
        event_name ||
        bet_type ||
        profit_loss ||
        lay_rate ||
        amount ||
        created_date ||
        match_date
      );
   

    });

    setFilteredTableData(filteredData);
  };

  return (
    <React.Fragment>
      <Stack className="current-bets-main-container">
        <SearchWithText
          text={"Bets History"}
          onSearchClick={(e) => handleSearch(e.target.value)}
        />
        <CustomTab
          tab1={"Sports"}
          tab2={"Casino"}
          setSelectedTab={setSelectedTab}
        />
        <RadioGroups onChange={(e) => {}} />
      </Stack>
      <Stack className="accounts-page-main-stack">
        {/* <SearchWithText
          text={'Account Statement'}
          onSearchClick={(e) => handleSearch(e.target.value)}
        /> */}
        <Stack className="accounts-page-date-stack">
        <BasicDatePicker
            text={"From"}
            onChange={handleFromDateChange}
            isFromDate={true}
          />
          <BasicDatePicker
            text={"To"}
            onChange={handleToDateChange}
            isFromDate={false}
          />
          <Stack className="select-main-stack" direction={"row"}>
            {selectedTab === "Sports" && (
              <SelectType
                selectedType={type}
                handleTypeChange={(e) => {
                  setType(e.target.value);
                }}
                options={sportsOptions}
              />
            )}

            <Stack className="submit-button-stack" direction={"row"}>
              <Button
                variant="contained"
                onClick={fetchCurrentBetsData}
                className="submit-button"
                disableRipple
              >
                Submit
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack className="current-bets-main-container-2">
        <GenericTable
          tableHeader={currentBetsTableHeader}
          tableRow={currentBetsTableRow}
          isBetHistory={true}
        />
      </Stack>
    </React.Fragment>
  );
};
