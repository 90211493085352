import * as React from 'react';
import { useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Stack, Text, Button } from '@chakra-ui/react';
import calendarIcon from '../../../assets/images/icons/calendarIcon.svg';
import '../../../assets/styles/css/datepicker.scss';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';

const BasicDatePicker = ({ text, onChange, isFromDate }) => {
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    onChange(date, isFromDate);
  };

  const handleClearDate = () => {
    setSelectedDate(null);
    onChange(null, isFromDate);
  };

  return (
    <Stack className='date-picker-main-stack'>
      <Text className='date-picker-text'>{text}</Text>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={selectedDate} 
          onChange={(newValue) => {
            handleDateChange(new Date(newValue));
          }}
          className='date-picker-new'
          disableFuture
          slots={{
            openPickerIcon: () => (
              <img src={calendarIcon} alt='date-img' className='cal-img' />
            ),
          }}
        />
      </LocalizationProvider>
      {selectedDate && <Button onClick={handleClearDate}>Clear</Button>}
    </Stack>
  );
};

export default BasicDatePicker;

