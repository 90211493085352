import React from "react";
import "./Depositmodal.css";
import { useState } from "react";
const DepositMain = () => {
  return (
    <div
      className="edit-stakes-holder confirm-popup-login modal show"
      id="payment_pending-popup"
      tabindex="-1"
      data-backdrop="static"
      data-keyboard="false"
      data-show="true"
      style={{ display: "block", paddingRight: "15px" }}
      aria-modal="true"
    >
      <div
        className="edit-stakes-content confirm-popup-inner deposit-popup-inners"
        id="outersection"
      >
        <div className="edit-stakes-form">
          <div className="thumb-in payment-method">
            {/* <style> .change_pg_method{ color: #00808e; text-decoration: underline; } .form-deposits .error{ color: red !important; font-size: 12px !important; font-weight: 600 !important; opacity: 1 !important; }</style> */}
            <div className="row bg-white ">
              <div className="deposit_outer col-sm-12">
                <div className="" id="paymentmethod_popup" role="dialog">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                  <div className="modal-dialog-scrollable" role="document">
                    <div className="modal-content">
                      <h4 className="header-text p-2">Deposit Credits</h4>
                      <div className="modal-header select-pgchannel-row">
                        <h5 className="modal-title">
                          Select your deposit method
                        </h5>
                      </div>
                      <div className="modal-body">
                        <div className="row m-0">
                          <div className="col-md-3 p-0 col-sm-4 px-1 ">
                            <b className="text-danger bankTransferMsg">
                              2% Bonus only on Bank Transfer (IMPS/NEFT/RTGS)
                            </b>
                            <div className="mobile_deposit">
                              <div className="mt-1">
                                <a
                                  href="javascript:void(0)"
                                  className="pgmethod select_pg_method select_pg_method_banktransfer pt-1"
                                  data-payment_type="banktransfer"
                                  tabindex="65"
                                >
                                  <img
                                    src="https://hotsports.com/img/payment_method/bt.png"
                                    className="pgicon"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-9 p-0 col-sm-8 px-1 ">
                            <b className="text-danger upiTransferMsg">
                              Deposit today get 2% Bonus.
                            </b>
                          </div>
                        </div>
                        <div
                          className="row mx-1 deposit_card_slip "
                          id="paymentoptions"
                        >
                          <div className="col-md-3 col-sm-4 p-1 desk_deposit">
                            <div className="mt-1 bank_trasnfer_btn">
                              <a
                                href="javascript:void(0)"
                                className="pgmethod select_pg_method select_pg_method_banktransfer pt-1 active"
                                data-payment_type="banktransfer"
                                tabindex="66"
                              >
                                <img
                                  src="https://hotsports.com/img/payment_method/bt.png"
                                  className="pgicon"
                                />
                              </a>
                            </div>
                          </div>
                          <div className="col-md-9 col-sm-8 p-1 ">
                            <div className="row mx-1 upi_listing align-items-center">
                              <div className="col-md-2 p-0 col-sm-4 px-1 upi_box">
                                <a
                                  href="javascript:void(0)"
                                  className="pgmethod select_pg_method select_pg_method_phone_pe"
                                  data-payment_type="phone_pe"
                                  tabindex="67"
                                >
                                  <img
                                    src="https://hotsports.com/img/payment_method/phonepe.webp"
                                    className="pgicon"
                                  />
                                </a>
                              </div>

                              <div className="col-md-2 p-1 col-sm-4 px-1 upi_box">
                                <a
                                  href="javascript:void(0)"
                                  className="pgmethod select_pg_method select_pg_method_gpay"
                                  data-payment_type="gpay"
                                  tabindex="68"
                                >
                                  <img
                                    src="https://hotsports.com/img/payment_method/googlepay.webp"
                                    className="pgicon"
                                  />
                                </a>
                              </div>

                              <div className="col-md-2 p-1 col-sm-4 px-1 upi_box">
                                <a
                                  href="javascript:void(0)"
                                  className="pgmethod select_pg_method select_pg_method_paytm pt-1"
                                  data-payment_type="paytm"
                                  tabindex="69"
                                >
                                  <img
                                    src="https://hotsports.com/img/payment_method/f-paytm.png"
                                    className="pgicon"
                                  />
                                </a>
                              </div>

                              {/* upi  */}
                              <div className="col-md-2 p-1 col-sm-4 px-1 upi_box">
                                <a
                                  href="javascript:void(0)"
                                  className="pgmethod select_pg_method select_pg_method_upi pt-3"
                                  data-payment_type="upi"
                                  tabindex="70"
                                >
                                  <img
                                    src="https://hotsports.com/img/payment_method/upi.webp"
                                    className="pgicon"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          id="offline_deposit_content_banktransfer"
                          data-payment_type="banktransfer"
                          className="depo_method"
                        >
                          <form
                            id="offline_deposit_bank"
                            className="offline_deposit"
                            action=""
                            method=""
                            data-toggle="validator"
                            role="form"
                            enctype="multipart/form-data"
                            novalidate="novalidate"
                            autocomplete="off"
                          >
                            <div className="valresponse"></div>
                            <div className="method_head">Transfer Details</div>
                            <h6 className="method_subhead text-italic">
                              *Please make your payment on the details below and
                              submit transaction id
                            </h6>
                            <div className="deposit_section_in bank_trans_sec1">
                              <div className="form-deposits">
                                <input
                                  className="form-control "
                                  type="text"
                                  id=""
                                  value="AXIS BANK"
                                  name="bank_name"
                                  placeholder=""
                                  readonly=""
                                  autocomplete="off"
                                />
                                <button
                                  className="click_btn button copy_btn copy_to_clip"
                                  copy_value="AXIS BANK"
                                >
                                  Copy
                                </button>
                                <label className="form-float-label">
                                  Bank name
                                </label>
                              </div>
                              <div className="form-deposits">
                                <input
                                  className="form-control "
                                  type="text"
                                  id=""
                                  value="FOXCON INDIA"
                                  name="account_holder_name"
                                  placeholder=""
                                  readonly=""
                                  autocomplete="off"
                                />
                                <button
                                  className="click_btn button copy_btn copy_to_clip"
                                  copy_value="FOXCON INDIA"
                                >
                                  Copy
                                </button>
                                <label className="form-float-label">
                                  Account holder name
                                </label>
                              </div>
                              <div className="form-deposits">
                                <input
                                  className="form-control "
                                  type="text"
                                  id=""
                                  value="923020011631310"
                                  name="account_number"
                                  placeholder=""
                                  readonly=""
                                  autocomplete="off"
                                />
                                <button
                                  className="click_btn button copy_btn copy_to_clip"
                                  copy_value="923020011631310"
                                >
                                  Copy
                                </button>
                                <label className="form-float-label">
                                  Account Number
                                </label>
                              </div>
                              <div className="form-deposits">
                                <input
                                  className="form-control "
                                  type="text"
                                  id=""
                                  value="Current"
                                  name="account_type"
                                  placeholder=""
                                  readonly=""
                                  autocomplete="off"
                                />
                                <button
                                  className="click_btn button copy_btn copy_to_clip"
                                  copy_value="Current"
                                >
                                  Copy
                                </button>
                                <label className="form-float-label">
                                  Account Type
                                </label>
                              </div>
                              <div className="form-deposits">
                                <input
                                  className="form-control "
                                  type="text"
                                  id=""
                                  value="UTIB0004813"
                                  name="ifsc_code"
                                  placeholder=""
                                  readonly=""
                                  autocomplete="off"
                                />
                                <button
                                  className="click_btn button copy_btn copy_to_clip"
                                  copy_value="UTIB0004813"
                                >
                                  Copy
                                </button>
                                <label className="form-float-label">
                                  IFSC code
                                </label>
                              </div>
                              <div className="form-deposits">
                                <input
                                  className="form-control "
                                  type="text"
                                  id=""
                                  value="NEW DELHI"
                                  name="branch"
                                  placeholder=""
                                  readonly=""
                                  autocomplete="off"
                                />
                                <button
                                  className="click_btn button copy_btn copy_to_clip"
                                  copy_value="NEW DELHI"
                                >
                                  Copy
                                </button>
                                <label className="form-float-label">
                                  Branch
                                </label>
                              </div>
                            </div>
                            <div className="method_head">
                              Your Payment Details
                            </div>
                            <div className="deposit_section_in bank_trans_sec1">
                              <div className="form-deposits">
                                <input
                                  className="form-control bank_amount do-alphn"
                                  type="number"
                                  id=""
                                  value=""
                                  name="bank_amount"
                                  min="100"
                                  max="10000000"
                                  placeholder=""
                                  required=""
                                  autocomplete="off"
                                  data-maxlen="8"
                                />
                                <label className="form-float-label">
                                  Amount <small>Min:100 Max: 10000000</small>
                                </label>
                              </div>
                              <div className="form-deposits">
                                <input
                                  className="form-control do-alphn-s"
                                  type="text"
                                  id=""
                                  value="ALiFaisal"
                                  name="username"
                                  placeholder=""
                                  required=""
                                  autocomplete="off"
                                  data-maxlen="25"
                                  data-allowspecial="0"
                                  data-uupper="0"
                                />
                                <label className="form-float-label">
                                  USER ID
                                </label>
                              </div>
                              <div className="form-deposits">
                                <input
                                  className="form-control reference_id do-alphn"
                                  type="text"
                                  id="reference_id"
                                  value=""
                                  name="reference_id"
                                  placeholder=""
                                  required=""
                                  autocomplete="off"
                                  data-maxlen="25"
                                />
                                <label className="form-float-label">
                                  Ref. No. / Trans. ID / UTR{" "}
                                </label>
                              </div>
                              <div className="form-float-radio_btn">
                                <label className="form-float-label">
                                  Transferred By
                                </label>
                                <div className="bb_tran_subdiv_sec">
                                  <input
                                    type="radio"
                                    className="trans_type"
                                    required=""
                                    id="bt-imps"
                                    name="transfer_via"
                                    data-img="imps"
                                    value="IMPS"
                                    autocomplete="off"
                                  />
                                  <label for="Transfer"> IMPS</label>
                                  <input
                                    type="radio"
                                    className="trans_type ml-2"
                                    required=""
                                    id="bt-neft"
                                    name="transfer_via"
                                    data-img="neft"
                                    value="NEFT"
                                    autocomplete="off"
                                  />
                                  <label for="Transfer"> NEFT</label>
                                  <input
                                    type="radio"
                                    className="trans_type ml-2"
                                    required=""
                                    id="bt-rtgs"
                                    name="transfer_via"
                                    data-img="rtgs"
                                    value="RTGS"
                                    autocomplete="off"
                                  />
                                  <label for="Transfer"> RTGS</label>
                                </div>
                              </div>
                              <div
                                className="form-deposits promocode-area"
                                id="promocode-html-append-banktransfer"
                              >
                                <div>
                                  <a
                                    href="javascript:void(0)"
                                    className="have_promo have_promocode float-right"
                                    id="have_promocode"
                                    tabindex="71"
                                  >
                                    Have Promocode?
                                  </a>
                                </div>
                                <div
                                  id="promocode-section"
                                  className="form-group promocode-section"
                                  style={{ display: "none" }}
                                >
                                  <label>
                                    <b>Promocode</b>
                                  </label>
                                  <button
                                    type="button"
                                    className="close cancel-promocode"
                                  >
                                    <span aria-hidden="true">×</span>
                                  </button>
                                  <div className="input-group">
                                    <input
                                      id="promocode"
                                      type="text"
                                      name="promocode"
                                      className="form-control promocode promocode_input mr-2"
                                      placeholder="Enter Your Promocode"
                                      autocomplete="off"
                                    />
                                    <button
                                      id="apply-promocode"
                                      type="button"
                                      className="btn blue-button apply-promocode"
                                    >
                                      Apply
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="acc_details text-center"
                              style={{ marginTop: "12px" }}
                            >
                              <input
                                type="hidden"
                                id="offline_payment_type"
                                name="payment_type"
                                value="bank_transfers"
                                autocomplete="off"
                              />
                              <button
                                type="submit"
                                className="btn green-button bank_transfers_submit"
                                disabled=""
                                autocomplete="off"
                              >
                                Submit
                              </button>
                              <button
                                id="skip-payment"
                                type="button"
                                className="btn red-button skip-payment"
                              >
                                Skip
                              </button>
                            </div>
                          </form>
                        </div>

                        <div
                          id="offline_deposit_content_paytm"
                          data-payment_type="paytm"
                          className="depo_method"
                          style={{ display: "none" }}
                        >
                          <form
                            id="paytm_deposit_wallet"
                            className="offline_deposit offline_wallet_deposit"
                            action=""
                            method=""
                            data-toggle="validator"
                            role="form"
                            enctype="multipart/form-data"
                            novalidate="novalidate"
                            autocomplete="off"
                          >
                            <div className="method_head">Transfer Details</div>
                            <h6 className="method_subhead text-italic">
                              *Please make your payment on the details below and
                              submit transaction id
                            </h6>
                            <div className="valresponse"></div>
                            <div className="deposit_section_in bank_trans_sec1">
                              <div className="form-deposits">
                                <input
                                  className="form-control "
                                  type="text"
                                  id=""
                                  value="fsfba00044100@fincarem"
                                  name="wallet"
                                  placeholder=""
                                  readonly=""
                                  autocomplete="off"
                                />
                                <button
                                  className="click_btn button copy_btn copy_to_clip"
                                  copy_value="fsfba00044100@fincarem"
                                >
                                  Copy
                                </button>
                                <label className="form-float-label">
                                  Wallet
                                </label>
                              </div>
                              <div className="form-deposits">
                                <input
                                  className="form-control "
                                  type="text"
                                  id=""
                                  value="JANNATH MULTIPLEX"
                                  name="account_holder_name"
                                  placeholder=""
                                  readonly=""
                                  autocomplete="off"
                                />
                                <label className="form-float-label">
                                  Account Name
                                </label>
                              </div>
                            </div>
                            <div className="method_head">
                              Your Payment Details
                            </div>
                            <div className="deposit_section_in bank_trans_sec1">
                              <div className="form-deposits">
                                <input
                                  className="form-control number_only only-numeric valid do-alphn"
                                  type="number"
                                  id="wallet_amount_paytm"
                                  name="wallet_amount"
                                  min="100"
                                  max="10000000"
                                  data-maxlen="8"
                                  placeholder=""
                                  autocomplete="off"
                                />
                                <label className="form-float-label">
                                  Amount <small>Min:100 Max: 10000000</small>
                                </label>
                              </div>
                              <div className="form-deposits">
                                <input
                                  className="form-control do-alphn-s"
                                  type="text"
                                  id=""
                                  value="ALiFaisal"
                                  data-maxlen="25"
                                  data-clower="1"
                                  name="username"
                                  placeholder=""
                                  required=""
                                  autocomplete="off"
                                  data-allowspecial="0"
                                  data-uupper="0"
                                />
                                <label className="form-float-label">
                                  USER ID
                                </label>
                              </div>
                              <div className="form-deposits">
                                <input
                                  className="form-control do-alphn"
                                  type="text"
                                  id="reference_id"
                                  value=""
                                  name="reference_id"
                                  placeholder=""
                                  autocomplete="off"
                                  data-maxlen="25"
                                />
                                <label className="form-float-label">
                                  Ref. No. / Trans. ID / UTR{" "}
                                </label>
                              </div>
                              <div
                                className="form-deposits promocode-area"
                                id="promocode-html-append-paytm"
                              ></div>
                            </div>
                            <div
                              className="acc_details text-center"
                              style={{ marginTop: "12px" }}
                            >
                              <input
                                type="hidden"
                                id="offline_payment_paytm"
                                name="payment_type"
                                value="paytm"
                                autocomplete="off"
                              />
                              <button
                                type="submit"
                                className="btn green-button bank_transfers_submit"
                                autocomplete="off"
                              >
                                Submit
                              </button>
                              <button
                                id="skip-payment"
                                type="button"
                                className="btn red-button skip-payment"
                              >
                                Skip
                              </button>
                            </div>
                          </form>
                        </div>

                        <div
                          id="offline_deposit_content_online"
                          data-payment_type="online"
                          className="depo_method"
                          style={{ display: "none" }}
                        >
                          <h5 className="text-danger">
                            Due to technical reasons, Please make manual
                            deposits
                          </h5>
                        </div>

                        {/* <div id="promo_html"  style={{display: "none"}}>
                                 <div><a href="javascript:void(0)" className="have_promo have_promocode float-right" id="have_promocode" tabindex="71">Have Promocode?</a></div>
                                 <div id="promocode-section" className="form-group promocode-section"  style={{display: "none"}}>
                                    <label><b>Promocode</b></label><button type="button" className="close cancel-promocode"><span aria-hidden="true">×</span></button>
                                    <div className="input-group"><input id="promocode" type="text" name="promocode" className="form-control promocode promocode_input mr-2" placeholder="Enter your Promocode" autocomplete="off"/><button id="apply-promocode" type="button" className="btn blue-button apply-promocode">Apply</button></div>
                                 </div>
                              </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DepositMain;
