//entrypoint
import axios from 'axios';
import { getToken } from '../utils/constants';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import store from '../redux/store';
import { getFingerprint, hashFingerprint } from '../utils/commonFunctions';
const { dispatch } = store;

const generateFingerprint = async () => {
  // const fingerprint = await FingerprintJS.load();
  // const result = await fingerprint.get();
  // const visitorId = result.visitorId;
  const fingerprintData = getFingerprint();
  const fingerprintHash = hashFingerprint(fingerprintData);
  return fingerprintHash;
};
export const AXIOS_INSTANCE_WITHOUT_INTERCEPTOR = axios.create();

AXIOS_INSTANCE_WITHOUT_INTERCEPTOR.interceptors.request.use(async (config) => {
  const token = getToken();
  config.headers['x-origin'] = 'e5dadeccee37872f08f365bb82005462';
  config.headers['x-browser-fingerprint'] = await generateFingerprint();
  // config.headers["x-browser-fingerprint"] = "abc123";
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
AXIOS_INSTANCE_WITHOUT_INTERCEPTOR.interceptors.response.use(
  (response) => {
    const token = getToken();

    if (response.headers.authorization && token) {
      dispatch({
        type: 'accountData/setToken',
        payload: response?.headers?.authorization.replace('Bearer', ''),
      });
    }
    return response;
  },
  (error) => {
    console.log(error, 'errorGlobal');
    if (error?.response?.status === 401) {
      window.location.href = '/';
      localStorage.clear();
      dispatch({ type: 'accountData/logOut' });
    }
    return error;
  }
);

export const AXIOS_INSTANCE = AXIOS_INSTANCE_WITHOUT_INTERCEPTOR;
