import { Stomp } from '@stomp/stompjs';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter,
  Route,
  Router,
  Routes,
  useLocation,
  useParams,
} from 'react-router-dom';
import PrivateOutlet from './layout/PrivateOutlet';
import PublicOutlet from './layout/PublicOutlet';
// import AccountStatement from "./pages/accountStatement/AccountStatement";
import ActivityLog from './pages/activityLog/ActivityLog';
import BetHistory from './pages/betHistory/BetHistory';
import CasinoList from './pages/casino/CasinoList';
import Cards32A from './pages/casino/games/32Cards-A';
import Cards32B from './pages/casino/games/32Cards-B';
import JudgementCards3 from './pages/casino/games/3CardsJudgement';
import AmarAkbarAnthony from './pages/casino/games/AmabAkbarAnthony';
import AndarBahar from './pages/casino/games/AndarBahar';
import AndarBahar2 from './pages/casino/games/AndarBahar-2';
import BollywoodCasino from './pages/casino/games/BollywoodCasino';
import CasinoMeter from './pages/casino/games/CasinoMeter';
import CasinoQueen from './pages/casino/games/CasinoQueen';
import DragonTiger1Day from './pages/casino/games/DragonTiger1Day';
import DragonTiger202 from './pages/casino/games/DragonTiger202';
import DragonTiger201 from './pages/casino/games/DragonTiger201';
import InstantWorli from './pages/casino/games/InstantWorli';
import Lucky7A from './pages/casino/games/Lucky7A';
import Lucky7B from './pages/casino/games/Lucky7B';
import Poker20 from './pages/casino/games/Poker20-20';
import Race2020 from './pages/casino/games/Race2020';
import TeenPatti from './pages/casino/games/TeenPatti';
import TeenPatti1Day from './pages/casino/games/Teenpatti1Day';
import CasinoResult from './pages/casinoResults/CasinoResults';
import ChangePassword from './pages/ChangePassword';
import ComingSoon from './pages/ComingSoon';
import Dashboard from './pages/dashboard';
import GamePage from './pages/game/GamePage';
import ProfitLossReport from './pages/profitLossReport/ProfitLossReport';
import UnSetteledBet from './pages/unSetteledBet/UnSetteledBet';
import Video from './pages/Video';

import store, { saveState } from './redux/store';
import {
  CASINO_SERVICE_URL,
  createSocket,
  CRICKET_SERVICE_URL,
  IS_PROD,
  SOCCER_AND_TENNIS_SERVICE_URL,
} from './utils/constants';
import DragonTigerLion from './pages/casino/games/DragonTigerLion';
import TeenPattiTest from './pages/casino/games/TeenPattiTest';
import TeenPattiOpen from './pages/casino/games/TeenPattiOpen';
import CasinoWar from './pages/casino/games/CasinoWar';
import Five5Cricket from './pages/casino/games/Five-5Cricket';
import Cricket2020 from './pages/casino/games/Cricket20-20';
import Poker1Day from './pages/casino/games/Poker1Day';
import Pokerr6Players from './pages/casino/games/Poker6Players';
import Baccart from './pages/casino/games/Baccart';
import Baccart2 from './pages/casino/games/Baccarat2';
import Worli from './pages/casino/games/WorliMatka';
import FiveCricket from './pages/casino/games/Five-5Cricket';
import SuperOver from './pages/casino/games/SuperOver';
import Loading from './components/Loading';
import { useMemo } from 'react';
import { usePrefersReducedMotion } from '@chakra-ui/react';
import Login from './pages/login';
import SecurityAuth from './pages/settings/SecurityAuth';
import LoginAuth from './pages/login/loginAuth';
import ChangeButtonValue from './pages/ChangeButtonValue';
import ChangePasswordPage from './pages/ChangePasswordPage';
import LastResults from './components/LastResults';
import { AccountStatement } from './components/AccountStatement';
import { CurrentBets } from './components/Currentbets';
import { LiveCasinoBets } from './components/LiveCasinoBet';
import { FAQ } from './components/FAQ';
import {
  FinalCasinoResults,
  FinancialCasinoResult,
} from './components/CasinoResults';
import { UserDashBoardPage } from './pages/UserDashBoardPage';
import { UserDetails } from './pages/UserDetalisPage';
import { SendMoney } from './components/SendMoney';
import { LandingPage } from './pages/LandingPage';
import { BetsHistory } from './components/BetHistory';
import PriceLabelForm from './components/PriceLabelForm';
import SignUpCard from './components/login/signUp';
import SignUp from './pages/login/signUp';
import ForgotPassword from './pages/forgotPassword';
import { getMatches } from './redux/features/gameSlice';
import {
  getFingerprint,
  getService,
  hashFingerprint,
} from './utils/commonFunctions';
import { setUserData } from './redux/features/accountSlice';
import { CasinotBets } from './components/CasinoBets';
import CardGamesList from './pages/casino/CardGamesList';
import MyDepositSection from './pages/wallet/MyDeposits/MyDepositSection';
import MywithdrawlSec from './pages/wallet/MyWithdrawls/MywithdrawlSec';
import DepositSection from './pages/wallet/DepositCredit/DepositSection';
import DepositMain from './pages/wallet/DepositCredit/DepositMain';
import Deposite from './pages/deposite';
import Withdrawal from './pages/withdrawal';
import { DepositHistory } from './pages/depositHistory';
import { WithdrawalHistory } from './pages/withdrawalHistory';
import { DirectUsers } from './pages/directUsers';
import { AddNewUser } from './pages/addNewMember';
import BankAccount from './pages/bankAccount';

export default function App() {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.account.userData);
  const isLoading = useSelector((state) => state.account.loading);
  const prefersMotion = usePrefersReducedMotion();
  const location = useLocation();
  const { sportsName } = useParams();
  const { gameKey } = useSelector((state) => state.gameData);
  const permissions = JSON.parse(localStorage.getItem('state'));
  const canAddNewUser = permissions?.account?.userData?.canCreateUser;
  const canUseBanking = permissions?.account?.userData?.canUseBanking;

  useEffect(() => {
    if (location.pathname.includes('live-casino')) {
      if (gameKey) {
        createSocket(
          'casino',
          dispatch,
          gameKey,
          getService(location.pathname).port,
          getService(location.pathname).service
        );
      }
    }
  }, [gameKey, dispatch]);

  useEffect(() => {
    if (location.pathname == '/') {
      dispatch(getMatches('cricket'));
      dispatch(getMatches('soccer'));
      dispatch(getMatches('tennis'));
    }
  }, []);

  useEffect(() => {
    if (userData?.token && userData?.resetRequired) {
      dispatch({
        type: 'accountData/setChangePopSate',
        payload: true,
      });
    }
  }, [userData]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      dispatch({
        type: 'accountData/setUserData',
        payload: user,
      });
    }
  }, []);

  useEffect(() => {
    if (location.pathname.includes('cricket')) {
      dispatch(getMatches('cricket'));
    } else if (location.pathname.includes('tennis')) {
      dispatch(getMatches('tennis'));
    } else if (location.pathname.includes('soccer')) {
      dispatch(getMatches('soccer'));
    }
  }, [dispatch, location.pathname]);
  useEffect(() => {
    store.subscribe(() => {
      saveState(store.getState());
    });
  }, []);

  useEffect(() => {
    const fingerprintData = getFingerprint();
    const fingerprintHash = hashFingerprint(fingerprintData);
    console.log(fingerprintHash, 'fingerprintHash');
  }, []);

  return userData?.token ? (
    <PrivateOutlet>
      {/* {isLoading && <Loading />} */}
      <Routes>
        {/* <Route exact path="/landing"  element={< />} /> */}
        <Route exact path='/' element={<LandingPage />} />
        <Route exact path='/games/:sportName' element={<Dashboard />} />
        <Route exact path='/profile' element={<Dashboard />} />
        <Route exact path='/game/:game/:id' element={<GamePage />} />
        <Route path='/live-casino' element={<CasinoList />} />
        <Route path='/card-games' element={<CardGamesList />} />
        <Route path='/live-casino/teenpatti/20-20' element={<TeenPatti />} />
        <Route
          path='/live-casino/teenpatti/1-day'
          element={<TeenPatti1Day />}
        />
        <Route path='/live-casino/teenpatti/test' element={<TeenPattiTest />} />
        <Route path='/live-casino/teenpatti/open' element={<TeenPattiOpen />} />
        <Route
          path='/live-casino/dragon-tiger/20-20'
          element={<DragonTiger201 />}
        />

        <Route
          path='/live-casino/dragon-tiger/20-20/2'
          element={<DragonTiger202 />}
        />

        <Route path='/live-casino/card-32/a' element={<Cards32A />} />
        <Route path='/live-casino/card-32/b' element={<Cards32B />} />
        <Route path='/live-casino/race/20-20' element={<Race2020 />} />
        <Route path='/live-casino/cn/queen' element={<CasinoQueen />} />

        <Route
          path='/live-casino/dragon-tiger/1-day'
          element={<DragonTiger1Day />}
        />
        <Route path='/live-casino/lucky7/a' element={<Lucky7A />} />
        <Route path='/live-casino/lucky7/b' element={<Lucky7B />} />
        <Route path='/live-casino/andar-bahar' element={<AndarBahar />} />
        <Route path='/live-casino/andar-bahar/2' element={<AndarBahar2 />} />
        <Route
          path='/live-casino/3cardsjudgement'
          element={<JudgementCards3 />}
        />
        <Route
          path='/live-casino/cn/bollywood-table'
          element={<BollywoodCasino />}
        />
        <Route
          path='/live-casino/andar-bahar/aaa'
          element={<AmarAkbarAnthony />}
        />

        <Route path='/live-casino/poker/20-20' element={<Poker20 />} />
        <Route path='/live-casino/worli/2' element={<InstantWorli />} />
        <Route path='/live-casino/cn/meter' element={<CasinoMeter />} />
        <Route
          path='/live-casino/dragon-tiger/dtl'
          element={<DragonTigerLion />}
        />

        <Route path='/live-casino/cn/war' element={<CasinoWar />} />

        <Route path='/live-casino/cricket/five' element={<FiveCricket />} />
        <Route path='/live-casino/cricket/20-20' element={<Cricket2020 />} />
        <Route path='/live-casino/poker' element={<Poker1Day />} />
        <Route
          path='/live-casino/poker/poker-6-player'
          element={<Pokerr6Players />}
        />
        <Route path='/live-casino/baccarat' element={<Baccart />} />
        <Route path='/live-casino/baccarat/2' element={<Baccart2 />} />
        <Route path='/live-casino/worli' element={<Worli />} />
        <Route path='/live-casino/cricket/super-over' element={<SuperOver />} />

        <Route
          path='/report/account-statement'
          element={<AccountStatement />}
        />
        <Route path='/report/bet-history' element={<BetHistory />} />
        <Route
          path='/report/profit-loss-report'
          element={<ProfitLossReport />}
        />
        <Route path='/report/unsetteled-bet' element={<UnSetteledBet />} />
        <Route path='/report/activity-log' element={<ActivityLog />} />
        <Route path='/report/casino-result' element={<CasinoResult />} />
        <Route path='/report/changebtnvalue' element={<ChangeButtonValue />} />
        <Route path='/report/changepassword' element={<ChangePasswordPage />} />
        <Route path='/settings/secureauth' element={<SecurityAuth />} />
        <Route path='/account' element={<AccountStatement />} />
        <Route path='/current' element={<CurrentBets />} />
        <Route path='/casino-bets' element={<CasinotBets />} />
        <Route path='/FAQ' element={<FAQ />} />
        <Route path='/casino-result' element={<FinancialCasinoResult />} />
        <Route path='/dashboard' element={<UserDashBoardPage />} />
        <Route path='/user-details' element={<UserDetails />} />
        <Route path='/bet_history' element={<BetsHistory />} />
        <Route path='/change-password' element={<ChangePassword />} />

        {canUseBanking &&
          <>
            <Route path='/sendMoney' element={<SendMoney />} />
            <Route path='/deposit' element={<Deposite />} />
            <Route path='/withdrawal' element={<Withdrawal />} />
            <Route path='/bank-account' element={<BankAccount />} />
            <Route path='/deposit-history' element={<DepositHistory />} />
            <Route path='/withdrawal-history' element={<WithdrawalHistory />} />
          </>}



        {canAddNewUser &&
          <>
            <Route path='/add-newUser' element={<AddNewUser />} />
            <Route path='/direct-users' element={<DirectUsers />} />
          </>
        }

        <Route exact path='/my-deposits' element={<MyDepositSection />} />
        <Route exact path='/my-withdrawals' element={<MywithdrawlSec />} />
        <Route exact path='/deposit-credit' element={<DepositSection />} />
        <Route exact path='/deposit-modal' element={<DepositMain />} />
      </Routes>
    </PrivateOutlet>
  ) : (
    <PublicOutlet>
      {/* {isLoading && <Loading />} */}
      <Routes>
        <Route exact path='/' element={<Login />} />
        <Route exact path='/sign-up' element={<SignUp />} />
        <Route exact path='/forgot-password' element={<ForgotPassword />} />
        <Route exact path='/loginauth/2' element={<LoginAuth />} />
        <Route exact path='video' element={<Video />} />
        <Route path='/game/:game/:id' element={<GamePage />} />
        <Route path='/live-casino' element={<CasinoList />} />
        <Route path='/live-casino' element={<CardGamesList />} />
        <Route
          path='/live-casino/dragon-tiger/20-20/2'
          element={<DragonTiger202 />}
        />
        <Route path='/live-casino/teenpatti/20-20' element={<TeenPatti />} />
        <Route path='/live-casino/card-32/a' element={<Cards32A />} />
        <Route path='/live-casino/card-32/b' element={<Cards32B />} />
        <Route path='/live-casino/race20' element={<Race2020 />} />
        <Route path='/live-casino/cn/queen' element={<CasinoQueen />} />
        <Route
          path='/live-casino/dragon-tiger/20-20'
          element={<DragonTiger201 />}
        />
        <Route
          path='/live-casino/dragon-tiger/1-day'
          element={<DragonTiger1Day />}
        />
        <Route path='/live-casino/lucky7a' element={<Lucky7A />} />
        <Route path='/live-casino/lucky7b' element={<Lucky7B />} />
        <Route path='/live-casino/andar-bahar' element={<AndarBahar />} />
        <Route path='/live-casino/andar-bahar/2' element={<AndarBahar2 />} />
        <Route
          path='/live-casino/3cardsjudgement'
          element={<JudgementCards3 />}
        />
        <Route path='/live-casino/btable' element={<BollywoodCasino />} />
        <Route path='/live-casino/aaa' element={<AmarAkbarAnthony />} />
        <Route
          path='/live-casino/teenpatti/1-day'
          element={<TeenPatti1Day />}
        />
        <Route path='/live-casino/poker/20-20' element={<Poker20 />} />
        <Route path='/live-casino/worli2' element={<InstantWorli />} />
        <Route path='/live-casino/cmeter' element={<CasinoMeter />} />
        <Route
          path='/live-casino/dragon-tiger/dtl'
          element={<DragonTigerLion />}
        />
        <Route path='/live-casino/teenpatti/test' element={<TeenPattiTest />} />
        <Route path='/live-casino/teenpatti/open' element={<TeenPattiOpen />} />
        <Route path='/live-casino/war' element={<CasinoWar />} />
        <Route path='/live-casino/cricketv3' element={<Five5Cricket />} />
        <Route path='/live-casino/cmatch20' element={<Cricket2020 />} />
        <Route path='/live-casino/poker' element={<Poker1Day />} />
        <Route
          path='/live-casino/poker/poker-6-player'
          element={<Pokerr6Players />}
        />
        <Route path='/live-casino/baccarat' element={<Baccart />} />
        <Route path='/live-casino/baccarat/2' element={<Baccart2 />} />
      </Routes>
    </PublicOutlet>
  );
}
