import {
  Stack,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  Select,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  MenuItem,
} from "@mui/material";
import React, { useRef, useState } from "react";
import pdf_logo from "../../../assets/images/icons/pdf.svg";
import ppt_logo from "../../../assets/images/icons/ppt.svg";
import previous_all from "../../../assets/images/icons/double_left_arrow.svg";
import previous_one from "../../../assets/images/icons/single_left_arrow.svg";
import next_all from "../../../assets/images/icons/double_right_arrow.svg";
import next_one from "../../../assets/images/icons/single_right_arrow.svg";
import "../../../assets/styles/css/genericTable.scss";
import { radioOptions, perPageOptions } from "./index.utils";
import { useGenericTable } from "../../../hooks/genericTable.hooks";
import { DownloadTableExcel } from "react-export-table-to-excel";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Popover, PopoverContent, PopoverTrigger, Text, background } from "@chakra-ui/react";
import "../../fixture.css";
import { getBetsFromAccountLogs } from "../../../service/AuthService";
import moment from "moment";

export const GenericTable = ({
  tableHeader,
  tableRow,
  isCurrentBets,
  currentBetsData,
  isBetHistory,
  fromCasinoBets,
  dipositHistory,
  onRemarkClick,
}) => {
  const {
    perPageData,
    radioValue,
    tableFilteredRowData,
    currentPage,
    handleDropdownChange,
    handleRadioChange,
    handleAllPreviousClick,
    handlePreviousClick,
    handleAllNextClick,
    handleNextClick,
    setTableFilteredRowData,
    startIndex,
    endIndex,
  } = useGenericTable(tableRow);

  const tableRef = useRef(null);

  const generateReportPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({ html: "#table" });
    doc.save("table.pdf");
  };

  const totalCount = tableFilteredRowData.reduce(
    (total, item) => item.Amount + total,
    0
  );

  return (
    <Stack className="generic-table-main-container">
      <Stack ml={1}>
        {isCurrentBets && (
          <Stack className="radio-group-main-container">
            <FormControl className="radio-group-main-form-control" >
              <RadioGroup
                row
                value={radioValue}
                color="black"
                onChange={handleRadioChange}
                className="radio-group-main-radio"
              >
                {radioOptions?.map((radioOption) => (
                  <FormControlLabel
                    key={radioOption.value}
                    value={radioOption.value}
                    onChange={(e) => {
                      const _temp = [...currentBetsData];
                      let _filtered = [];
                      if (e.target.value === "back") {
                        _filtered = _temp.filter((j) => j.back_run);
                      } else if (e.target.value === "lay") {
                        _filtered = _temp.filter((j) => j.lay_run);
                      } else {
                        _filtered = _temp;
                      }

                      setTableFilteredRowData(_filtered);
                    }}
                    control={<Radio />}
                    label={radioOption.label}
                    className="generic-table-control-labels"
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Stack>
        )}
      </Stack>
      <Stack className="generic-table-main-container-inner">

        <Stack className="generic-table-main-container-inner-1">
          <p className="generic-table-text-1">Show</p>
          <Select
            value={perPageData}
            onChange={handleDropdownChange}
            className="generic-table-main-conatainer-select"
          >
            {perPageOptions?.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                className="generic-table-menu-item"
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
          <p className="generic-table-text-1">entries</p>
        </Stack>

        <Stack className="generic-table-main-container-inner-5">
          <img
            className="file-logo"
            src={pdf_logo}
            alt="PDF Logo"
            onClick={generateReportPDF}
            style={{ cursor: "pointer" }}
          />

          <DownloadTableExcel
            filename="users table"
            sheet="users"
            currentTableRef={tableRef.current}
          >
            <img
              className="file-logo"
              src={ppt_logo}
              alt="PPT Logo"
              style={{ cursor: "pointer" }}
            />{" "}
          </DownloadTableExcel>
        </Stack>
      </Stack>

      <TableContainer className="generic-mui-table-container">
        <table id="table">
          <TableHead className="generic-mui-table-head">
            {tableHeader.map((head) => (
              <TableCell className="generic-mui-table-head-cell" key={head}>
                <p className="generic-table-header-text">{head}</p>
              </TableCell>
            ))}
          </TableHead>
          {!tableFilteredRowData || tableFilteredRowData.length === 0 ? (
            <p className="generic-table-text-4">No records found</p>
          ) : (
            <TableBody className="generic-mui-table-body">
              {tableFilteredRowData
                .slice(startIndex, endIndex)
                .map((row, ind) => (
                  <TableRow
                    style={{
                      background: row.lay_run ? "#FFCCDF" : "#BBE3FF",
                    }}
                    key={ind}
                    className="generic-mui-table-row"
                  >
                    {tableHeader.map((head) => (
                      <TableCell
                        className="generic-mui-table-row-cell"
                        key={head}
                      >
                        <p
                          className={`generic-table-row-text-style ${head === "Debit"
                            ? "red-text"
                            : head === "Credit"
                              ? "green-text"
                              : ""
                            }`}
                          onClick={head === "Remark"
                            ? () => onRemarkClick(row.accountLogId)
                            : undefined
                          }
                        >
                          <span style={head === "Remark"
                            ? {
                              backgroundColor: row.type == 'GAME_REPORT' ? '#102a6d' : 'transparent',
                              color: row.type == 'GAME_REPORT' ? 'white' : 'black',
                              padding: "4px",
                              cursor: row.type == 'GAME_REPORT' ? 'pointer' : '',
                              borderRadius: "4px",
                            }
                            : {}}>
                            {head === "User Rate"
                              ? row.lay_run || row.back_run
                              : row[head]}
                          </span>
                        </p>

                      </TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableBody>
          )}
        </table>

        {tableRow?.length > 0 && (
          <TableFooter className="generic-mui-table-footer">
            <img
              src={previous_all}
              alt="previous-all"
              onClick={handleAllPreviousClick}
              className="img-1"
            />
            <img
              src={previous_one}
              alt="previous"
              onClick={handlePreviousClick}
              className="img-1"
            />
            <p className="generic-table-text-5">{currentPage}</p>
            <img
              src={next_one}
              alt="next"
              onClick={handleNextClick}
              className="img-1"
            />
            <img
              src={next_all}
              alt="next-all"
              onClick={handleAllNextClick}
              className="img-1"
            />
          </TableFooter>
        )}
      </TableContainer>

    </Stack>
  );
};
