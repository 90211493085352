/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Image,
  css,
  Button,
  Box,
  Flex,
  Text,
} from '@chakra-ui/react';
import './exportTab.css';
import TabsContent from './tabsContent';
import { buttonColors } from '../../utils/constants';
import { useReducer } from 'react';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { BetSlip } from '../../components/BetSlip';
import FootballMenuIcon from '../../components/Icons/FootballMenu';
import TennisMenuIcon from '../../components/Icons/TennisMenu';

export default function ExportTabs() {
  const { sportName } = useParams();
  const tabListRef = useRef();
  const handleScroll = (direction) => {
    const tabList = tabListRef.current;
    if (tabList) {
      const scrollAmount = 200;
      if (direction === 1) {
        tabList.scrollLeft += scrollAmount;
      } else {
        tabList.scrollLeft -= scrollAmount;
      }
    }
  };
  const [_selected, setSelected] = React.useState('cricket');
  const [sports, setSports] = React.useState([
    {
      name: 'Cricket',
      id: 'cricket',
      icon: 'https://dzm0kbaskt4pv.cloudfront.net/v11/static/mobile/img/gameImg/4.png',
    },
    {
      name: 'Football',
      id: 'soccer',
      icon: 'https://dzm0kbaskt4pv.cloudfront.net/v11/static/mobile/img/gameImg/1.png',
    },
    {
      name: 'Tennis',
      id: 'tennis',
      icon: 'https://dzm0kbaskt4pv.cloudfront.net/v11/static/mobile/img/gameImg/2.png',
    },
    {
      name: 'Basketball',
      id: 'basketball',
      icon: 'https://dzm0kbaskt4pv.cloudfront.net/v11/static/mobile/img/gameImg/15.png',
    },
    {
      name: 'Volleyball',
      id: 'volleyball',
      icon: 'https://dzm0kbaskt4pv.cloudfront.net/v11/static/mobile/img/gameImg/18.png',
    },
    {
      name: 'Snooker',
      id: 'snooker',
      icon: 'https://dzm0kbaskt4pv.cloudfront.net/v11/static/mobile/img/gameImg/59.png',
    },
    // {
    //   name: "Handball",
    //   id: "handball",
    // },
    {
      name: 'Horse Racing',
      id: 'horse_racing',
      icon: 'https://dzm0kbaskt4pv.cloudfront.net/v11/static/mobile/img/gameImg/10.png',
    },
    // {
    //   name: "Handball",
    //   id: null,
    // },
    // {
    //   name: "Ice Hockey",
    //   id: null,
    // },
  ]);

  useEffect(() => {
    if (sportName) {
      const _sports = [...sports];
      const filtered = _sports.filter((k) => k.id === sportName);

      setSports(filtered);
      setSelected(sportName);
    }
  }, []);

  console.log(sportName, 'check page');

  return (
    <>
      <div
        style={{
          position: 'relative',
          paddingRight: '5px',
        }}
        className='sport-tabs '
      >
        {/* <a onClick={() => handleScroll(-1)} className='arrow-tabs arrow-left'>
          <Image
            h='5px'
            src='https://sitethemedata.com/v115/static/front/img/arrow-down.svg'
          />
        </a> */}
        <Tabs defaultIndex={0} p={0} id='taj_home_sports_list'>
          <TabPanels>
            {sports.length === 0 && (
              <h5 className='norecords'>No Data Found</h5>
            )}
            {sports.map((sport) => (
              <TabPanel padding={0}>
                <TabsContent
                  sportName={sportName}
                  color='lightgray'
                  id={sport.id}
                  icon={
                    <Image
                      h='15px'
                      w='15px'
                      objectFit='contain'
                      src={require(`../../assets/img/icons/${sport.name} Icon.svg`)}
                    />
                  }
                />
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
        {/* <a onClick={() => handleScroll(1)} className='arrow-tabs arrow-right'>
          <Image
            ml='10px'
            h='5px'
            src='https://sitethemedata.com/v115/static/front/img/arrow-down.svg'
          />
        </a> */}
        {/* <BetSlip /> */}
      </div>
    </>
  );
}
