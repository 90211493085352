import React from "react";
import { Stack, Typography } from "@mui/material";
import '../../../../src/assets/styles/css/textwithImage.scss'

export const TextWithImage = ({ src, text }) => {
  return (
    <Stack className="main-stack">
      <Stack direction={"row"} spacing={1} className="main-inner">
        <img src={src} alt="" className="text-image-img" />
        <Typography className="text-style">
          {text}
        </Typography>
      </Stack>
    </Stack>
  );
};
