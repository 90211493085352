import moment from "moment/moment";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SingleOddsBox from "./SingleOddsBox";

export default function GameList({ id }) {
  const data = useSelector((state) => state.gameData[id + "Games"]);
  const naviagte = useNavigate();
  useEffect(() => {}, [data]);
  const navigateToGame = (gameEvent) => {
    naviagte(`/game/${id}/${gameEvent[id + "Id"]}`, {
      state: { gameEvent },
    });
  };
  return (
    <table class="table coupon-table">
      {/* <thead>
        <tr>
          <th>Game Text</th>
          <th colspan="2">1</th>
          <th colspan="2">X</th>
          <th colspan="2">2</th>
        </tr>
      </thead> */}
      <tbody style={{minHeight:"50px"}}>
        {data?.map((gameEvent) => (
          <tr>
            <td>
              <a
                style={{
                  cursor: "pointer",
                }}
                class="text-dark"
                href
              >
                <div
                  onClick={() => navigateToGame(gameEvent)}
                  class="game-name"
                >
                  {gameEvent?.eventName} /{" "}
                  {(gameEvent?.eventTime || gameEvent?.eventDate) &&
                    moment(
                      gameEvent.eventDate
                        ? gameEvent.eventDate
                        : gameEvent.eventTime
                    ).format("MMM DD YYYY h:mm a")}
                </div>
              </a>
              <div class="game-icons">
                <span class="game-icon">
                  {gameEvent?.inPlay && <span class="active"></span>}
                </span>
                <span class="game-icon"></span>
                <span class="game-icon">
                  <img
                    src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/front/img/icons/ic_fancy.png"
                    class="fancy-icon"
                    alt=""
                  />
                </span>{" "}
                <span class="game-icon">
                  <img
                    src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/front/img/icons/ic_bm.png"
                    class="bookmaker-icon"
                    alt=""
                  />
                </span>
                <span class="game-icon"></span>
              </div>
            </td>
            <td>
              <SingleOddsBox data={gameEvent?.back1} isBack={true} />
            </td>
            <td>
              <SingleOddsBox data={gameEvent?.lay1} />
            </td>
            <td>
              <SingleOddsBox data={gameEvent?.back11} isBack={true} />
            </td>
            <td>
              <SingleOddsBox data={gameEvent?.lay11} />
            </td>
            <td>
              <SingleOddsBox data={gameEvent?.back12} isBack={true} />
            </td>
            <td>
              <SingleOddsBox data={gameEvent?.lay12} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
