import Image1 from '../../../src/assets/images/icons/image1.jpg';
import Image2 from '../../../src/assets/images/icons/image2.jpg';
import Image3 from '../../../src/assets/images/icons/image3.jpg';
import Image4 from '../../../src/assets/images/icons/image4.jpg';
import Image5 from '../../../src/assets/images/icons/image5.jpg';
import Image6 from '../../../src/assets/images/icons/image6.jpg';
import Image7 from '../../../src/assets/images/icons/image7.jpg';
import Image8 from '../../../src/assets/images/icons/image8.jpg';
import Image9 from '../../../src/assets/images/icons/image9.jpg';
import Image10 from '../../../src/assets/images/icons/image10.jpg';
import Image11 from '../../../src/assets/images/icons/image11.jpg';
import Image12 from '../../../src/assets/images/icons/image12.jpg';

export const cardImages = [
    Image1,
    Image2,
    Image3,
    Image4,
    Image5,
    Image6,
    Image7,
    Image8,
    Image9,
    Image10,
    Image11,
    Image12
  ];

export const groupedImages = [];

  for (let i = 0; i < cardImages.length; i += 5) {
    groupedImages.push(cardImages.slice(i, i + 5));
  }