import { useEffect, useState } from "react";
import { generateRandomString } from "../../src/components/SendMoney/index.utils";
import { useNavigate } from "react-router-dom";
import { AXIOS_INSTANCE } from "../service";
import { BASE_URL, Encrypt } from "../utils/constants";
import { useToast } from "@chakra-ui/react";

const useSendMoney = () => {
  const toast = useToast();
  const [payeeLoginId, setPayeeLoginId] = useState("");
  const [payeeName, setPayeeName] = useState("");
  const [remark, setRemark] = useState("");
  const [userName, setUserName] = useState("");
  const [transferAmount, setTransferAmount] = useState("");
  const [securityPin, setSecurityPin] = useState("");
  const [captchaCode, setCaptchaCode] = useState(generateRandomString());
  const [enteredCaptcha, setEnteredCaptcha] = useState("");
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const navigate = useNavigate();

  const handleButtonClick = async () => {
    const payload = {
      balance: transferAmount,
      name: payeeName,
      remarks: remark,
      transactionPassword: Encrypt(securityPin),
      userName: userName.toUpperCase(),
    };
    try {
      const res = await AXIOS_INSTANCE.put(
        `${BASE_URL}/user/send-money`,
        payload
      );

      if (res.status === 200) {
        console.log("Money sent successfully");
        toast({
          title: "Success",
          description: res.data.response,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setPayeeLoginId("");
        setPayeeName("");
        setRemark("");
        setUserName("");
        setTransferAmount("");
        setSecurityPin("");
        setEnteredCaptcha("");
        setCaptchaCode(generateRandomString());
      } else {
        console.warn(`Unexpected response status: ${res.status}`);
        toast({
          title: "Error",
          description: res.data.response, // Using dynamic response message
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error sending money:", error);
      toast({
        title: "Error",
        description: "Error sending money",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleRefreshCaptcha = () => {
    setCaptchaCode(generateRandomString());
  };

  const handleEnteredCaptchaChange = (e) => {
    setEnteredCaptcha(e.target.value);
  };

  useEffect(() => {
    setCaptchaCode(generateRandomString());
  }, []);

  useEffect(() => {
    const isFormValid =
      payeeName &&
      userName &&
      transferAmount &&
      remark &&
      securityPin &&
      enteredCaptcha === captchaCode;
    setIsSubmitDisabled(!isFormValid);
  }, [payeeName, userName, transferAmount, remark, securityPin, enteredCaptcha, captchaCode]);

  return {
    payeeLoginId,
    setPayeeLoginId,
    payeeName,
    setPayeeName,
    remark,
    setRemark,
    userName,
    setUserName,
    transferAmount,
    setTransferAmount,
    securityPin,
    setSecurityPin,
    captchaCode,
    enteredCaptcha,
    handleEnteredCaptchaChange,
    isSubmitDisabled,
    handleButtonClick,
    handleRefreshCaptcha,
  };
};

export default useSendMoney;
