import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  WrapItem,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaLock } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Flip, Flipr } from "../../../components/Flip";
import {
  getCasinoPlExposureAPI,
  placeCasinoBetAPI,
} from "../../../service/casino";
import PlaceBet from "../../game/PlaceBet";
import CardDrawer from "../../../components/CardDrawer/CardDrawer";
import { useMemo } from "react";
import Iframe from "react-iframe";
import IframeComponent from "../../../components/Iframe";
import LastResults from "../../../components/LastResults";
import { setGameKey } from "../../../redux/features/gameSlice";
import { getService } from "../../../utils/commonFunctions";
import { useLocation } from "react-router-dom";
import CasinoVideoHome from "../../../components/CasinoVideoRules/casinoVideoHome";

export default function InstantWorli() {
  const location = useLocation();
  const odds_ = useSelector((state) => state.gameData.worli2Odds);
  //useMemo
  const odds = useMemo(() => odds_, [odds_]);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [isLocked, setIsLocked] = useState(false);
  const authDetails = useSelector((state) => state.account.authDetails);
  const toast = useToast();
  const [plExposure, setPlExposure] = useState(null);
  const dispatch = useDispatch();
  const gameName = "WORLI2";
  const CustomButton = ({ text }) => (
    <Button size="sm" my="2" className="casinoButton">
      {isLocked ? <FaLock /> : text}
    </Button>
  );
  const getData = (id) => {
    let data = odds?.oddDetailsDTOS?.find((item) => item.runnerName == id);
    return data ? data : {};
  };

  const getProfit = (selectedEventForBet) => {
    return (selectedEventForBet.back1 - 1) * selectedAmount;
  };
  const setEvent = (id) => {
    let selectedEventForBet = getData(id);
    let payload = {};
    payload.amount = parseInt(selectedAmount);
    payload.casinoGames = gameName;
    payload.profit = getProfit(selectedEventForBet);
    payload.loss = parseInt(selectedAmount);
    payload.selectedOdd = selectedEventForBet.back1;
    payload.selectedId = selectedEventForBet.selectionId;
    payload.marketId = odds.marketId;
    payload.runnerName = selectedEventForBet.runnerName;
    payload.type = "back";

    dispatch({
      type: "gameData/setSelectedEventForBet",
      payload,
    });
  };

  useEffect(() => {
    dispatch(setGameKey("worli2"));
  }, [dispatch]);

  useEffect(() => {
    if (odds?.autoTime <= 5) {
      setIsLocked(true);
    } else {
      setIsLocked(false);
    }
  }, [odds, getData]);
  const getPlExposure = async (id) => {
    const res = await getCasinoPlExposureAPI(
      getService(location.pathname).port,
      getService(location.pathname).service,
      id,
      authDetails?.uid
    );
    if (res && res?.code == 200) {
      setPlExposure(res.response);
    }
  };
  const getExposureForEntity = (selectionName, selectionId) => {
    if (plExposure) {
      let data = plExposure?.find((item) =>
        item?.casinoPLExposure?.find(
          (exposure) => exposure.selectionName == selectionName
        )
      );
      return data ? data : null;
    }
  };
  useEffect(() => {
    let interval = setInterval(() => {
      if (odds?.marketId) {
        getPlExposure(odds?.marketId);
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [odds?.marketId]);
  return (
    <Box
      display="flex"
      flexDirection={{ base: "column", sm: "column", lg: "row" }}
    >
      <Box mt="1" mr="1" w={{ base: "100%", sm: "100%", lg: "70%" }}>
        <Box h="60vh" mb="2" pos="relative" className="casino-video">
          {odds?.cards && (
            <CardDrawer
              cards={[odds?.cards[0][0], odds?.cards[1][0], odds?.cards[2][0]]}
            />
          )}
          <div class="market-title">Instant Worli</div>

          <IframeComponent
            url={`${process.env.REACT_APP_IFRAME_URL}?id=3040`}
          />
          <Box
            bottom="0"
            p="2"
            pos="absolute"
            right="2"
            display="flex"
            bg="black"
          >
            <Flip value={odds?.autoTime} />
          </Box>
          <CasinoVideoHome/>
        </Box>

        <div className="casino-box tab-content">
          <div
            id="single"
            style={{
              width: "100%",
            }}
            className="tab-pane active single"
          >
            <div className={`worlibox ${isLocked ? "suspended" : ""}`}>
              <div className="worli-left">
                <div className="worli-box-title">
                  <b>9</b>
                </div>{" "}
                <div className="worli-box-row">
                  <div
                    onClick={() => setEvent("1")}
                    className="worli-odd-box back"
                  >
                    <span className="worli-odd">1</span>
                    <span>{getExposureForEntity("1")?.exposure}</span>
                  </div>{" "}
                  <div
                    onClick={() => setEvent("2")}
                    className="worli-odd-box back"
                  >
                    <span className="worli-odd">2</span>
                    <span>{getExposureForEntity("2")?.exposure}</span>
                  </div>{" "}
                  <div
                    onClick={() => setEvent("3")}
                    className="worli-odd-box back"
                  >
                    <span className="worli-odd">3</span>
                    <span>{getExposureForEntity("3")?.exposure}</span>
                  </div>{" "}
                  <div
                    onClick={() => setEvent("4")}
                    className="worli-odd-box back"
                  >
                    <span className="worli-odd">4</span>
                    <span>{getExposureForEntity("4")?.exposure}</span>
                  </div>{" "}
                  <div
                    onClick={() => setEvent("5")}
                    className="worli-odd-box back"
                  >
                    <span className="worli-odd">5</span>
                    <span>{getExposureForEntity("5")?.exposure}</span>
                  </div>
                </div>{" "}
                <div className="worli-box-row">
                  <div
                    onClick={() => setEvent("6")}
                    className="worli-odd-box back"
                  >
                    <span className="worli-odd">6</span>
                    <span>{getExposureForEntity("6")?.exposure}</span>
                  </div>{" "}
                  <div
                    onClick={() => setEvent("7")}
                    className="worli-odd-box back"
                  >
                    <span className="worli-odd">7</span>
                    <span>{getExposureForEntity("7")?.exposure}</span>
                  </div>{" "}
                  <div
                    onClick={() => setEvent("8")}
                    className="worli-odd-box back"
                  >
                    <span className="worli-odd">8</span>
                    <span>{getExposureForEntity("8")?.exposure}</span>
                  </div>{" "}
                  <div
                    onClick={() => setEvent("9")}
                    className="worli-odd-box back"
                  >
                    <span className="worli-odd">9</span>
                    <span>{getExposureForEntity("9")?.exposure}</span>
                  </div>{" "}
                  <div
                    onClick={() => setEvent("0")}
                    className="worli-odd-box back"
                  >
                    <span className="worli-odd">0</span>
                    <span>{getExposureForEntity("0")?.exposure}</span>
                  </div>
                </div>
              </div>{" "}
              <div className="worli-right">
                <div className="worli-box-title">
                  <b>9</b>
                </div>{" "}
                <div className="worli-box-row">
                  <div
                    onClick={() => setEvent("Line 1")}
                    className="worli-odd-box back"
                  >
                    <span className="worli-odd">LINE 1</span>{" "}
                    <span className="d-block">1|2|3|4|5</span>
                    <span>{getExposureForEntity("Line 1")?.exposure}</span>
                  </div>{" "}
                  <div
                    onClick={() => setEvent("Odd")}
                    className="worli-odd-box back"
                  >
                    <span className="worli-odd">ODD</span>{" "}
                    <span className="d-block">1|3|5|7|9</span>
                    <span>{getExposureForEntity("Odd")?.exposure}</span>
                  </div>
                </div>{" "}
                <div className="worli-box-row">
                  <div
                    onClick={() => setEvent("Line 2")}
                    className="worli-odd-box back"
                  >
                    <span className="worli-odd">LINE 2</span>{" "}
                    <span className="d-block">6|7|8|9|0</span>
                    <span>{getExposureForEntity("Line 2")?.exposure}</span>
                  </div>{" "}
                  <div
                    onClick={() => setEvent("Even")}
                    className="worli-odd-box back"
                  >
                    <span className="worli-odd">EVEN</span>{" "}
                    <span className="d-block">2|4|6|8|0</span>
                    <span>{getExposureForEntity("Even")?.exposure}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <Grid templateColumns="repeat(2,1fr)">
          <GridItem>
            <Text textAlign="center" fontWeight="bold">
              9
            </Text>
            <Grid templateColumns="repeat(5,2fr)">
              <GridItem
                border="2px"
                color="#72bbef"
                bg="#72bbef40"
                height="52px"
              >
                <Text
                  size="lg"
                  textAlign="center"
                  fontWeight="bold"
                  textColor="#d7d7d7"
                >
                  0
                </Text>
              </GridItem>
              <GridItem
                border="2px"
                color="#72bbef"
                bg="#72bbef40"
                height="52px"
              >
                <Text
                  size="lg"
                  textAlign="center"
                  fontWeight="bold"
                  textColor="#d7d7d7"
                >
                  1
                </Text>
              </GridItem>
              <GridItem
                border="2px"
                color="#72bbef"
                bg="#72bbef40"
                height="52px"
              >
                <Text
                  size="lg"
                  textAlign="center"
                  fontWeight="bold"
                  textColor="#d7d7d7"
                >
                  2
                </Text>
              </GridItem>
              <GridItem
                border="2px"
                color="#72bbef"
                bg="#72bbef40"
                height="52px"
              >
                <Text
                  size="lg"
                  textAlign="center"
                  fontWeight="bold"
                  textColor="#d7d7d7"
                >
                  3
                </Text>
              </GridItem>
              <GridItem
                border="2px"
                color="#72bbef"
                bg="#72bbef40"
                height="52px"
              >
                <Text
                  size="lg"
                  textAlign="center"
                  fontWeight="bold"
                  textColor="#d7d7d7"
                >
                  4
                </Text>
              </GridItem>
              <GridItem
                border="2px"
                color="#72bbef"
                bg="#72bbef40"
                height="52px"
              >
                <Text
                  size="lg"
                  textAlign="center"
                  fontWeight="bold"
                  textColor="#d7d7d7"
                >
                  5
                </Text>
              </GridItem>
              <GridItem
                border="2px"
                color="#72bbef"
                bg="#72bbef40"
                height="52px"
              >
                <Text
                  size="lg"
                  textAlign="center"
                  fontWeight="bold"
                  textColor="#d7d7d7"
                >
                  6
                </Text>
              </GridItem>
              <GridItem
                border="2px"
                color="#72bbef"
                bg="#72bbef40"
                height="52px"
              >
                <Text
                  size="lg"
                  textAlign="center"
                  fontWeight="bold"
                  textColor="#d7d7d7"
                >
                  7
                </Text>
              </GridItem>
              <GridItem
                border="2px"
                color="#72bbef"
                bg="#72bbef40"
                height="52px"
              >
                <Text
                  size="lg"
                  textAlign="center"
                  fontWeight="bold"
                  textColor="#d7d7d7"
                >
                  8
                </Text>
              </GridItem>
              <GridItem
                border="2px"
                color="#72bbef"
                bg="#72bbef40"
                height="52px"
              >
                <Text
                  size="lg"
                  textAlign="center"
                  fontWeight="bold"
                  textColor="#d7d7d7"
                >
                  9
                </Text>
              </GridItem>
            </Grid>
          </GridItem>
          <GridItem>
            <Text textAlign="center" fontWeight="bold">
              9
            </Text>
            <Grid templateColumns="repeat(2,2fr)" size="lg">
              <GridItem border="2px" color="#72bbef" bg="#72bbef40">
                <Text
                  size="lg"
                  textAlign="center"
                  fontWeight="bold"
                  textColor="#d7d7d7"
                >
                  LINE 1
                </Text>
                <Text textAlign="center" textColor="#d7d7d7">
                  1|2|3|4|5
                </Text>
              </GridItem>
              <GridItem border="2px" color="#72bbef" bg="#72bbef40">
                <Text
                  size="lg"
                  textAlign="center"
                  fontWeight="bold"
                  textColor="#d7d7d7"
                >
                  ODD
                </Text>
                <Text textAlign="center" textColor="#d7d7d7">
                  1|3|5|7|9
                </Text>
              </GridItem>
              <GridItem border="2px" color="#72bbef" bg="#72bbef40">
                <Text
                  size="lg"
                  textAlign="center"
                  fontWeight="bold"
                  textColor="#d7d7d7"
                >
                  LINE 2
                </Text>
                <Text textAlign="center" textColor="#d7d7d7">
                  6|7|8|9|0
                </Text>
              </GridItem>
              <GridItem border="2px" color="#72bbef" bg="#72bbef40">
                <Text
                  size="lg"
                  textAlign="center"
                  fontWeight="bold"
                  textColor="#d7d7d7"
                >
                  EVEN
                </Text>
                <Text textAlign="center" textColor="#d7d7d7">
                  2|4|6|8|0
                </Text>
              </GridItem>
            </Grid>
          </GridItem>
        </Grid> */}
        <LastResults eventId={gameName} />
      </Box>

      <PlaceBet game="casino" eventId={gameName} />
    </Box>
  );
}
