import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Spacer,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  WrapItem,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaLock } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Flip, Flipr } from "../../../components/Flip";
import {
  getCasinoPlExposureAPI,
  placeCasinoBetAPI,
} from "../../../service/casino";
import PlaceBet from "../../game/PlaceBet";
import CardDrawer from "../../../components/CardDrawer/CardDrawer";
import { ASSETS_URL } from "../../../utils/constants";
import { useMemo } from "react";
import Iframe from "react-iframe";
import IframeComponent from "../../../components/Iframe";
import CasinoVideoRules from "../../../components/CasinoVideoRules";
import LastResults from "../../../components/LastResults";
import Popover from "../../../components/Popover";
import { setGameKey } from "../../../redux/features/gameSlice";
import { getService } from "../../../utils/commonFunctions";
import { useLocation } from "react-router-dom";

export default function DragonTiger1Day() {
  const location = useLocation();
  const odds_ = useSelector((state) => state.gameData.dragonTigerOneDayOdds);
  const [showMinMax, setShowMinMax] = useState(false);
  //useMemo
  const odds = useMemo(() => odds_, [odds_]);
  const authDetails = useSelector((state) => state.account.authDetails);
  const [plExposure, setPlExposure] = useState(null);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [isLocked, setIsLocked] = useState(false);
  const userData = useSelector((state) => state.account.userData);
  const toast = useToast();
  const dispatch = useDispatch();
  const gameName = "DRAGON_TIGER_6";
  const Card = [
    {
      id: "Tiger Card 1",
      img: "spade.png",
    },
    {
      id: "Tiger Card 1",
      img: "heart.png",
    },
    {
      id: "Tiger Card 1",
      img: "club.png",
    },
    {
      id: "Tiger Card 1",
      img: "diamond.png",
    },
  ];

  const CustomButton = ({ text, item, size, children, ...rest }) => (
    <Button
      size={size ? size : "sm"}
      my="2"
      display="flex"
      justifyContent="center"
      alignItems="center"
      className="pattiButton"
      onClick={isLocked ? null : rest.onClick}
      flexDirection="column"
    >
      {isLocked ? <FaLock /> : text}
      {!isLocked && <Text className="expos-text">{children}</Text>}
    </Button>
  );
  const CustomButtonPink = ({ text, item, size, ...rest }) => (
    <Button
      size={size ? size : "sm"}
      my="2"
      display="flex"
      justifyContent="center"
      alignItems="center"
      className="casinoButton-pink"
      onClick={isLocked ? null : rest.onClick}
    >
      {isLocked ? <FaLock /> : item ? item : text}
    </Button>
  );
  const getData = (id) => {
    let data = odds?.oddDetailsDTOS?.find((item) => item.runnerName == id);
    return data ? data : {};
  };

  useEffect(() => {
    dispatch(setGameKey("dragonTigerOneDay"));
  }, [dispatch]);

  const getProfit = (size) => {
    return (size - 1) * selectedAmount;
  };
  const setEvent = (id, isBack = true) => {
    if (isLocked) return;
    let selectedEventForBet = getData(id);
    let payload = {};
    payload.amount = parseInt(selectedAmount);
    payload.casinoGames = gameName;
    payload.profit = getProfit(
      isBack ? selectedEventForBet.back1 : selectedEventForBet.lay1
    );
    payload.loss = parseInt(selectedAmount);
    payload.selectedOdd = isBack
      ? selectedEventForBet.back1
      : selectedEventForBet.lay1;
    payload.selectedId = selectedEventForBet.selectionId;
    payload.marketId = odds.marketId;
    payload.runnerName = selectedEventForBet.runnerName;
    payload.type = isBack ? "back" : "lay";

    dispatch({
      type: "gameData/setSelectedEventForBet",
      payload,
    });
  };
  useEffect(() => {
    if (odds.autoTime <= 5) {
      setIsLocked(true);
    } else {
      setIsLocked(false);
    }
  }, [odds, getData]);
  const getPlExposure = async (id) => {
    const res = await getCasinoPlExposureAPI(
      getService(location.pathname).port,
      getService(location.pathname).service,
      id,
      authDetails?.uid
    );
    if (res && res?.code == 200) {
      setPlExposure(res.response);
    }
  };
  const getExposureForEntity = (selectionName, selectionId) => {
    if (plExposure) {
      let data;
      for (let i = 0; i < plExposure.length; i++) {
        data = plExposure[i]?.casinoPLExposure?.find(
          (item) => item.selectionName == selectionName
        );
        if (data) {
          return data;
        }
      }
      return data ? data : null;
    }
  };

  const toggleMinMax = () => {
    setShowMinMax(!showMinMax);
  };

  useEffect(() => {
    let interval = setInterval(() => {
      if (odds?.marketId) {
        getPlExposure(odds?.marketId);
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [odds?.marketId]);

  return (
    <Box
      display="flex"
      flexDirection={{ base: "column", sm: "column", lg: "row" }}
    >
      <Box mt="1" mr="1" w={{ base: "100%", sm: "100%", lg: "70%" }}>
        <Box className="casino-video" mb="2" pos="relative">
          {odds.cards && (
            <CardDrawer cards={[odds?.cards[0], odds?.cards[1]]} />
          )}
          <div className="market-title">DRAGON TIGER ONE DAY </div>

          <IframeComponent
            url={`${process.env.REACT_APP_IFRAME_URL}?id=3057`}
          />
          <Box
            bottom="0"
            p="2"
            pos="absolute"
            right="2"
            display="flex"
            bg="black"
          >
            <Flip value={odds?.autoTime} />
          </Box>

          <CasinoVideoRules />
        </Box>
        
        <div className="main-market">
          <div className="dragon-tiger-1day">
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div className="table-responsive dt-odds" style={{ flex: 1 }}>
                <table
                  className="table table-bordered mb-0 top-bbd-cd"
                  style={{
                    width: "100%",
                    height: "100%",
                    border: "1px solid",
                    borderColor: "#EEE",
                  }}
                >
                  <thead>
                    <tr>
                      <div className="box-4 min-max">
                        <Popover>
                          <div id="min-max-info" className="min-max-box">
                            <p>Min: 100</p>
                            <p>Max: 300000</p>
                          </div>
                        </Popover>
                      </div>
                      <th
                        className="box-3 text-center back"
                        style={{ color: "black", width: "30%" }}
                      >
                        BACK
                      </th>
                      <th
                        className="box-3 text-center lay"
                        style={{ color: "black", width: "30%" }}
                      >
                        LAY
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{ backgroundColor: "#eee" }}>
                    <tr>
                      <td className="box-4">
                        <b>Dragon</b>
                        <p className="mb-0" style={{ lineHeight: "18px" }}>
                          0
                        </p>
                      </td>
                      <td
                        className={`box-3 back text-center ${
                          isLocked ? "suspendedtd" : ""
                        }`}
                        onClick={() => setEvent("Dragon")}
                      >
                        <span className="odds d-block">
                          <b> {getData("Dragon").back1} </b>
                        </span>{" "}
                        <span className="expo-text">
                          {getExposureForEntity("Dragon")?.exposure}
                        </span>
                      </td>
                      <td
                        className={`box-3 lay text-center ${
                          isLocked ? "suspendedtd" : ""
                        }`}
                        onClick={() => setEvent("Dragon", false)}
                      >
                        <span className="odds d-block">
                          <b>{getData("Dragon").lay1}</b>
                        </span>{" "}
                        <span className="expo-text">
                          {getExposureForEntity("Dragon")?.exposure}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="box-4">
                        <b>Tiger</b>
                        <p className="mb-0" style={{ lineHeight: "18px" }}>
                          0
                        </p>
                      </td>
                      <td
                        className={`box-3 back text-center ${
                          isLocked ? "suspendedtd" : ""
                        }`}
                        onClick={() => setEvent("Tiger")}
                      >
                        <span className="odds d-block">
                          <b>{getData("Tiger").back1}</b>
                        </span>{" "}
                        <span className="expo-text">
                          {" "}
                          {getExposureForEntity("Tiger")?.exposure}
                        </span>
                      </td>
                      <td
                        className={`box-3 lay text-center ${
                          isLocked ? "suspendedtd" : ""
                        }`}
                        onClick={() => setEvent("Tiger", false)}
                      >
                        <span className="odds d-block">
                          <b>{getData("Tiger").lay1}</b>
                        </span>{" "}
                        <span className="expo-text">
                          {getExposureForEntity("Tiger")?.exposure}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                className="light-bg container-fluid  mx-2"
                style={{ flex: 1, backgroundColor: "#eee" }}
              >
                <div className="row row5">
                  <div className="col-12">
                    <div style={{ display: "flex", marginLeft: "45%" }}>
                      <p className="d-block mb-0 text-center">
                        <b>{getData("Pair").back1}</b>
                      </p>
                      <Spacer />
                      <div
                        className="box-4 min-max"
                        style={{ float: "right", marginLeft: "65%" }}
                      >
                        <Popover>
                          <div id="min-max-info" className="min-max-box">
                            <p>Min: 100</p>
                            <p>Max: 300000</p>
                          </div>
                        </Popover>
                      </div>
                    </div>

                    <button
                      className={`btn-theme mt-1 ${
                        isLocked ? "suspendedtd" : ""
                      }`}
                      onClick={() => setEvent("Pair")}
                    >
                      Pair
                    </button>
                    <p className="mt-1 mb-0 text-center">
                      <b className="expo-text">
                        {getExposureForEntity("Pair")?.exposure}
                      </b>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                className="table-responsive mt-2"
                style={{
                  marginRight: "10px",
                  border: "1px solid",
                  borderColor: "#EEE",
                }}
              >
                <table className="table table-bordered mb-0">
                  <thead>
                    <tr>
                      <th className="box-4 min-max">
                        {" "}
                        <Popover>
                          <div id="min-max-info" className="min-max-box">
                            <p>Min: 100</p>
                            <p>Max: 100000</p>
                          </div>
                        </Popover>
                      </th>
                      <th className="box-2 text-center back">Even</th>
                      <th className="box-2 text-center back">Odd</th>
                    </tr>
                  </thead>
                  <tbody style={{ backgroundColor: "#eee" }}>
                    <tr>
                      <td className="box-4">
                        <b>Dragon</b>
                      </td>
                      <td
                        className={`box-3 back text-center  ${
                          isLocked ? "suspendedtd" : ""
                        }`}
                        onClick={() => setEvent("Dragon Even")}
                        style={{ height: "50px" }}
                      >
                        <span className="odds d-block">
                          <b>{getData("Dragon Even").back1}</b>
                        </span>{" "}
                        <span className="expo-text">
                          {getExposureForEntity("Dragon Even")?.exposure}
                        </span>
                      </td>
                      <td
                        className={`box-3 back text-center  ${
                          isLocked ? "suspendedtd" : ""
                        }`}
                        onClick={() => setEvent("Dragon Odd")}
                        style={{ height: "50px" }}
                      >
                        <span className="odds d-block">
                          <b>{getData("Dragon Odd").back1}</b>
                        </span>{" "}
                        <span className="expo-text">
                          {getExposureForEntity("Dragon Odd")?.exposure}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="box-4">
                        <b>Tiger</b>
                      </td>
                      <td
                        className={`box-3 back text-center ${
                          isLocked ? "suspendedtd" : ""
                        }`}
                        onClick={() => setEvent("Tiger Even")}
                        style={{ height: "50px" }}
                      >
                        <span className="odds d-block">
                          <b>{getData("Tiger Even").back1}</b>
                        </span>{" "}
                        <span className="expo-text">
                          {getExposureForEntity("Tiger Even")?.exposure}
                        </span>
                      </td>
                      <td
                        className={`box-3 back text-center ${
                          isLocked ? "suspendedtd" : ""
                        }`}
                        onClick={() => setEvent("Tiger Odd")}
                        style={{ height: "50px" }}
                      >
                        <span className="odds d-block">
                          <b>{getData("Tiger Odd").back1}</b>
                        </span>{" "}
                        <span className="expo-text">
                          {getExposureForEntity("Tiger Odd")?.exposure}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                className="table-responsive mt-2"
                style={{ border: "1px solid", borderColor: "#EEE" }}
              >
                <table className="table table-bordered mb-0">
                  <thead>
                    <tr>
                      <th className="box-4 min-max">
                        {" "}
                        <Popover>
                          <div id="min-max-info" className="min-max-box">
                            <p>Min: 100</p>
                            <p>Max: 100000</p>
                          </div>
                        </Popover>
                      </th>
                      <th className="box-2 text-center back">
                        Red
                      </th>
                      <th className="box-2 text-center back">
                        Black
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{ backgroundColor: "#eee" }}>
                    <tr>
                      <td className="box-4">
                        <b>Dragon</b>
                      </td>
                      <td
                        className={`box-3 back text-center ${
                          isLocked ? "suspendedtd" : ""
                        }`}
                        onClick={() => setEvent("Dragon Red")}
                        style={{ height: "50px" }}
                      >
                        <span className="odds d-block">
                          <b> {getData("Dragon Red").back1}</b>
                        </span>{" "}
                        <span className="expo-text">
                          {getExposureForEntity("Dragon Red")?.exposure}
                        </span>
                      </td>
                      <td
                        className={`box-3 back text-center ${
                          isLocked ? "suspendedtd" : ""
                        }`}
                        onClick={() => setEvent("Dragon Black")}
                        style={{ height: "50px" }}
                      >
                        <span className="odds d-block">
                          <b> {getData("Dragon Black").back1}</b>
                        </span>{" "}
                        <span className="expo-text">
                          {" "}
                          {getExposureForEntity("Dragon Black")?.exposure}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="box-4">
                        <b>Tiger</b>
                      </td>
                      <td
                        className={`box-3 back text-center ${
                          isLocked ? "suspendedtd" : ""
                        }`}
                        onClick={() => setEvent("Tiger Red")}
                        style={{ height: "50px" }}
                      >
                        <span className="odds d-block">
                          <b>{getData("Tiger Red").back1}</b>
                        </span>{" "}
                        <span className="expo-text">
                          {getExposureForEntity("Tiger Red")?.exposure}
                        </span>
                      </td>
                      <td
                        className={`box-3 back text-center ${
                          isLocked ? "suspendedtd" : ""
                        }`}
                        onClick={() => setEvent("Tiger Black")}
                        style={{ height: "50px" }}
                      >
                        <span className="odds d-block">
                          <b>{getData("Tiger Black").back1}</b>
                        </span>{" "}
                        <span className="expo-text">
                          {getExposureForEntity("Tiger Black")?.exposure}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div
              className="table-responsive mt-2"
              style={{ border: "1px solid", borderColor: "#EEE" }}
            >
              <table className="table table-bordered mb-0">
                <thead>
                  <tr>
                    <th className="box-2 min-max">
                      <div className="info-block">
                        <Popover>
                          <div id="min-max-info" className="min-max-box">
                            <p>Min: 100</p>
                            <p>Max: 100000</p>
                          </div>
                        </Popover>
                      </div>
                    </th>
                    <th className="box-2 text-center back">
                      <span>
                        <span>Spade</span>
                      </span>
                    </th>
                    <th className="box-2 text-center back card-red">
                      <span>
                        <span>Heart</span>
                      </span>
                    </th>
                    <th className="box-2 text-center back">
                      <span>
                        <span>Club</span>
                      </span>
                    </th>
                    <th className="box-2 text-center back card-red">
                      <span>
                        <span>Diamond</span>
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody style={{ backgroundColor: "#eee" }}>
                  <tr>
                    <td className="box-2">
                      <b>Dragon</b>
                    </td>
                    <td
                      className={`box-2 back text-center ${
                        isLocked ? "suspendedtd" : ""
                      }`}
                      onClick={() => setEvent("Dragon Spade")}
                      style={{ height: "50px" }}
                    >
                      <span className="odds d-block">
                        <b>{getData("Dragon Spade").back1}</b>
                      </span>{" "}
                      <span className="expo-text">
                        {getExposureForEntity("Dragon Spade")?.exposure}
                      </span>
                    </td>
                    <td
                      className={`box-2 back text-center ${
                        isLocked ? "suspendedtd" : ""
                      }`}
                      onClick={() => setEvent("Dragon Heart")}
                      style={{ height: "50px" }}
                    >
                      <span className="odds d-block">
                        <b>{getData("Dragon Heart").back1}</b>
                      </span>{" "}
                      <span className="expo-text">
                        {getExposureForEntity("Dragon Heart")?.exposure}
                      </span>
                    </td>
                    <td
                      className={`box-2 back text-center ${
                        isLocked ? "suspendedtd" : ""
                      }`}
                      onClick={() => setEvent("Dragon Diamond")}
                      style={{ height: "50px" }}
                    >
                      <span className="odds d-block">
                        <b>{getData("Dragon Diamond").back1}</b>
                      </span>{" "}
                      <span className="expo-text">
                        {getExposureForEntity("Dragon Diamond")?.exposure}
                      </span>
                    </td>
                    <td
                      className={`box-2 back text-center ${
                        isLocked ? "suspendedtd" : ""
                      }`}
                      onClick={() => setEvent("Dragon Club")}
                      style={{ height: "50px" }}
                    >
                      <span className="odds d-block">
                        <b>{getData("Dragon Club").back1}</b>
                      </span>{" "}
                      <span className="expo-text">
                        {getExposureForEntity("Dragon Club")?.exposure}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="box-2">
                      <b>Tiger</b>
                    </td>
                    <td
                      className={`box-2 back text-center ${
                        isLocked ? "suspendedtd" : ""
                      }`}
                      onClick={() => setEvent("Tiger Spade")}
                      style={{ height: "50px" }}
                    >
                      <span className="odds d-block">
                        <b>{getData("Tiger Spade").back1}</b>
                      </span>{" "}
                      <span className="expo-text">
                        {getExposureForEntity("Tiger Spade")?.exposure}
                      </span>
                    </td>
                    <td
                      className={`box-2 back text-center ${
                        isLocked ? "suspendedtd" : ""
                      }`}
                      onClick={() => setEvent("Tiger Heart")}
                      style={{ height: "50px" }}
                    >
                      <span className="odds d-block">
                        <b>{getData("Tiger Heart").back1}</b>
                      </span>{" "}
                      <span className="expo-text">
                        {getExposureForEntity("Tiger Heart")?.exposure}
                      </span>
                    </td>
                    <td
                      className={`box-2 back text-center ${
                        isLocked ? "suspendedtd" : ""
                      }`}
                      onClick={() => setEvent("Tiger Diamond")}
                      style={{ height: "50px" }}
                    >
                      <span className="odds d-block">
                        <b>{getData("Tiger Diamond").back1}</b>
                      </span>{" "}
                      <span className="expo-text">
                        {getExposureForEntity("Tiger Diamond")?.exposure}
                      </span>
                    </td>
                    <td
                      className={`box-2 back text-center ${
                        isLocked ? "suspendedtd" : ""
                      }`}
                      onClick={() => setEvent("Tiger Club")}
                      style={{ height: "50px" }}
                    >
                      <span className="odds d-block">
                        <b>{getData("Tiger Club").back1}</b>
                      </span>{" "}
                      <span className="expo-text">
                        {getExposureForEntity("Tiger Club")?.exposure}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="remark text-right pr-2"></div>
          </div>
        </div>

        <LastResults eventId={gameName} />
      </Box>

      <PlaceBet game="casino" eventId={gameName} />
    </Box>
  );
}
