import React from "react";
import "./index.css";
import { Box } from "@chakra-ui/react";
export default function CasinoVideoHome() {
  const goBack = () => {
    window.history.back();
  };

  return (
    <Box className="casino-options">
      <div className="casino-video-right-icons">
        <div title="Home" className="casino-video-home-icon">
          <a
            // href="/live-casino"
            onClick={goBack}
            className="router-link-active"
            style={{ color: "var(--text-highlight)", cursor: "pointer" }}
          >
            <i className="fas fa-home" />
          </a>
        </div>{" "}
        
      </div>
     
    </Box>
  );
}
