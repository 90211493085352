import { AXIOS_INSTANCE } from '..';
import {
  BASE_URL,
  CRICKET_SERVICE_URL,
  SCORECARD_API_URL,
  SOCCER_AND_TENNIS_SERVICE_URL,
  TUNNEL_API_URL,
  getToken,
} from '../../utils/constants';

export const getAllCricketMatchListAPI = async () => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${CRICKET_SERVICE_URL}/cricket/all-matches/list`
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const placeCricketBetAPI = async (payload, token) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${CRICKET_SERVICE_URL}/bet/place`,
      {
        ...payload,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const placeFancyCricketBetAPI = async (payload, token) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${CRICKET_SERVICE_URL}/bet/fancy-place`,
      {
        ...payload,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getMyBetsCricketAPI = async (eventId, token) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${CRICKET_SERVICE_URL}/bet/my-bet/${eventId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getMatchDetailsCricketAPI = async (eventId, token) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${CRICKET_SERVICE_URL}/cricket/match-details`,
      {
        params: {
          eventId,
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const deleteCricketBetAPI = async (id) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${CRICKET_SERVICE_URL}/bet/bets/${id}/status?betStatus=DELETED`,
      {}
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getCricketEventDetailsAPI = async (eventId) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${CRICKET_SERVICE_URL}/cricket/event-data/${eventId}`
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getCricketInPlayMatches = async () => {
  try {
    const res = await AXIOS_INSTANCE.get(
      `${CRICKET_SERVICE_URL}/cricket/Inplay-matches`
    );
    return res;
  } catch (error) {
    return error;
  }
};
export const getTennisInPlayMatches = async () => {
  try {
    const res = await AXIOS_INSTANCE.get(
      `${SOCCER_AND_TENNIS_SERVICE_URL}/tennis/inplay-matches`
    );
    return res;
  } catch (error) {
    return error;
  }
};
export const getUpcomingMatches = async () => {
  try {
    const res = await AXIOS_INSTANCE.get(`${BASE_URL}/auth/upcoming-fixture`);
    return res;
  } catch (error) {
    return error;
  }
};
export const getCricketLiveMatches = async () => {
  try {
    const res = await AXIOS_INSTANCE.get(
      `${CRICKET_SERVICE_URL}/cricket/live-matches`
    );
    return res;
  } catch (error) {
    return error;
  }
};
export const getTennisSoccerLiveMatches = async (sport) => {
  try {
    const res = await AXIOS_INSTANCE.get(
      `${SOCCER_AND_TENNIS_SERVICE_URL}/${sport}/live-matches`
    );
    return res;
  } catch (error) {
    return error;
  }
};
export const getCricketScorecard = async (matchId) => {
  try {
    const res = await AXIOS_INSTANCE.get(
      `${TUNNEL_API_URL}/tunnel/original/cricket/score/${matchId}`
    );
    return res;
  } catch (error) {
    return error;
  }
};
