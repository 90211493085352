import {
  Flex,
  Box,
  Stack,
  Button,
  Text,
  HStack,
  PinInput,
  PinInputField,
  useToast,
} from "@chakra-ui/react";

import { useState, useEffect } from "react";
import { verifyUsernameAPI, sendOtpAPI, verifyOtpAPI, updatePasswordAPI } from "../service/AuthService";
import { useNavigate } from "react-router-dom";
import CustomInput from "../components/Input";
import Logo from "../assets/img/logo.svg";
import "./../components/login/login.scss";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Encrypt } from '../utils/constants';
import {REACT_APP_API_URL} from "../../src/"
 
export default function ForgotPassword() {
  const navigate = useNavigate();
  const toast = useToast();


  const [step, setStep] = useState(1);


  const [username, setUsername] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword1, setShowPassword1] = useState(false)
  const [showPassword2, setShowPassword2] = useState(false)

  const [seconds, setSeconds] = useState(300);
  const [resendEnabled, setResendEnabled] = useState(false);


  useEffect(() => {
    if (step === 2 && seconds > 0) {
      const timer = setTimeout(() => setSeconds(seconds - 1), 1000);
      return () => clearTimeout(timer);
    } else if (seconds === 0) {
      setResendEnabled(true);
    }
  }, [seconds, step]);

  const handleUsernameSubmit = async () => {
    const res = await verifyUsernameAPI(username);

    if (res.code === 200) {

      setStep(2);
      setSeconds(300);
      toast({
        title: "Success",
        description: res?.response,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Error",
        description: "Username not found.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  const handleUsernameSubmitResend = async () => {
    setOtp("")
    const res = await verifyUsernameAPI(username);

    if (res.code === 200) {

      setStep(2);
      setSeconds(300);
      toast({
        title: "Success",
        description: "OTP Resent Successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Error",
        description: "Some Erorr Occured",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleOtpSubmit = async () => {
    if (newPassword === '' || confirmPassword === '' || otp==='') {
      toast({
        description: 'Please fill all the fields',
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
      return;
    }
    if (newPassword !== confirmPassword) {
      toast({
        title: "Error",
        description: "Passwords do not match.",
        status: "error",
        duration: 9000,
        isClosable: true,

      });
      return;
    }
      
    if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
        newPassword
      )
    ) {
      toast({
        description:
          'Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special character',
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
      return;
    }
    const data = {
      username,
      otp,
      password:Encrypt(newPassword)
    }
    const res = await verifyOtpAPI(data);
    
    if (res.code === 200) {
      navigate("/");
      // setStep(3);
      toast({
        title: "Success",
        description: "Password Recovered Successfully, Please Login",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Error",
        description: res.response,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handlePasswordSubmit = async () => {

    if (newPassword === '' || confirmPassword === '') {
      toast({
        description: 'Please fill all the fields',
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
      return;
    }
    if (newPassword !== confirmPassword) {
      toast({
        title: "Error",
        description: "Passwords do not match.",
        status: "error",
        duration: 9000,
        isClosable: true,

      });
      return;
    }
      
    if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
        newPassword
      )
    ) {
      toast({
        description:
          'Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special character',
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
      return;
    }
    const res = await updatePasswordAPI({ username, newPassword });

    if (res.code === 200) {
      navigate("/login");
      toast({
        title: "Success",
        description: "Password updated successfully!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Error",
        description: "Something went wrong, please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleResendOtp = async () => {
    if (resendEnabled) {
      const res = await sendOtpAPI({ username });
      if (res.code === 200) {
        setSeconds(300);
        setResendEnabled(false);
        toast({
          title: "OTP Sent",
          description: "A new OTP has been sent to your mobile number.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: "Failed to resend OTP. Please try again later.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <div className="login">
      <Flex minH={"100vh"} align={"center"} justify={"center"}>
        <Stack spacing={2} fontSize="sm" mx={"auto"} maxW={"422px"}>
          <Stack align={"center"} style={{ marginTop: "20px" }}>
            <img className="logo" alt="logo" src={Logo} />
          </Stack>
          <Box
            rounded={"lg"}
            bg="white"
            boxShadow={"lg"}
            p={8}
            textAlign="center"
          >
            <Text className="login-text" fontSize="lg" fontWeight="bold" mb={4}>
              {step === 1
                ? "Enter Username"
                : step === 2
                  ? "OTP Verification"
                  : "Reset Password"}
            </Text>
            <Stack spacing={4}>
              {step === 1 && (
                <CustomInput
                  type="text"
                  label="Username"
                  placeholder="Enter Username"
                  name="username"
                  onChange={(e) => setUsername(e.target.value)}
                />
              )}

              {step === 2 && (
                <>
                
                  <Text fontSize="sm" color="gray.600" mb={4}>
                    Your OTP was sent to your registered mobile number.
                  </Text>

                    <CustomInput
                    type={showPassword1 ? "text" : "password"}
                    label="New Password"
                    placeholder={"New Password"}
                    name="newPassword"
                    onChange={(e) => setNewPassword(e.target.value)}
                    icon={showPassword1 ? <FaEyeSlash onClick={() => setShowPassword1(false)} /> : <FaEye onClick={() => setShowPassword1(true)} />}
                    iconPosition="right"
                  />

                  <CustomInput
                    type={showPassword2 ? "text" : "password"}
                    label="Confirm Password"
                    placeholder="Confirm Password"
                    name="confirmPassword"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    icon={showPassword2 ? <FaEyeSlash onClick={() => setShowPassword2(false)} /> : <FaEye onClick={() => setShowPassword2(true)} />}
                    iconPosition="right"
                  />

                  <HStack justify="center" mb={1} spacing={2} >
                    <PinInput
                      otp
                      size="lg"
                      onChange={(value) => setOtp(value)}
                      focusBorderColor="blue.500"
                    >
                      <PinInputField
                        width="40px"
                        height="40px"
                        fontSize="lg"
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.300"
                        backgroundColor="#E9E9E9"
                        borderRadius="5px"
                        _focus={{ borderColor: "blue.500" }}
                        _placeholder={{ color: "transparent" }}
                        marginRight="15px"
                      />
                      <PinInputField
                        width="40px"
                        height="40px"
                        fontSize="lg"
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.300"
                        backgroundColor="#E9E9E9"
                        borderRadius="5px"
                        _focus={{ borderColor: "blue.500" }}
                        _placeholder={{ color: "transparent" }}
                        marginRight="8px"
                      />
                      <PinInputField
                        width="40px"
                        height="40px"
                        fontSize="lg"
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.300"
                        backgroundColor="#E9E9E9"
                        borderRadius="5px"
                        _focus={{ borderColor: "blue.500" }}
                        _placeholder={{ color: "transparent" }}
                        marginRight="8px"
                      />
                      <PinInputField
                        width="40px"
                        height="40px"
                        fontSize="lg"
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.300"
                        backgroundColor="#E9E9E9"
                        borderRadius="5px"
                        _focus={{ borderColor: "blue.500" }}
                        _placeholder={{ color: "transparent" }}
                        marginRight="8px"
                      />
                      <PinInputField
                        width="40px"
                        height="40px"
                        fontSize="lg"
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.300"
                        backgroundColor="#E9E9E9"
                        borderRadius="5px"
                        _focus={{ borderColor: "blue.500" }}
                        _placeholder={{ color: "transparent" }}
                        marginRight="8px"
                      />
                      <PinInputField
                        width="40px"
                        height="40px"
                        fontSize="lg"
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.300"
                        backgroundColor="#E9E9E9"
                        borderRadius="5px"
                        _focus={{ borderColor: "blue.500" }}
                        _placeholder={{ color: "transparent" }}
                        marginRight="8px"
                      />
                    </PinInput>
                  </HStack>
                  <Flex justifyContent="space-between">
                  <Text fontSize="14px" style={{ color: "red", fontWeight: "bold" }} textAlign="left" marginLeft="20px">
                  {seconds > 0 ? `${Math.floor(seconds / 60)}:${seconds % 60 < 10 ? `0${seconds % 60}` : seconds % 60} min` : ""}
                  </Text>

                 <Text
                  fontSize="14px"
                  fontWeight="bold"
                  style={resendEnabled ? { color: "blue" } : { color: "gray" }}
                  cursor={resendEnabled ? "pointer" : "not-allowed"}
                  onClick={handleUsernameSubmitResend}
                  textAlign="right"
                  marginRight="20px"
                >
                  Resend OTP
                </Text>
                </Flex>
                </>
              )}

              {step === 3 && (
                <>
                  <CustomInput
                    type={showPassword1 ? "text" : "password"}
                    label="New Password"
                    placeholder={"New Password"}
                    name="newPassword"
                    onChange={(e) => setNewPassword(e.target.value)}
                    icon={showPassword1 ? <FaEyeSlash onClick={() => setShowPassword1(false)} /> : <FaEye onClick={() => setShowPassword1(true)} />}
                    iconPosition="right"
                  />

                  <CustomInput
                    type={showPassword2 ? "text" : "password"}
                    label="Confirm Password"
                    placeholder="Confirm Password"
                    name="confirmPassword"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    icon={showPassword2 ? <FaEyeSlash onClick={() => setShowPassword2(false)} /> : <FaEye onClick={() => setShowPassword2(true)} />}
                    iconPosition="right"
                  />
                </>
              )}

              <Stack spacing={6} mt={6}>
                <Button
                  colorScheme="blue"
                  borderRadius={'10px'}
                  height={'50px'}
                  onClick={
                    step === 1
                      ? handleUsernameSubmit
                      : step === 2
                        ? handleOtpSubmit
                        : handlePasswordSubmit
                  }
                  width="full"
                  size="lg"
                >
                  {step === 1
                    ? "SUBMIT"
                    : step === 2
                      ? "SUBMIT"
                      : "SUBMIT"}
                </Button>
                
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Flex>
    </div>
  );
}




// 1.⁠ ⁠Under "Direct User," the "Phone" and "Full Name" fields need to be corrected.
// 2.⁠ ⁠In the account statement, the placement of the "From/To" and "Remark" columns needs to be switched.
// 3.⁠ ⁠In "Send Money," the placement of the "Remark" and "Password" fields needs to be switched, and the 'R' in "Regenerate Captcha" should be capitalized.
// 4.⁠ ⁠In "Withdrawal," "INR" needs to be removed from the placeholder.
// 5.⁠ ⁠The payment type needs to be updated in the admin panel when making a deposit in U




// http://165.232.180.102:8000/swagger-ui/#/user-controller/sendOtpUsingPOST
// https://api.betcricks.com/auth/api/user/sendOtp?username=TEST2