import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  WrapItem,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaLock } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Flip, Flipr } from "../../../components/Flip";
import {
  getCasinoPlExposureAPI,
  placeCasinoBetAPI,
} from "../../../service/casino";
import PlaceBet from "../../game/PlaceBet";
import CardDrawer from "../../../components/CardDrawer/CardDrawer";
import { useMemo } from "react";
import Iframe from "react-iframe";
import IframeComponent from "../../../components/Iframe";
import CasinoVideoRules from "../../../components/CasinoVideoRules";
import LastResults from "../../../components/LastResults";
import { setGameKey } from "../../../redux/features/gameSlice";
import { getService } from "../../../utils/commonFunctions";
import { useLocation } from "react-router-dom";

export default function Poker20() {
  const location = useLocation();
  const odds_ = useSelector((state) => state.gameData.poker20Odds);
  //useMemo
  const odds = useMemo(() => odds_, [odds_]);
  const [plExposure, setPlExposure] = useState(null);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [isLocked, setIsLocked] = useState(false);
  const userData = useSelector((state) => state.account.userData);
  const toast = useToast();
  const dispatch = useDispatch();
  const gameName = "POKER_20";
  const CustomButton = ({ text }) => (
    <Button size="sm" my="2" className="casinoButton">
      {isLocked ? <FaLock /> : text}
    </Button>
  );
  const getData = (id, ifLeft = true) => {
    let data = odds?.oddDetailsDTOS?.find(
      (item) =>
        String(item.selectionId).startsWith(ifLeft ? "1" : "2") &&
        item.runnerName == id
    );
    return data ? data : {};
  };

  const getProfit = (selectedEventForBet) => {
    return (selectedEventForBet.back1 - 1) * selectedAmount;
  };
  const setEvent = (id, ifLeft = true) => {
    if (isLocked) return;
    let selectedEventForBet = getData(id, ifLeft);

    let payload = {};
    payload.amount = parseInt(selectedAmount);
    payload.casinoGames = gameName;
    payload.profit = getProfit(selectedEventForBet);
    payload.loss = parseInt(selectedAmount);
    payload.selectedOdd = selectedEventForBet.back1;
    payload.selectedId = selectedEventForBet.selectionId;
    payload.marketId = odds.marketId;
    payload.runnerName = selectedEventForBet.runnerName;
    payload.type = "back";

    dispatch({
      type: "gameData/setSelectedEventForBet",
      payload,
    });
  };
  useEffect(() => {
    if (odds.autoTime <= 5) {
      setIsLocked(true);
    } else {
      setIsLocked(false);
    }
  }, [odds, getData]);
  const getPlExposure = async (id) => {
    const res = await getCasinoPlExposureAPI(
      getService(location.pathname).port,
      getService(location.pathname).service,
      id
    );
    if (res && res?.code == 200) {
      setPlExposure(res.response);
    }
  };
  const getExposureForEntity = (selectionName, ifLeft = true) => {
    if (plExposure) {
      let data;
      let selectionId_;
      if (ifLeft) {
        selectionId_ = odds?.oddDetailsDTOS?.find(
          (item) =>
            String(item.selectionId).startsWith("1") &&
            item.runnerName == selectionName
        )?.selectionId;
      } else {
        selectionId_ = odds?.oddDetailsDTOS?.find(
          (item) =>
            String(item.selectionId).startsWith("2") &&
            item.runnerName == selectionName
        )?.selectionId;
      }
      for (let i = 0; i < plExposure.length; i++) {
        let data = plExposure[i]?.casinoPLExposure?.find(
          (item) => item.selectionId == selectionId_
        );
        if (data) {
          return data;
        }
      }
      return data ? data : null;
    }
  };
  // const getExposureForEntity = (selectionName, selectionId) => {
  //   if (plExposure) {
  //     let data;
  //     for (let i = 0; i < plExposure.length; i++) {
  //       let data = plExposure[i]?.casinoPLExposure?.find(
  //         (item) => item.selectionName == selectionName
  //       );
  //       if (data) {
  //         return data;
  //       }
  //     }
  //     return data ? data : null;
  //   }
  // };

  useEffect(() => {
    dispatch(setGameKey("poker20"));
  }, [dispatch]);

  useEffect(() => {
    let interval = setInterval(() => {
      if (odds?.marketId) {
        getPlExposure(odds?.marketId);
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [odds?.marketId]);
  return (
    <Box
      display="flex"
      flexDirection={{ base: "column", sm: "column", lg: "row" }}
    >
      <Box mr="1" w={{ base: "100%", sm: "100%", lg: "70%" }}>
        <Box h="60vh" mb="2" pos="relative" className="casino-video">
          {odds.cards && (
            <CardDrawer
              custom={
                <div className="multilevel-wrapper">
                  <Flex>
                    <Box>
                      <Box
                        textAlign="left"
                        className="casino-video-cards-label"
                      >
                        PLAYER A
                      </Box>
                      <Flex>
                        {odds?.cards[0].map((card, index) => (
                          <span>
                            <img
                              className="casino-card-img"
                              src={require(`../../../assets/img/casino/${card}.png`)}
                            />
                          </span>
                        ))}
                      </Flex>
                    </Box>
                    <Box ml="50px">
                      <Box
                        textAlign="left"
                        className="casino-video-cards-label"
                      >
                        PLAYER B
                      </Box>
                      <Flex>
                        {odds?.cards[1].map((card, index) => (
                          <span>
                            <img
                              className="casino-card-img"
                              src={require(`../../../assets/img/casino/${card}.png`)}
                            />
                          </span>
                        ))}
                      </Flex>
                    </Box>
                  </Flex>
                  <Box mt="2" mr="auto">
                    <Box textAlign="left" className="casino-video-cards-label">
                      BOARD
                    </Box>
                    <Flex mt="1">
                      {odds?.cards[2].map((card, index) => (
                        <span>
                          <img
                            className="casino-card-img"
                            src={require(`../../../assets/img/casino/${card}.png`)}
                          />
                        </span>
                      ))}
                    </Flex>
                  </Box>
                </div>
              }
              multiLevel={true}
            />
          )}
          <div className="market-title">20-20 Poker</div>

          <IframeComponent
            url={`${process.env.REACT_APP_IFRAME_URL}?id=3052`}
          />
          <Box
            bottom="0"
            p="2"
            pos="absolute"
            right="2"
            display="flex"
            bg="black"
          >
            <Flip value={odds?.autoTime} />
          </Box>
          <CasinoVideoRules
            custom={
              <div className="rules-body">
                <img
                  src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/front/img/rules/poker-rules.jpeg"
                  className="rules-body"
                />
              </div>
            }
          />
        </Box>

        <div id="poker-table21" className="row">
          <div className="table-responsive col-md-6 m-b-10 main-market p-r-5">
            <div className="live-poker">
              <table className="table coupon-table table table-bordered ">
                <thead>
                  <tr style={{ backgroundColor: "#EEE" }}>
                    <th className="box-6"></th>
                    <th className="box-2 back">Player A</th>
                    <th className="box-2 back">Player B</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ backgroundColor: "#EEE" }}>
                    <td className="box-6">Winner</td>
                    <td
                      className={`box-2 back teen-section ${
                        isLocked ? "suspendedtd" : ""
                      }`}
                      onClick={() => setEvent("Winner")}
                    >
                      <button className="back">
                        <span className="odd">{getData("Winner").back1}</span>{" "}
                        <p>{getExposureForEntity("Winner")?.exposure}</p>
                      </button>
                    </td>
                    <td
                      className={`box-2 back teen-section ${
                        isLocked ? "suspendedtd" : ""
                      }`}
                      onClick={() => setEvent("Winner", false)}
                    >
                      <button className="back">
                        <span className="odd">
                          {getData("Winner", false).back1}
                        </span>{" "}
                        <p>{getExposureForEntity("Winner", false)?.exposure}</p>
                      </button>
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#EEE" }}>
                    <td className="box-6">One Pair</td>
                    <td
                      className={`box-2 back teen-section ${
                        isLocked ? "suspendedtd" : ""
                      }`}
                      onClick={() => setEvent("One Pair")}
                    >
                      <button className="back">
                        <span className="odd">{getData("One Pair").back1}</span>{" "}
                        <p>{getExposureForEntity("One Pair")?.exposure}</p>
                      </button>
                    </td>
                    <td
                      className={`box-2 back teen-section ${
                        isLocked ? "suspendedtd" : ""
                      }`}
                      onClick={() => setEvent("One Pair", false)}
                    >
                      <button className="back">
                        <span className="odd">
                          {getData("One Pair", false).back1}
                        </span>{" "}
                        <p>
                          {getExposureForEntity("One Pair", false)?.exposure}
                        </p>
                      </button>
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#EEE" }}>
                    <td className="box-6">Two Pair</td>
                    <td
                      className={`box-2 back teen-section ${
                        isLocked ? "suspendedtd" : ""
                      }`}
                      onClick={() => setEvent("Two Pair")}
                    >
                      <button className="back">
                        <span className="odd">{getData("Two Pair").back1}</span>{" "}
                        <p>{getExposureForEntity("Two Pair")?.exposure}</p>
                      </button>
                    </td>
                    <td
                      className={`box-2 back teen-section ${
                        isLocked ? "suspendedtd" : ""
                      }`}
                      onClick={() => setEvent("Two Pair", false)}
                    >
                      <button className="back">
                        <span className="odd">
                          {getData("Two Pair", false).back1}{" "}
                        </span>{" "}
                        <p>
                          {getExposureForEntity("Two Pair", false)?.exposure}
                        </p>
                      </button>
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#EEE" }}>
                    <td className="box-6">Three of a Kind</td>
                    <td
                      className={`box-2 back teen-section ${
                        isLocked ? "suspendedtd" : ""
                      }`}
                      onClick={() => setEvent("Three of a Kind")}
                    >
                      <button className="back">
                        <span className="odd">
                          {getData("Three of a Kind").back1}
                        </span>{" "}
                        <p>
                          {getExposureForEntity("Three of a Kind")?.exposure}
                        </p>
                      </button>
                    </td>
                    <td
                      className={`box-2 back teen-section ${
                        isLocked ? "suspendedtd" : ""
                      }`}
                      onClick={() => setEvent("Three of a Kind", false)}
                    >
                      <button className="back">
                        <span className="odd">
                          {getData("Three of a Kind", false).back1}
                        </span>{" "}
                        <p>
                          {
                            getExposureForEntity("Three of a Kind", false)
                              ?.exposure
                          }
                        </p>
                      </button>
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#EEE" }}>
                    <td className="box-6">Straight</td>
                    <td
                      className={`box-2 back teen-section ${
                        isLocked ? "suspendedtd" : ""
                      }`}
                      onClick={() => setEvent("Straight")}
                    >
                      <button className="back">
                        <span className="odd">{getData("Straight").back1}</span>{" "}
                        <p>{getExposureForEntity("Straight")?.exposure}</p>
                      </button>
                    </td>
                    <td
                      className={`box-2 back teen-section ${
                        isLocked ? "suspendedtd" : ""
                      }`}
                      onClick={() => setEvent("Straight", false)}
                    >
                      <button className="back">
                        <span className="odd">
                          {getData("Straight", false).back1}
                        </span>{" "}
                        <p>
                          {getExposureForEntity("Straight", false)?.exposure}
                        </p>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="table-responsive col-md-6 m-b-10 main-market p-l-5">
            <div className="live-poker">
              <table className="table coupon-table table table-bordered ">
                <thead>
                  <tr style={{ backgroundColor: "#EEE" }}>
                    <th className="box-6"></th>
                    <th className="box-2 back ">Player A</th>
                    <th className="box-2 back ">Player B</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ backgroundColor: "#EEE" }}>
                    <td className="box-6">Flush</td>
                    <td
                      className={`box-2 back teen-section ${
                        isLocked ? "suspendedtd" : ""
                      }`}
                      onClick={() => setEvent("Flush")}
                    >
                      <button className="back">
                        <span className="odd">{getData("Flush").back1}</span>{" "}
                        <p>{getExposureForEntity("Flush")?.exposure}</p>
                      </button>
                    </td>
                    <td
                      className={`box-2 back teen-section ${
                        isLocked ? "suspendedtd" : ""
                      }`}
                      onClick={() => setEvent("Flush", false)}
                    >
                      <button className="back">
                        <span className="odd">
                          {getData("Flush", false).back1}
                        </span>{" "}
                        <p>{getExposureForEntity("Flush", false)?.exposure}</p>
                      </button>
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#EEE" }}>
                    <td className="box-6">Full House</td>
                    <td
                      className={`box-2 back teen-section ${
                        isLocked ? "suspendedtd" : ""
                      }`}
                      onClick={() => setEvent("Full House")}
                    >
                      <button className="back">
                        <span className="odd">
                          {getData("Full House").back1}
                        </span>{" "}
                        <p>{getExposureForEntity("Full House")?.exposure}</p>
                      </button>
                    </td>
                    <td
                      className={`box-2 back teen-section ${
                        isLocked ? "suspendedtd" : ""
                      }`}
                      onClick={() => setEvent("Full House", false)}
                    >
                      <button className="back">
                        <span className="odd">
                          {getData("Full House", false).back1}
                        </span>{" "}
                        <p>
                          {getExposureForEntity("Full House", false)?.exposure}
                        </p>
                      </button>
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#EEE" }}>
                    <td className="box-6">Four of a Kind</td>
                    <td
                      className={`box-2 back teen-section ${
                        isLocked ? "suspendedtd" : ""
                      }`}
                      onClick={() => setEvent("Four of a Kind")}
                    >
                      <button className="back">
                        <span className="odd">
                          {getData("Four of a Kind").back1}
                        </span>{" "}
                        <p>
                          {getExposureForEntity("Four of a Kind")?.exposure}
                        </p>
                      </button>
                    </td>
                    <td
                      className={`box-2 back teen-section ${
                        isLocked ? "suspendedtd" : ""
                      }`}
                      onClick={() => setEvent("Four of a Kind", false)}
                    >
                      <button className="back">
                        <span className="odd">
                          {getData("Four of a Kind", false).back1}
                        </span>{" "}
                        <p>
                          {
                            getExposureForEntity("Four of a Kind", false)
                              ?.exposure
                          }
                        </p>
                      </button>
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#EEE" }}>
                    <td className="box-6">Straight Flush</td>
                    <td
                      className={`box-2 back teen-section ${
                        isLocked ? "suspendedtd" : ""
                      }`}
                      onClick={() => setEvent("Straight Flush")}
                    >
                      <button className="back">
                        <span className="odd">
                          {getData("Straight Flush").back1}
                        </span>{" "}
                        <p>
                          {getExposureForEntity("Straight Flush")?.exposure}
                        </p>
                      </button>
                    </td>
                    <td
                      className={`box-2 back teen-section ${
                        isLocked ? "suspendedtd" : ""
                      }`}
                      onClick={() => setEvent("Straight Flush", false)}
                    >
                      <button className="back">
                        <span className="odd">
                          {getData("Straight Flush", false).back1}
                        </span>{" "}
                        <p>
                          {
                            getExposureForEntity("Straight Flush", false)
                              ?.exposure
                          }
                        </p>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="casino-remark mt-1">
          <div className="remark-icon">
            <img
              src="https://png.pngtree.com/png-vector/20191129/ourlarge/pngtree-chat-icon-blue-vector--discussion-icon-png-image_2047530.jpg"
              alt="image"
            />
          </div>{" "}
          <marquee>
            In each round, player will be a winner from player A/B and from the
            remaining patterns, only high pattern will be considered as winner
            for each player | tie round will be considered as a cancel round.
          </marquee>
        </div>
        <LastResults eventId={gameName} />
      </Box>

      <PlaceBet game="casino" eventId={gameName} seperationRequired={true} />
    </Box>
  );
}
