import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box, Flex, Text, useControllableState } from "@chakra-ui/react";
import { BiFootball } from "react-icons/bi";
import { MdSportsCricket, MdSportsTennis } from "react-icons/md";
import left from "../../../src/assets/images/icons/left.svg";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Divider,
} from "@chakra-ui/react";
import { useState } from "react";
import { useEffect } from "react";
import { getAllCricketMatchListAPI } from "../../service/cricket";
import {
  getAllSoccerMatchListAPI,
  getAllTennisMatchListAPI,
} from "../../service/soccer&tennis";
import { FaHorse, FaPlusSquare } from "react-icons/fa";
import { PlusSquareIcon } from "@chakra-ui/icons";
import { pokerGameList, sidebarCasinoList } from "../../utils/constants";
import FavIcon from "../../components/Icons/Fav";
import KabaddiIcon from "../../components/Icons/Kabaddi";
import CricketMenuIcon from "../../components/Icons/CricketMenu";
import FootballMenuIcon from "../../components/Icons/FootballMenu";
import TennisMenuIcon from "../../components/Icons/TennisMenu";
import HourseRacingIcon from "../../components/Icons/HourseRacing";
import CardGameMenuIcon from "../../components/Icons/CardGameMenu";
import SportsBookIcon from "../../components/Icons/SportsBook";
import LiveCasinoIcon from "../../components/Icons/LiveCasino";
import { useDispatch, useSelector } from "react-redux";
import CricketActive from "../../components/Icons/CricketActive";
import BackIcon from "../../components/Icons/Back";
import HomeIcon from "../../../src/assets/img/Home.png";
import "./sidebar.css";

const AllSportsLinkItems = [
  { name: "cricket", icon: MdSportsCricket, isMap: false },
  { name: "soccer", icon: BiFootball, isMap: true },
  { name: "tennis", icon: MdSportsTennis, isMap: true },
];
const RacingLinkItems = [
  { name: "Horse racing", icon: FaHorse, isMap: true },
  { name: "Slot Game", icon: FaHorse, isMap: true },
  { name: "Live Casino", icon: FaHorse, isMap: true, link: "/live-casino" },
];

const Sidebar = ({ onClose, isSidebarOpen, setIsSidebarOpen }) => {
  const dispatch = useDispatch();
  const naviagte = useNavigate();
  const cricketData = useSelector((state) => state.gameData.cricketGames);
  const tennisData = useSelector((state) => state.gameData.tennisGames);
  const soccerData = useSelector((state) => state.gameData.soccerGames);
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [gameData, setGameData] = useState({
    cricket: [],
    soccer: [],
    tennis: [],
  });
  const handleAccordionClick = (accordionName) => {
    setActiveAccordion(
      activeAccordion === accordionName ? null : accordionName
    );
  };

  const isAccordionActive = (accordionName) => {
    return activeAccordion === accordionName;
  };

  const getCricketData = async () => {
    const res = await getAllCricketMatchListAPI();
    if (res && res?.code === 200) {
      setGameData((prev) => ({ ...prev, cricket: res.response }));
    }
  };
  const getSoccerData = async () => {
    const res = await getAllSoccerMatchListAPI();
    if (res && res?.code === 200) {
      setGameData((prev) => ({ ...prev, soccer: res.response }));
      console.log(res.response, "game data Soccer");
      dispatch({
        type: "gameData/setSoccerGames",
        payload: res.response,
      });
    }
  };
  const getTennisData = async () => {
    const res = await getAllTennisMatchListAPI();
    if (res && res?.code === 200) {
      setGameData((prev) => ({ ...prev, tennis: res.response }));
      console.log(res.response, "game data Tennis");
      dispatch({
        type: "gameData/setTennisGames",
        payload: res.response,
      });
    }
  };

  useEffect(() => {
    getCricketData();
    getTennisData();
    getSoccerData();
    return () => setIsSidebarOpen(false);
  }, []);

  const navigateToGame = (id, gameEvent) => {
    setIsSidebarOpen(false);
    naviagte(
      `/game/${id}/${gameEvent[id + "Id"]}?gameId=${
        gameEvent.gameId || gameEvent.eventId
      }`,
      {
        state: {
          gameEvent: {
            ...gameEvent,
            eventTime: gameEvent.eventDate
              ? gameEvent.eventDate
              : gameEvent.eventTime
              ? gameEvent.eventTime
              : gameEvent.startDate,
          },
        },
      }
    );
  };

  const [isCricketAccordionOpen, setIsCricketAccordionOpen] = useState(false);
  const [isFootballAccordionOpen, setIsFootballAccordionOpen] = useState(false);
  const [isTennisAccordionOpen, setIsTennisAccordionOpen] = useState(false);
  const [value, setValue] = useControllableState({ defaultValue: 0 });

  console.log(gameData, "game data");

  return (
    <div className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
      <ul>
        {/* <li className="disabled-link" style={{
                    gap: "5px",
                  }}>
          <FavIcon />
          <a href="#">Favorites</a>
        </li> */}
        {/* 
        {(isCricketAccordionOpen ||
          isFootballAccordionOpen ||
          isTennisAccordionOpen) && (
          <li>
            <img
              src={HomeIcon}
              alt='Image'
              style={{ width: '15px', height: '15px' }}
            />
            <a href='/'>Sports</a>
          </li>
        )} */}

        {/* Accordion for the Cricket  */}
        {/* {!isTennisAccordionOpen && !isFootballAccordionOpen && ( */}
        <li>
          <Accordion allowToggle>
            <AccordionItem>
              <h2>
                <AccordionButton
                  onClick={() =>
                    setIsCricketAccordionOpen(!isCricketAccordionOpen)
                  }
                  style={{
                    gap: "30px",
                  }}
                  // className={isCricketAccordionOpen ? 'active' : ''}
                >
                  {/* {isCricketAccordionOpen ? (
                      <CricketActive />
                    ) : ( */}
                  <CricketMenuIcon />
                  {/* )} */}

                  <Box
                    flex="1"
                    textAlign="left"
                    fontSize={"14px"}
                    fontFamily={"'Inter',Helvetica"}
                    fontWeight={400}
                    // color={isCricketAccordionOpen ? 'white' : 'black'}
                    // bgColor={isCricketAccordionOpen ? '#102A6D' : 'white'}
                    onClick={() => {
                      if (isCricketAccordionOpen) {
                        setIsCricketAccordionOpen(false);
                        naviagte("/");
                      } else {
                        setIsCricketAccordionOpen(true);
                        naviagte("/games/cricket");
                      }
                    }}
                  >
                    Cricket
                  </Box>
                </AccordionButton>
              </h2>
              {/* <AccordionPanel>
                  {isCricketAccordionOpen && (
                    <Flex
                      onClick={() => {
                        window.location.href = '/';
                      }}
                      align='center'
                      gap='23px'
                      mt='20px'
                      cursor='pointer'
                    >
                      <Box className='back-cta'>
                        <BackIcon />
                      </Box>
                      Previous
                    </Flex>
                  )}
                  {cricketData.map((game, index) => (
                    <React.Fragment key={game.id}>
                      <Link
                        className='link-side'
                        to={`/game/cricket/${game.cricketId}?gameId=${
                          game.gameId || game.eventId
                        }`}
                        onClick={() => navigateToGame(game.id, game)}
                      >
                        <Box display='flex' justifyContent={'space-between'}>
                          <Text
                            style={{
                              fontFamily: "'Inter',Helvetica",
                              fontSize: '11px',
                              fontWeight: 400,
                              marginLeft: '4px',
                            }}
                          >
                            {game.eventName}
                          </Text>
                          <img
                            src={left}
                            alt=''
                            style={{ width: '10px', height: '10px' }}
                          />
                        </Box>
                      </Link>
                      {index !== gameData.cricket.length - 1 && (
                        <Divider
                          style={{
                            borderBottom: '1px solid #DDDDDD',
                            width: '100%',
                          }}
                        />
                      )}
                    </React.Fragment>
                  ))}
                </AccordionPanel> */}
            </AccordionItem>
          </Accordion>
        </li>
        {/* )} */}
        {/* 
        {!isCricketAccordionOpen && (
          <> */}
        {/* Accordion for the Soocer */}
        <li>
          <Accordion allowToggle>
            <AccordionItem>
              <h2>
                <AccordionButton
                  onClick={() =>
                    setIsFootballAccordionOpen(!isFootballAccordionOpen)
                  }
                  style={{
                    gap: "30px",
                  }}
                  // className={isFootballAccordionOpen ? 'active' : ''}
                >
                  {/* {isFootballAccordionOpen ? (
                        <FootballMenuIcon />
                      ) : ( */}
                  <FootballMenuIcon />
                  {/* )} */}

                  <Box
                    flex="1"
                    textAlign="left"
                    fontSize={"14px"}
                    fontFamily={"'Inter',Helvetica"}
                    fontWeight={400}
                    // color={isFootballAccordionOpen ? 'white' : 'black'}
                    // bgColor={isFootballAccordionOpen ? '#102A6D' : 'white'}
                    onClick={() => {
                      if (isFootballAccordionOpen) {
                        setIsFootballAccordionOpen(false);
                        naviagte("/");
                      } else {
                        setIsFootballAccordionOpen(true);
                        naviagte("/games/soccer");
                      }
                    }}
                  >
                    Football
                  </Box>
                </AccordionButton>
              </h2>
              {/* <AccordionPanel>
                    {isFootballAccordionOpen && (
                      <Flex
                        onClick={() => {
                          window.location.href = '/';
                        }}
                        align='center'
                        gap='23px'
                        mt='20px'
                        cursor='pointer'
                      >
                        <Box className='back-cta'>
                          <BackIcon />
                        </Box>
                        Previous
                      </Flex>
                    )}
                    {Array.isArray(soccerData) &&
                      soccerData.map((group, index) => (
                        <React.Fragment key={index}>
                          {Array.isArray(group) ? (
                            group.map((game, gameIndex) => (
                              <Link
                                className='link-side'
                                to={`/game/soccer/${game.soccerId}?gameId=${
                                  game.gameId || game.eventId
                                }`}
                                onClick={() => navigateToGame(game.id, game)}
                                key={gameIndex}
                              >
                                <Box
                                  display='flex'
                                  justifyContent={'space-between'}
                                >
                                  <Text
                                    style={{
                                      fontFamily: "'Inter',Helvetica",
                                      fontSize: '11px',
                                      fontWeight: 400,
                                      marginLeft: '4px',
                                    }}
                                  >
                                    {game.eventName}
                                  </Text>
                                  <img
                                    src={left}
                                    alt=''
                                    style={{ width: '10px', height: '10px' }}
                                  />
                                </Box>
                              </Link>
                            ))
                          ) : (
                            <Link
                              className='link-side'
                              to={`/game/soccer/${group.soccerId}?gameId=${
                                group.gameId || group.eventId
                              }`}
                              onClick={() => navigateToGame(group.id, group)}
                              key={index}
                            >
                              <Box
                                display='flex'
                                justifyContent={'space-between'}
                              >
                                <Text
                                  style={{
                                    fontFamily: "'Inter',Helvetica",
                                    fontSize: '11px',
                                    fontWeight: 400,
                                    marginLeft: '4px',
                                  }}
                                >
                                  {group.eventName}
                                </Text>
                                <img
                                  src={left}
                                  alt=''
                                  style={{ width: '10px', height: '10px' }}
                                />
                              </Box>
                            </Link>
                          )}
                          {index !== gameData.soccer.length - 1 && (
                            <Divider
                              style={{
                                borderBottom: '1px solid #DDDDDD',
                                width: '100%',
                              }}
                            />
                          )}
                        </React.Fragment>
                      ))}
                  </AccordionPanel> */}
            </AccordionItem>
          </Accordion>
        </li>

        {/* {!isFootballAccordionOpen && (
              <> */}
        {/* Accordion for the Tennis */}
        <li>
          <Accordion allowToggle>
            <AccordionItem>
              <h2>
                <AccordionButton
                  onClick={() =>
                    setIsTennisAccordionOpen(!isTennisAccordionOpen)
                  }
                  style={{
                    gap: "30px",
                  }}
                  // className={isTennisAccordionOpen ? 'active' : ''}
                >
                  {/* {isTennisAccordionOpen ? (
                            <TennisMenuIcon />
                          ) : ( */}
                  <TennisMenuIcon />
                  {/* )} */}

                  <Box
                    flex="1"
                    textAlign="left"
                    fontSize={"14px"}
                    fontFamily={"'Inter',Helvetica"}
                    fontWeight={400}
                    // color={isTennisAccordionOpen ? 'white' : 'black'}
                    // bgColor={
                    //   isTennisAccordionOpen ? '#102A6D' : 'white'
                    // }
                    onClick={() => {
                      if (isTennisAccordionOpen) {
                        setIsTennisAccordionOpen(false);
                        naviagte("/");
                      } else {
                        setIsTennisAccordionOpen(true);
                        naviagte("/games/tennis");
                      }
                    }}
                  >
                    Tennis
                  </Box>
                </AccordionButton>
              </h2>
              {/* <AccordionPanel>
                        {isTennisAccordionOpen && (
                          <Flex
                            onClick={() => {
                              window.location.href = '/';
                            }}
                            align='center'
                            gap='23px'
                            mt='20px'
                            cursor='pointer'
                          >
                            <Box className='back-cta'>
                              <BackIcon />
                            </Box>
                            Previous
                          </Flex>
                        )}
                        {Array.isArray(tennisData) &&
                          tennisData.map((group, index) => (
                            <React.Fragment key={index}>
                              {Array.isArray(group) ? (
                                group.map((game, gameIndex) => (
                                  <Link
                                    className='link-side'
                                    to={`/game/tennis/${game.tennisId}?gameId=${
                                      game.gameId || game.eventId
                                    }`}
                                    onClick={() =>
                                      navigateToGame(game.id, game)
                                    }
                                    key={gameIndex}
                                  >
                                    <Box
                                      display='flex'
                                      justifyContent={'space-between'}
                                    >
                                      <Text
                                        style={{
                                          fontFamily: "'Inter',Helvetica",
                                          fontSize: '11px',
                                          fontWeight: 400,
                                          marginLeft: '4px',
                                        }}
                                      >
                                        {game.eventName}
                                      </Text>
                                      <img
                                        src={left}
                                        alt=''
                                        style={{
                                          width: '10px',
                                          height: '10px',
                                        }}
                                      />
                                    </Box>
                                  </Link>
                                ))
                              ) : (
                                <Link
                                  className='link-side'
                                  to={`/game/tennis/${group.tennisId}?gameId=${
                                    group.gameId || group.eventId
                                  }`}
                                  onClick={() =>
                                    navigateToGame(group.id, group)
                                  }
                                  key={index}
                                >
                                  <Box
                                    display='flex'
                                    justifyContent={'space-between'}
                                  >
                                    <Text
                                      style={{
                                        fontFamily: "'Inter',Helvetica",
                                        fontSize: '11px',
                                        fontWeight: 400,
                                        marginLeft: '4px',
                                      }}
                                    >
                                      {group.eventName}
                                    </Text>
                                    <img
                                      src={left}
                                      alt=''
                                      style={{ width: '10px', height: '10px' }}
                                    />
                                  </Box>
                                </Link>
                              )}
                              {index !== gameData.tennis.length - 1 && (
                                <Divider
                                  style={{
                                    borderBottom: '1px solid #DDDDDD',
                                    width: '100%',
                                  }}
                                />
                              )}
                            </React.Fragment>
                          ))}
                      </AccordionPanel> */}
            </AccordionItem>
          </Accordion>
        </li>
        {/* {!isTennisAccordionOpen && (
                  <> */}
        <li
          style={{
            gap: "10px",
          }}
        >
          <LiveCasinoIcon />
          <a href="/live-casino">Live Casino</a>
        </li>
        <li
          style={{
            gap: "10px",
          }}
        >
          <CardGameMenuIcon />
          <a href="/card-games">Indian Card Games</a>
        </li>
        <li
          className="disabled-link"
         
        >
          <HourseRacingIcon />
          <a href>Evolution</a>
        </li>

        <li
          className="disabled-link"
          style={{
            gap: "10px",
          }}
        >
          <SportsBookIcon />
          <a href>Ezugi</a>
        </li>

        {/* </>
                )} */}
        {/* </> */}
        {/* //   )}
          // </> */}
        {/* // )} */}
      </ul>
    </div>
  );
};

export default Sidebar;
