import { createSlice } from '@reduxjs/toolkit';
import { INITIAL_STATE } from '../initialState';
import {
  CASINO_SERVICE_URL,
  CRICKET_SERVICE_URL,
  CRICKET_SERVICE_URL_SOCKET,
  IS_PROD,
  SOCCER_AND_TENNIS_SERVICE_URL,
  SOCCER_AND_TENNIS_SERVICE_URL_SOCKET,
} from '../../utils/constants';
import { Stomp } from '@stomp/stompjs';
export const gameSlice = createSlice({
  name: 'gameData',
  initialState: INITIAL_STATE,
  reducers: {
    setSideBarData: (state, action) => {
      state.sideBarData = { ...state.sideBarData, ...action.payload };
    },
    setCricketOdds: (state, action) => {
      let payload = state.cricketOdds;
      Object.keys(action.payload).map((key) => {
        payload[key] = action.payload[key];
      });
      state.cricketOdds = payload;
    },
    setTennisOdds: (state, action) => {
      let payload = state.tennisOdds;
      Object.keys(action.payload).map((key) => {
        payload[key] = action.payload[key];
      });
      state.tennisOdds = payload;
    },
    setSoccerOdds: (state, action) => {
      let payload = state.soccerOdds;
      Object.keys(action.payload).map((key) => {
        payload[key] = action.payload[key];
      });
      state.soccerOdds = payload;
    },
    setTennisGames: (state, action) => {
      state.tennisGames = action.payload;
    },
    setTennisInPlayGames: (state, action) => {
      state.tennisInPlayGames = action.payload;
    },
    setCricketGames: (state, action) => {
      state.cricketGames = action.payload;
    },
    setCricketInPlayGames: (state, action) => {
      state.cricketInPlayGames = action.payload;
    },
    setSoccerGames: (state, action) => {
      state.soccerGames = action.payload;
    },
    setSoccerInPlayGames: (state, action) => {
      state.soccerInPlayGames = action.payload;
    },
    setSelectedEventForBet: (state, action) => {
      if (action.payload === null) {
        state.selectedEventForBet = null;
      } else {
        state.selectedEventForBet = {
          ...state.selectedEventForBet,
          ...action.payload,
        };
      }
    },
    setCasinoOdds: (state, action) => {
      state.casinoOdds = action.payload;
    },
    setMatchSettings: (state, action) => {
      let { game, settings, eventId } = action.payload;
      if (game === 'cricket') {
        state.cricketSettings[eventId] = settings;
      }
      if (game === 'tennis') {
        state.tennisSettings[eventId] = settings;
      }
      if (game === 'soccer') {
        state.soccerSettings[eventId] = settings;
      }
    },
    setTeenOdds: (state, action) => {
      state.teenOdds = action.payload;
    },
    setTeenPatti20Odds: (state, action) => {
      state.teenPatti20Odds = action.payload;
    },
    setLucky7Odds: (state, action) => {
      state.lucky7Odds = action.payload;
    },
    setLucky7euOdds: (state, action) => {
      state.lucky7euOdds = action.payload;
    },
    setCard32Odds: (state, action) => {
      state.card32Odds = action.payload;
    },
    setAndarBahar20Odds: (state, action) => {
      state.andarBahar20Odds = action.payload;
    },
    setPoker20Odds: (state, action) => {
      state.poker20Odds = action.payload;
    },
    setDragonTiger20Odds: (state, action) => {
      state.dragonTiger20Odds = action.payload;
    },
    setDragonTiger202Odds: (state, action) => {
      state.dragonTiger202Odds = action.payload;
    },
    setCasinoQueenOdds: (state, action) => {
      state.casinoQueenOdds = action.payload;
    },
    setDragonTigerLionOdds: (state, action) => {
      state.dragonTigerLionOdds = action.payload;
    },
    setTeen8Odds: (state, action) => {
      let payload = action.payload;
      if (payload.cards) {
        for (let i = 0; i < payload.cards.length; i++) {
          if (payload.cards[i].length < 3) {
            while (payload.cards[i].length < 3) {
              payload.cards[i].push('1');
            }
          }
        }
      }
      state.teen8Odds = payload;
    },
    setTeen9Odds: (state, action) => {
      state.teen9Odds = action.payload;
    },
    setCasinoMeterOdds: (state, action) => {
      state.casinoMeterOdds = action.payload;
    },
    setPoker9Odds: (state, action) => {
      state.poker9Odds = action.payload;
    },

    setCard32euOdds: (state, action) => {
      state.card32euOdds = action.payload;
    },
    setRace20Odds: (state, action) => {
      state.race20Odds = action.payload;
    },
    setWorli2Odds: (state, action) => {
      state.worli2Odds = action.payload;
    },
    setBollywoodTableOdds: (state, action) => {
      state.bollywoodTableOdds = action.payload;
    },
    setCasinoWarOdds: (state, action) => {
      state.casinoWarOdds = action.payload;
    },
    setAbjOdds: (state, action) => {
      state.abjOdds = action.payload;
    },
    setBaccarat20Odds: (state, action) => {
      state.baccarat2Odds = action.payload;
    },
    setBaccaratOdds: (state, action) => {
      state.baccaratOdds = action.payload;
    },
    setCmatch20Odds: (state, action) => {
      state.cmatch20Odds = action.payload;
    },
    setWorliOdds: (state, action) => {
      state.worliOdds = action.payload;
    },
    setCricketv3Odds: (state, action) => {
      state.cricketv3Odds = action.payload;
    },
    setAaaOdds: (state, action) => {
      state.aaaOdds = action.payload;
    },
    setDragonTigerOneDayOdds: (state, action) => {
      state.dragonTigerOneDayOdds = action.payload;
    },
    setSuperOverOdds: (state, action) => {
      state.superOverOdds = action.payload;
    },
    setIsDataLoaded(state, action) {
      state.isDataLoaded = action.payload;
    },

    logOut: (state, action) => {
      state.selectedEventForBet = null;
    },
    setGameKey(state, action) {
      state.gameKey = action.payload;
    },
    setUpcomingMatches(state, action) {
      state.upcomingMatches = action.payload;
    },
  },
});

export const {
  setGameKey,
  setUpcomingMatches,
  setCricketInPlayGames,
  setSoccerInPlayGames,
  setTennisInPlayGames,
} = gameSlice.actions;
export default gameSlice.reducer;

export const getMatches = (id) => async (dispatch) => {
  try {
    const stompURL =
      id == 'cricket'
        ? !IS_PROD
          ? CRICKET_SERVICE_URL.replace('http', 'ws').replace(
              '/api',
              '/websocket'
            )
          : CRICKET_SERVICE_URL_SOCKET.replace('https', 'wss')
        : id == 'casino'
        ? !IS_PROD
          ? CASINO_SERVICE_URL.replace('http', 'ws').replace(
              '/api',
              '/websocket'
            )
          : CASINO_SERVICE_URL.replace('http', 'ws').replace('/v1', '') +
            '/websocket'
        : !IS_PROD
        ? SOCCER_AND_TENNIS_SERVICE_URL.replace('http', 'ws').replace(
            '/api',
            '/websocket'
          )
        : SOCCER_AND_TENNIS_SERVICE_URL_SOCKET.replace('https', 'wss');

    let stompClient = Stomp.client(stompURL);
    stompClient.debug = () => {
      return null;
    };
    stompClient.connect({}, (frame) => {
      stompClient.subscribe('/live/liveMatches', function (message) {
        try {
          let data = JSON.parse(message.body);
          console.log(data, 'socketData');

          if (id == 'cricket') {
            dispatch({
              type: 'gameData/setCricketGames',
              payload: data,
            });
          }

          if (id == 'soccer' || id == 'tennis') {
            dispatch({
              type: 'gameData/setSoccerGames',
              payload: data.soccerAllMatchesResponseDTOS,
            });
            dispatch({
              type: 'gameData/setTennisGames',
              payload: data.tennisAllMatchesResponseDTOS,
            });
          }
        } catch (e) {
          console.log(e);
        }
      });
    });
  } catch (error) {}
};
export const getInplayMatches = (id) => async (dispatch) => {
  try {
    const stompURL =
      id == 'cricket'
        ? !IS_PROD
          ? CRICKET_SERVICE_URL.replace('http', 'ws').replace(
              '/api',
              '/websocket'
            )
          : CRICKET_SERVICE_URL_SOCKET.replace('https', 'wss')
        : id == 'casino'
        ? !IS_PROD
          ? CASINO_SERVICE_URL.replace('http', 'ws').replace(
              '/api',
              '/websocket'
            )
          : CASINO_SERVICE_URL.replace('http', 'ws').replace('/v1', '') +
            '/websocket'
        : !IS_PROD
        ? SOCCER_AND_TENNIS_SERVICE_URL.replace('http', 'ws').replace(
            '/api',
            '/websocket'
          )
        : SOCCER_AND_TENNIS_SERVICE_URL_SOCKET.replace('https', 'wss');

    let stompClient = Stomp.client(stompURL);
    stompClient.debug = () => {
      return null;
    };
    stompClient.connect({}, (frame) => {
      stompClient.subscribe('/live/inplayMatches', function (message) {
        try {
          let data = JSON.parse(message.body);

          if (id == 'cricket') {
            dispatch({
              type: 'gameData/setCricketInPlayGames',
              payload: data,
            });
          }

          if (id == 'tennis') {
            dispatch({
              type: 'gameData/setTennisInPlayGames',
              payload: data.tennisAllMatchesResponseDTOS,
            });
            dispatch({
              type: 'gameData/setSoccerInPlayGames',
              payload: data.soccerAllMatchesResponseDTOS,
            });
          }
        } catch (e) {
          console.log(e);
        }
      });
    });
  } catch (error) {}
};
