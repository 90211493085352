import React, { useCallback, useEffect, useState } from 'react';
import { Button, Stack } from '@mui/material';
import { SearchWithText } from '../shared/SearchWithText';
import { GenericTable } from '../shared/GenericTable';
import '../../assets/styles/css/current.scss';
import BasicDatePicker from '../shared/DatePicker';
import moment from 'moment';
import { formatDate } from '../AccountStatement/index.utils';
import { SelectType } from '../shared/SelectType';
import { casinosOptions } from '../shared/SelectType/index.utils';
import { getCasinoReportHistory } from '../../service/casino';

export const FinancialCasinoResult = () => {
  const [currentBetsData, setCurrentBetsData] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [fromDate, setFromDate] = useState(
    moment().subtract(7, 'days').format('YYYY-MM-DD')
  );

  const [type, setType] = React.useState('DRAGON_TIGER_20');

  const handleFromDateChange = (date) => {
    setFromDate(date);
    console.log('from', date);
  };

  const fetchCurrentBetsData = useCallback(async () => {
    const payload = {
      casinoGames: type,
      date: moment(fromDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS'),
      page: 0,
      size: 100,
    };

    try {
      const response = await getCasinoReportHistory(payload);
      console.log(response, 'response');
      setCurrentBetsData(response.response.content || []);
      setFilteredTableData(response.response.content || []);
    } catch (error) {
      console.error('Error fetching current bets data:', error);
    }
  }, [fromDate, type]);
  
  useEffect(() => {
    fetchCurrentBetsData();
  }, [fetchCurrentBetsData]);

  const currentBetsTableHeader = ['No','Game', 'Winner', 'Date'];

  const currentBetsTableRow = filteredTableData.map((data, index) => ({
    No: index + 1,
    Game: data.casinoGames || '',
    Winner: data.winner || '',
    Date: moment(data.createdAt).format('DD/MM/YYYY hh:mm A') || '',
  }));

  const handleSearch = (searchTerm) => {
    const formattedSearchTerm = searchTerm.toLowerCase();

    const filteredData = currentBetsData.filter((data) => {
      const srNoMatch = String(data.No)
        .toLowerCase()
        .includes(formattedSearchTerm);
      const event_winner = String(data.winner)
        .toLowerCase()
        .includes(formattedSearchTerm);
        const casino_game = String(data.casinoGames)
        .toLowerCase()
        .includes(formattedSearchTerm);

      return srNoMatch || event_winner || casino_game;
    });

    setFilteredTableData(filteredData);
  };

  return (
    <React.Fragment>
      <Stack className='current-bets-main-container'>
        <SearchWithText
          text={'Casino Results'}
          onSearchClick={(e) => handleSearch(e.target.value)}
        />
      </Stack>
      <Stack className='accounts-page-main-stack'>
        {/* <SearchWithText
          text={'Account Statement'}
          onSearchClick={(e) => handleSearch(e.target.value)}
        /> */}
        <Stack className='accounts-page-date-stack'>
          <BasicDatePicker
            date={fromDate}
            text={'From'}
            onChange={handleFromDateChange}
          />

          <Stack className='select-main-stack' direction={'row'}>
            <SelectType
              selectedType={type}
              handleTypeChange={(e) => {
                setType(e.target.value);
              }}
              options={casinosOptions}
            />

            <Stack className='submit-button-stack' direction={'row'}>
              <Button
                variant='contained'
                onClick={fetchCurrentBetsData}
                className='submit-button'
                disableRipple
              >
                Submit
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack className='current-bets-main-container-2'>
        <GenericTable
          tableHeader={currentBetsTableHeader}
          tableRow={currentBetsTableRow}
          isBetHistory={true}
        />
      </Stack>
    </React.Fragment>
  );
};
