export const radioOptions = [
    { value: "all", label: "All" },
    { value: "back", label: "Back" },
    { value: "lay", label: "Lay" },
  ];

export const perPageOptions = [
    { value: "none", label: "None" },
    { value: 5, label: "5" },
    { value: 10, label: "10" },
    { value: 25, label: "25" },
  ];