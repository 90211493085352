import {
  Box,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import "./deposite.scss";
import copy from "copy-to-clipboard";
import { AXIOS_INSTANCE } from "../../service";
import { BASE_URL_BANKING } from "../../utils/constants";
import { Button } from "@mui/material";
import UPIPayment from "./UPIBHIMPayment";
import UPIBHIMPayment from "./UPIBHIMPayment";
import UPIPhonePePayment from "./UPIPhonePePayment";
import UPIGPayPayment from "./UPIGPayPayment";
import UPIPaytmPayment from "./UPIPaytmPayment";
import USDTPayment from "./USDTPayment";

const UPI_ENUMS = {
  banktransfer: "BANK_SAVING",
  usdt: "USDT",
  phone_pe: "UPI_PHONEPE",
  gpay: "UPI_GPAY",
  paytm: "UPI_PAYTM",
  upi: "UPI_BHIM",
};

const mapPaymentOptionToEnum = (selectedPaymentOption) => {
  const formattedOption = selectedPaymentOption;

  return UPI_ENUMS[formattedOption] || "";
};

const Deposite = () => {
  const toast = useToast();
  const [paymentDetails, setPaymentDetails] = useState({
    bank: {},
    upi: {},
    usdt: {},
  });
  const [bankFormData, setBankFormData] = useState({
    bank_name: "",
    account_holder_name: "",
    account_number: "",
    account_type: "",
    ifsc_code: "",
    branch: "",
    bank_amount: "",
    username: "",
    utr_number: "",
    transfer_via: "IMPS", // Set default value or manage state accordingly
    promocode: "",
    payment_type: "BANK_SAVING",
  });
  const [file, setFile] = useState(null);
  const [selectedPaymentOption, setSelectedPaymentOption] =
    useState("BANK_CURRENT");
  const [upiData, setUpiData] = useState({
    wallet: "",
    amount: "",
    acount_name: "",
    userId: "",
    refNo: "",
    payment_type: mapPaymentOptionToEnum(selectedPaymentOption),
  });

  const resetData = () => {
    setBankFormData({
      bank_name: "",
      account_holder_name: "",
      account_number: "",
      account_type: "",
      ifsc_code: "",
      branch: "",
      bank_amount: "",
      username: "",
      utr_number: "",
      transfer_via: "IMPS",
      promocode: "",
      payment_type: "SAVING_BANK",
    });
    setUpiData({
      wallet: "",
      amount: "",
      acount_name: "",
      userId: "",
      refNo: "",
      payment_type: selectedPaymentOption,
    });
    setSelectedPaymentOption("");
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setBankFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };


  const handleDeposit = async (uri) => {
    let coins;
    let payment_type;
    console.log('selectedPaymentOption', bankFormData,upiData, selectedPaymentOption);
    if (selectedPaymentOption === "BANK_CURRENT") {
      coins = bankFormData.bank_amount;
      payment_type = paymentDetails?.bank?.bankType;
     
    } else {
      coins = upiData.amount;
      payment_type = selectedPaymentOption;
     
    }
    const payload = {
      coins: coins,
      payment_type: selectedPaymentOption,
      utr_number: bankFormData.utr_number || upiData.utr_number,
    };
    console.log(payload, "payload")
    if (bankFormData.transfer_type) {
      payload.transfer_type = bankFormData.transfer_type;
    }
    if (uri) {
      payload.paymentProofs = [uri];
    }
    if (coins < 100) {
      toast({
        title: "Minimum amount can not be less than 100",
        status: "error",
        duration: 5000,
      });
    } else {
      const res = await AXIOS_INSTANCE.post(
        `${BASE_URL_BANKING}/api/acc/deposit-coins`,
        payload
      );
      if (res.status == 200) {
        resetData();
        toast({
          title: "Deposit request submitted successfully",
          status: "success",
          duration: 5000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    }
  };

  const handleBANK_CURRENTSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      return await handleDeposit();
    }
    let coins;
    let payment_type;
    if (selectedPaymentOption === "BANK_CURRENT") {
      coins = bankFormData.bank_amount;
      payment_type = paymentDetails?.bank?.bankType;
      if (coins < 100) {
        toast({
          title: "Minimum amount cannot be less than 100",
          status: "error",
        });
        return;
      }
    } else {
      coins = upiData.amount;
      payment_type = selectedPaymentOption;
    }
    var formData = new FormData();
    formData.append("file", file);
    formData.append("bank_amount", coins);
    formData.append("payment_type", payment_type);
    try {
      const res = await AXIOS_INSTANCE.post(
        `${BASE_URL_BANKING}/api/acc/upload-doc`,
        formData
      );
      if (res.status === 200) {
        const uri = res?.data?.data["File Url"]?.CREATED;
        // resetData();
        await handleDeposit(uri);
      }
    } catch (error) {
      toast({
        title: "Error uploading document",
        description: error.message,
        status: "error",
        duration: 9000,
      });
    }
  };

  const handleUpiDataChange = (event) => {
    const { name, value } = event.target;
    setUpiData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const getPaymentDetails = async () => {
    try {
      const res = await AXIOS_INSTANCE.get(
        `${BASE_URL_BANKING}/api/admin/payment-details`
      );

      if (res.status === 200) {
        const resData = res.data.data;

        setPaymentDetails({
          bank: resData?.PaymentDetails?.find(
            (item) => item.isMethodActive && item.type === "bank"
          ),
          upi: {
            UPI_PHONEPE: resData?.PaymentDetails?.find(
              (item) =>
                item.isMethodActive &&
                item.type === "upi" &&
                item.bankType === "UPI_PHONEPE"
            ),
            UPI_PAYTM: resData?.PaymentDetails?.find(
              (item) =>
                item.isMethodActive &&
                item.type === "upi" &&
                item.bankType === "UPI_PAYTM"
            ),
            UPI_GPAY: resData?.PaymentDetails?.find(
              (item) =>
                item.isMethodActive &&
                item.type === "upi" &&
                item.bankType === "UPI_GPAY"
            ),
            UPI_BHIM: resData?.PaymentDetails?.find(
              (item) =>
                item.isMethodActive &&
                item.type === "upi" &&
                item.bankType === "UPI_BHIM"
            ),
          },
          usdt: resData?.PaymentDetails?.find(
            (item) => item.isMethodActive && item.type === "USDT"
          ),
        });
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleTabChange = (index) => {
    let newSelectedOption = '';
    switch (index) {
      case 0:
        newSelectedOption = UPI_ENUMS.banktransfer;
        break;
      case 1:
        newSelectedOption = UPI_ENUMS.usdt;
        break;
      case 2:
        newSelectedOption = UPI_ENUMS.gpay;
        break;
      case 3:
        newSelectedOption = UPI_ENUMS.paytm;
        break;
      case 4:
        newSelectedOption = UPI_ENUMS.upi;
        break;
        case 5:
        newSelectedOption = UPI_ENUMS.phone_pe;
        break;
      default:
        break;
    }
    setSelectedPaymentOption(newSelectedOption);

console.log("current tab:", selectedPaymentOption)
  };

  useEffect(() => {
    getPaymentDetails();
  }, [selectedPaymentOption]);

console.log("current tabbbb:", selectedPaymentOption)

  return (
    <Box className="deposite-page">
      <Tabs onChange={handleTabChange}>
        <TabList>
          <Tab>
            <img
              // onClick={() => {
              //   setSelectedPaymentOption("BANK_CURRENT");
              // }}
              src="https://hotsports.com/img/payment_method/bt.png"
            />
          </Tab>
          {/* <Tab>
            <img
              // onClick={() => {
              //   setSelectedPaymentOption("UPI_PHONEPE");
              // }}
              src="https://hotsports.com/img/payment_method/phonepe.webp"
            />
          </Tab>
          <Tab>
            <img
              // onClick={() => {
              //   setSelectedPaymentOption("UPI_GPAY");
              // }}
              src="https://hotsports.com/img/payment_method/googlepay.webp"
            />
          </Tab>
          <Tab>
            {" "}
            <img
              // onClick={() => {
              //   setSelectedPaymentOption("UPI_PAYTM");
              // }}
              src="https://hotsports.com/img/payment_method/f-paytm.png"
            />
          </Tab>
          <Tab>
            <img
              // onClick={() => {
              //   setSelectedPaymentOption("UPI_BHIM");
              // }}
              src="https://hotsports.com/img/payment_method/upi.webp"
            />
          </Tab> */}
          <Tab>
            <img
              // onClick={() => {
              //   setSelectedPaymentOption("USDT");
              // }}
              src={require("../../assets/img/usdt.jpg")}
            />
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Box p={"25px"}>
              <div
                id="offline_deposit_content_BANK_CURRENT"
                data-payment_type="BANK_CURRENT"
                className="depo_method"
              >
                <form
                  id="offline_deposit_bank"
                  className="offline_deposit"
                  data-toggle="validator"
                >
                  <div className="valresponse"></div>
                  <div className="method_head">Transfer Details</div>
                  <h6 className="method_subhead text-italic">
                    *Please make your payment on the details below and submit
                    transaction id
                  </h6>
                  <Flex w={"100%"} gap={"20px"}>
                    <Box w={"50%"}>
                      <div className="deposit_section_in bank_trans_sec1">
                        <div className="form-deposits">
                          <label className="form-float-label">Bank name</label>
                          <input
                            className="form-control "
                            type="text"
                            id=""
                            value={paymentDetails?.bank?.bankName}
                            name="bank_name"
                            placeholder=""
                            autoComplete="off"
                            onChange={handleInputChange}
                          />
                          <button
                            className="click_btn button copy_btn copy_to_clip"
                            onClick={(e) => {
                              e.preventDefault();
                              copy(paymentDetails?.bank?.bankName);
                            }}
                          >
                            Copy
                          </button>
                        </div>

                        <div className="form-deposits">
                          <label className="form-float-label">
                            Account holder name
                          </label>
                          <input
                            className="form-control "
                            type="text"
                            id=""
                            value={paymentDetails?.bank?.accountHolderName}
                            name="account_holder_name"
                            placeholder=""
                            autocomplete="on"
                            onChange={handleInputChange}
                          />
                          <button
                            className="click_btn button copy_btn copy_to_clip"
                            onClick={(e) => {
                              e.preventDefault();
                              copy(paymentDetails?.bank?.accountHolderName);
                            }}
                          >
                            Copy
                          </button>
                        </div>

                        <div className="form-deposits">
                          <label className="form-float-label">
                            Account Number
                          </label>
                          <input
                            className="form-control "
                            type="text"
                            id=""
                            value={paymentDetails?.bank?.accountNumber}
                            name="account_number"
                            placeholder=""
                            autocomplete="off"
                            onChange={handleInputChange}
                          />
                          <button
                            className="click_btn button copy_btn copy_to_clip"
                            onClick={(e) => {
                              e.preventDefault();
                              copy(paymentDetails?.bank?.accountNumber);
                            }}
                          >
                            Copy
                          </button>
                        </div>

                        <div className="form-deposits">
                          <label className="form-float-label">
                            Account Type
                          </label>
                          <input
                            className="form-control "
                            type="text"
                            id=""
                            value={paymentDetails?.bank?.bankType}
                            name="account_type"
                            placeholder=""
                            autocomplete="off"
                            onChange={handleInputChange}
                          />
                          <button
                            className="click_btn button copy_btn copy_to_clip"
                            onClick={(e) => {
                              e.preventDefault();
                              copy(paymentDetails?.bank?.bankType);
                            }}
                          >
                            Copy
                          </button>
                        </div>

                        <div className="form-deposits">
                          <label className="form-float-label">IFSC code</label>
                          <input
                            className="form-control "
                            type="text"
                            id=""
                            value={paymentDetails?.bank?.ifscCode}
                            name="ifsc_code"
                            placeholder=""
                            autocomplete="off"
                            onChange={handleInputChange}
                          />
                          <button
                            className="click_btn button copy_btn copy_to_clip"
                            onClick={(e) => {
                              e.preventDefault();
                              copy(paymentDetails?.bank?.ifscCode);
                            }}
                          >
                            Copy
                          </button>
                        </div>
                      </div>
                    </Box>
                    <Box w={"50%"}>
                      <div className="method_head">Your Payment Details</div>
                      <div className="deposit_section_in bank_trans_sec1">
                        <div className="form-deposits">
                          <label className="form-float-label">
                            Amount <small>Min:100 Max: 10000000</small>
                          </label>
                          <input
                            className="form-control bank_amount do-alphn"
                            type="number"
                            id=""
                            value={bankFormData.bank_amount}
                            name="bank_amount"
                            min="100"
                            max="10000000"
                            placeholder=""
                            required=""
                            autocomplete="off"
                            data-maxlen="8"
                            onChange={handleInputChange}
                          />
                        </div>

                        <div className="form-deposits">
                          <label className="form-float-label">
                            Ref. No. / Trans. ID / UTR{" "}
                          </label>
                          <input
                            className="form-control utr_number do-alphn"
                            type="text"
                            id="utr_number"
                            value={bankFormData.utr_number}
                            name="utr_number"
                            placeholder=""
                            required=""
                            autocomplete="off"
                            data-maxlen="25"
                            onChange={handleInputChange}
                          />
                        </div>

                        <div 
                        // style={{ width: "48%" }}
                        >
                          <label
                            style={{ textAlign: "left" }}
                            className="form-float-label "
                          >
                            Upload Payment Snap{" "}
                          </label>
                          <div
                            style={{ width: "100%" }}
                            className="form-deposits"
                          >
                            <input
                              className="form-control utr_number do-alphn"
                              type="file"
                              id="utr_number"
                              name="utr_number"
                              placeholder=""
                              required=""
                              autocomplete="off"
                              data-maxlen="25"
                              onChange={handleFileChange}
                            />
                          </div>
                        </div>

                        <div
                          // style={{ width: "48%" }}
                          className="form-float-radio_btn"
                        >
                          <label className="form-float-label mt-2">
                            Transferred By
                          </label>
                          <div className="bb_tran_subdiv_sec" >
                            <input
                              type="radio"
                              className="trans_type"
                              required=""
                              id="bt-imps"
                              name="transfer_via"
                              data-img="imps"
                              value="IMPS"
                              autocomplete="off"
                              onChange={(e) => {
                                setBankFormData({
                                  ...bankFormData,
                                  transfer_type: "IMPS",
                                });
                              }}
                            />
                            <label for="Transfer"> IMPS</label>
                            <input
                              type="radio"
                              className="trans_type ml-2"
                              required=""
                              id="bt-neft"
                              name="transfer_via"
                              data-img="neft"
                              value="NEFT"
                              autocomplete="off"
                              onChange={(e) => {
                                setBankFormData({
                                  ...bankFormData,
                                  transfer_type: "NEFT",
                                });
                              }}
                            />
                            <label for="Transfer"> NEFT</label>
                            <input
                              type="radio"
                              className="trans_type ml-2"
                              required=""
                              id="bt-rtgs"
                              name="transfer_via"
                              data-img="rtgs"
                              value="RTGS"
                              autocomplete="off"
                              onChange={(e) => {
                                setBankFormData({
                                  ...bankFormData,
                                  transfer_type: "RTGS",
                                });
                              }}
                            />
                            <label for="Transfer"> RTGS</label>
                          </div>
                        </div>
                        <div
                          className="form-deposits promocode-area"
                          id="promocode-html-append-BANK_CURRENT"
                        >
                          <div
                            id="promocode-section"
                            className="form-group promocode-section"
                            style={{ display: "none" }}
                          >
                            <label>
                              <b>Promocode</b>
                            </label>
                            <button
                              type="button"
                              className="close cancel-promocode"
                            >
                              <span aria-hidden="true">×</span>
                            </button>
                            <div className="input-group">
                              <input
                                id="promocode"
                                type="text"
                                name="promocode"
                                className="form-control promocode promocode_input mr-2"
                                placeholder="Enter your Promocode"
                                autocomplete="off"
                              />
                              <button
                                id="apply-promocode"
                                type="button"
                                className="btn blue-button apply-promocode"
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="acc_details text-center"
                        style={{ marginTop: "12px" }}
                      >
                        <input
                          type="hidden"
                          id="offline_payment_type"
                          name="payment_type"
                          value="bank_transfers"
                          autocomplete="off"
                        />
                        <Button
                          variant="contained"
                          onClick={handleBANK_CURRENTSubmit}
                          className="submit-button"
                          disabled={
                            !bankFormData.bank_amount ||
                            (!file && !bankFormData.utr_number) ||
                            (!bankFormData.utr_number && !file) ||
                            !bankFormData.transfer_type
                          }
                        >
                          Submit
                        </Button>
                      </div>
                    </Box>
                  </Flex>
                </form>
              </div>
            </Box>
          </TabPanel>
          <TabPanel>
            <UPIPhonePePayment
              paymentDetails={paymentDetails}
              handleUpiDataChange={handleUpiDataChange}
              upiData={upiData}
              handleFileChange={handleFileChange}
              handleBANK_CURRENTSubmit={handleBANK_CURRENTSubmit}
              file={file}
              selectedPaymentOption={selectedPaymentOption}
            />
          </TabPanel>
          <TabPanel>
            <UPIGPayPayment
              paymentDetails={paymentDetails}
              handleUpiDataChange={handleUpiDataChange}
              upiData={upiData}
              handleFileChange={handleFileChange}
              handleBANK_CURRENTSubmit={handleBANK_CURRENTSubmit}
              file={file}
            />
          </TabPanel>
          <TabPanel>
            <UPIPaytmPayment
              paymentDetails={paymentDetails}
              handleUpiDataChange={handleUpiDataChange}
              upiData={upiData}
              handleFileChange={handleFileChange}
              handleBANK_CURRENTSubmit={handleBANK_CURRENTSubmit}
              file={file}
            />
          </TabPanel>
          <TabPanel>
            <UPIBHIMPayment
              paymentDetails={paymentDetails}
              handleUpiDataChange={handleUpiDataChange}
              upiData={upiData}
              handleFileChange={handleFileChange}
              handleBANK_CURRENTSubmit={handleBANK_CURRENTSubmit}
              file={file}
            />
          </TabPanel>
          <TabPanel>
            <USDTPayment
              paymentDetails={paymentDetails}
              handleUpiDataChange={handleUpiDataChange}
              upiData={upiData}
              handleFileChange={handleFileChange}
              handleBANK_CURRENTSubmit={handleBANK_CURRENTSubmit}
              file={file}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default Deposite;
