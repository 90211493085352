import React, { useEffect, useState } from "react";
import { Stack, Typography, Tab, Tabs } from "@mui/material";
import '../../../assets/styles/css/tabs.scss'

const CustomTab = ({ tab1, tab2,setSelectedTab }) => {
  const [tab, setTab] = useState(0);

  const handleChange = (event,newValue) => {
    setTab(newValue);
  };

  useEffect(() => {
    setSelectedTab(tab === 0 ? tab1 : tab2);
  }, [tab]);

  return (
    <Stack
      className="custom-tabs-main-container"
    >
      <Tabs
        value={tab}
        onChange={handleChange}
        className="custom-tabs-header"
      >
        <Tab disableRipple className="tab-1" label={<Typography className="tab-1-text-style">{tab1}</Typography>} />
        <Tab disableRipple className="tab-1" label={<Typography className="tab-1-text-style">{tab2}</Typography>} />
      </Tabs>
    </Stack>
  );
};

export default CustomTab;
