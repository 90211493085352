import CasinoImage1 from "../../../src/assets/images/icons/cimage1.png";
import CasinoImage2 from "../../../src/assets/images/icons/cimage2.png";
import CasinoImage3 from "../../../src/assets/images/icons/cimage3.png";
import CasinoImage4 from "../../../src/assets/images/icons/cimage4.png";
import CasinoImage5 from "../../../src/assets/images/icons/cimage5.png";
import CasinoImage6 from "../../../src/assets/images/icons/cimage6.png";
import CasinoImage7 from "../../../src/assets/images/icons/cimage7.png";
import CasinoImage8 from "../../../src/assets/images/icons/cimage8.png";
import CasinoImage9 from "../../../src/assets/images/icons/cimage9.png";

export const liveCasinoImages = [
  CasinoImage1,
  CasinoImage2,
  CasinoImage3,
  CasinoImage4,
  CasinoImage5,
  CasinoImage6,
  CasinoImage7,
  CasinoImage8,
  CasinoImage9,
];

export const groupedImages = [];

for (let i = 0; i < liveCasinoImages.length; i += 5) {
  groupedImages.push(liveCasinoImages.slice(i, i + 5));
}
