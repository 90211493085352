import React from "react";
import { Stack } from "@mui/material";
import { groupedImages } from "./index.utils";
import "../../../src/assets/styles/css/liveCasino.scss";
import CasinoList from "../../pages/casino/CasinoList";
import { ASSETS_URL } from "../../utils/constants";
import { Grid, GridItem, Image } from "@chakra-ui/react";

const LiveCasinoImages = () => {
  const [casinoList, setCasinoList] = React.useState([
    {
      name: "Lucky 7-A",
      image: "lucky7.jpg",
      link: "/live-casino/lucky7/a",
      group: "lucky",
    },
    {
      name: "Lucky 7-B",
      image: "lucky7eu.jpg",
      link: "/live-casino/lucky7/b",
      group: "lucky",
    },
    {
      name: "Bollywood Casino",
      image: "btable.jpg",
      link: "/live-casino/cn/bollywood-table",
      group: "bollywood",
    },
    {
      name: "Amar Akbar Anthony",
      image: "aaa.jpg",
      link: "/live-casino/andar-bahar/aaa",
      group: "bollywood",
    },
    {
      name: "Instant Worli",
      image: "instant worli.jpg",
      link: "/live-casino/worli/2",
      group: "wroli",
    },

    {
      name: "Casino War",
      image: "casino-war.jpg",
      link: "/live-casino/cn/war",
      group: "casino",
    },
    {
      name: "Race 20",
      image: "race-20.jpg",
      link: "/live-casino/race/20-20",
      group: "race",
    },
    {
      name: "5-5 Cricket",
      image: "five cricket.jpg",
      link: "/live-casino/cricket/five",
      group: "sports-casino",
    },
    {
      name: "Cricket 20-20",
      image: "cmatch20.jpg",
      link: "/live-casino/cricket/20-20",
      group: "sports-casino",
    },
    {
      name: "Super Over",
      image: "superover.jpg",
      link: "/live-casino/cricket/super-over",
      group: "sports-casino",
    },
    {
      name: "Casino Meter",
      image: "casino meter.jpg",
      link: "/live-casino/cn/meter",
      group: "sports-casino",
    },
  ]);
  return (
    <>
      {/* <div className="main-live-stack">

     <CasinoList className="live-img" />
    </div> */}

      <Grid
        mt={{ base: "1px !important", md: "0px" }}
        gap={2}
        templateColumns={{ base: "repeat(3, 1fr)", md: "repeat(4, 1fr)" }}
        className="main-live-stack"
      >
        {casinoList.map((casino, index) => (
          <GridItem
            onClick={() => {
              window.location.href = casino.link;
            }}
            cursor="pointer"
            position="relative"
          >
            <Image
              h="100"
              width={"100%"}
              src={ASSETS_URL + casino.image}
              // className="live-img"
            />
          </GridItem>
        ))}
      </Grid>

      {/* {groupedImages.map((group, groupIndex) => (
        <Stack
          key={groupIndex}
          direction={'row'}
          className="main-live-stack"
        >
          {group.map((liveCasinoImages, index) => (
            <img
              key={index}
              src={liveCasinoImages}
              alt={`newImage ${groupIndex * 5 + index + 1}`}
              className="live-img"
            />
          ))}
        </Stack>
      ))} */}
    </>
  );
};

export default LiveCasinoImages;
