import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  color,
  Flex,
  Grid,
  GridItem,
  Image,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  WrapItem,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { FaLock } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Flip, Flipr } from "../../../components/Flip";
import {
  getCasinoPlExposureAPI,
  placeCasinoBetAPI,
} from "../../../service/casino";
import { ASSETS_URL, bgColor, secondaryColor } from "../../../utils/constants";
import PlaceBet from "../../game/PlaceBet";
import { cricketFancyPL, cricketMatchOddsPL } from "../../game/profitLoss";
import MatchOdds_ from "../../game/Ui/MatchOdds_";
import OddsBox from "../../game/Ui/OddsBox";
import CardDrawer from "../../../components/CardDrawer/CardDrawer";
import { useMemo } from "react";
import Iframe from "react-iframe";
import IframeComponent from "../../../components/Iframe";
import CasinoVideoRules from "../../../components/CasinoVideoRules";
import LastResults from "../../../components/LastResults";
import { useCallback } from "react";
import { setGameKey } from "../../../redux/features/gameSlice";
import { getService } from "../../../utils/commonFunctions";
import { useLocation } from "react-router-dom";

export default function FiveCricket() {
  const location = useLocation();
  const dispatch = useDispatch();
  const odds_ = useSelector((state) => state.gameData.cricketv3Odds);
  //useMemo
  const odds = useMemo(() => odds_, [odds_]);
  const [selectedEventForBet, setSelectedEventForBet] = useState(null);
  const [plExposure, setPlExposure] = useState(null);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [isLocked, setIsLocked] = useState(false);
  const userData = useSelector((state) => state.account.userData);
  const authDetails = useSelector((state) => state.account.authDetails);
  const toast = useToast();
  const gameName = "CRICKET_V3";

  const getData = useCallback(
    (id, key = "") => {
      let data = odds[key]?.find((item) => item.runnerName === id);
      console.log(key, id, odds[key], "findDAta");
      return data || {};
    },
    [odds]
  );

  const setEvent = (id, isBack = true, marketName = "oddDetailsDTOS") => {
    let selectedEventForBet = getData(id, marketName);
    let payload = {};
    payload.amount = parseInt(selectedAmount);
    payload.casinoGames = gameName;
    payload.profit =
      marketName == "odds"
        ? cricketMatchOddsPL(
            isBack ? selectedEventForBet.back1 : selectedEventForBet.lay1,
            selectedAmount,
            isBack ? "back" : "lay"
          ).profit
        : cricketFancyPL(
            isBack ? selectedEventForBet.back1 : selectedEventForBet.lay1,
            selectedAmount,
            isBack ? "back" : "lay"
          ).profit;
    payload.loss =
      marketName == "odds"
        ? cricketMatchOddsPL(
            isBack ? selectedEventForBet.back1 : selectedEventForBet.lay1,
            selectedAmount,
            isBack ? "back" : "lay"
          ).profit
        : cricketFancyPL(
            isBack ? selectedEventForBet.back1 : selectedEventForBet.lay1,
            selectedAmount,
            isBack ? "back" : "lay"
          ).profit;
    payload.selectedOdd = isBack
      ? selectedEventForBet.back1
      : selectedEventForBet.lay1;
    payload.selectedId = selectedEventForBet.selectionId;
    payload.marketId = odds.marketId;
    payload.runnerName = selectedEventForBet.runnerName;
    payload.type = isBack ? "back" : "lay";

    dispatch({
      type: "gameData/setSelectedEventForBet",
      payload,
    });
  };

  useEffect(() => {
    dispatch(setGameKey("cricketV3"));
  }, [dispatch]);

  useEffect(() => {
    if (odds?.autoTime <= 5) {
      setIsLocked(true);
    } else {
      setIsLocked(false);
    }
  }, [odds, getData]);
  const getPlExposure = async (id) => {
    const { response, code } = await getCasinoPlExposureAPI(
      getService(location.pathname).port,
      getService(location.pathname).service,
      id
    );
    if (code == 200) {
      setPlExposure(response);
    }
  };
  const getExposureForEntity = (selectionName, selectionId) => {
    if (plExposure) {
      let data;
      plExposure?.map((item) => {
        data = item?.casinoPLExposure?.find(
          (exposure) => exposure.selectionName == selectionName
        );
      });

      return data ? data : null;
    }
  };
  useEffect(() => {
    let interval = setInterval(() => {
      if (odds?.marketId) {
        getPlExposure(odds?.marketId);
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [odds?.marketId]);
  const ref = useRef();

  return (
    <Box
      display="flex"
      flexDirection={{ base: "column", sm: "column", lg: "row" }}
    >
      <Box mt="1" w={{ base: "100%", sm: "100%", lg: "70%" }} mr="1">
        <Box
          h="60vh"
          overflow="hidden"
          mb="2"
          pos="relative"
          className="casino-video"
        >
          {odds?.cards && (
            <CardDrawer
              custom={
                <Box>
                  {odds?.cards?.map((item, index) =>
                    item.map((card, index) => (
                      <Image
                        style={{
                          width: "30px",
                          height: "35px",
                        }}
                        my={2}
                        key={index}
                        src={ASSETS_URL + card + ".png"}
                      />
                    ))
                  )}
                </Box>
              }
            />
          )}
          <div className="market-title">FIVE CRICKET</div>

          <IframeComponent
            url={`${process.env.REACT_APP_IFRAME_URL}?id=3042`}
          />
          <Box
            bottom="0"
            p="2"
            pos="absolute"
            right="2"
            display="flex"
            bg="black"
          >
            <Flip value={odds?.autoTime} />
          </Box>
          <CasinoVideoRules image={`${ASSETS_URL}cricketv5%20rules%20.jpeg`} />
        </Box>

        <div className="markets">
          {odds?.oddDetailsDTOS?.length > 0 && (
            <div className="bookmaker-market">
              <div className="market-title mt-1" style={{ width: "100%" }}>
                Bookmaker market
                <a href="javascript:void(0)" className="m-r-5 game-rules-icon">
                  <span>
                    <i className="fa fa-info-circle float-right"></i>
                  </span>
                </a>
              </div>
              <div className="table-header">
                <div className="float-left country-name box-4 text-info"></div>
                <div className="box-1 float-left"></div>
                <div className="box-1 float-left"></div>
                <div className="back box-1 float-left text-center">
                  <b>BACK</b>
                </div>
                <div className="lay box-1 float-left text-center">
                  <b>LAY</b>
                </div>
                <div className="box-1 float-left"></div>
                <div className="box-1 float-left"></div>
              </div>
              <div className="table-body">
                {odds?.oddDetailsDTOS?.map((match, index) => (
                  <div key={index} className="table-row">
                    <div className="float-left country-name box-4">
                      <span className="team-name">
                        <b>{match.runnerName || match.nat}</b>
                      </span>
                      <p>
                        <span className="float-left">0</span>{" "}
                        <span className="float-right">0</span>
                      </p>
                    </div>

                    <div className="box-1 back2 float-left text-center"></div>
                    <div className="box-1 back1 float-left back-2 text-center"></div>
                    <div
                      data={odds}
                      match={match}
                      oddsKey={"back1"}
                      index={index}
                      isFancy={true}
                      className="box-1 back float-left back lock text-center"
                      // onClick={(data_) => {
                      //   if (
                      //     getData(match.runnerName, 'oddDetailsDTOS').back1 > 0
                      //   ) {
                      //     setEvent(data_.runnerName, true, 'oddDetailsDTOS');
                      //   }
                      // }}
                      onClick={(data_) => {
                        if (
                          getData(match.runnerName, "oddDetailsDTOS").back1 > 0
                        ) {
                          setEvent(match.runnerName, true, "oddDetailsDTOS");
                        }
                      }}
                    >
                      <span className="odd d-block">
                        {getData(match.runnerName, "oddDetailsDTOS").back1}
                      </span>{" "}
                      <span className="d-block">
                        {getData(match.runnerName, "oddDetailsDTOS").backSize1}
                      </span>
                    </div>
                    <div
                      data={odds}
                      isFancy={true}
                      match={match}
                      oddsKey={"lay1"}
                      index={index}
                      back={false}
                      className="box-1 lay float-left text-center betting-disabled"
                      // onClick={(data_) => {
                      //   if (
                      //     getData(match.runnerName, 'oddDetailsDTOS').laySize1 >
                      //     0
                      //   ) {
                      //     setEvent(data_.runnerName, false, 'oddDetailsDTOS');
                      //   }
                      // }}
                      onClick={(data_) => {
                        if (
                          getData(match.runnerName, "oddDetailsDTOS").laySize1 >
                          0
                        ) {
                          setEvent(match.runnerName, false, "oddDetailsDTOS");
                        }
                      }}
                    >
                      <span className="odd d-block">
                        {getData(match.runnerName, "oddDetailsDTOS").lay1}
                      </span>{" "}
                      <span className="d-block">
                        {getData(match.runnerName, "oddDetailsDTOS").laySize1}
                      </span>
                    </div>
                    <div className="box-1 lay1 float-left text-center"></div>
                    <div className="box-1 lay2 float-left text-center"></div>
                    {/* </div> */}
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="fancy-market row row5">
            {odds?.fancyOddDetailsDTOS?.length > 0 && (
              <div className="col-6">
                <div>
                  <div className="market-title mt-1" style={{ width: "100%" }}>
                    Fancy Market
                    <a
                      href="javascript:void(0)"
                      className="m-r-5 game-rules-icon"
                    >
                      <span>
                        <i className="fa fa-info-circle float-right"></i>
                      </span>
                    </a>
                  </div>
                  <div className="table-header">
                    <div className="float-left country-name box-6"></div>
                    
                    <div
                      className="back box-1 float-left back text-center"
                      style={{ color: "black" }}
                    >
                      <b>Yes</b>
                    </div>
                    <div
                      className="box-1 float-left lay text-center"
                      style={{ color: "black" }}
                    >
                      <b>No</b>
                    </div>
                    <div className="box-2 float-left"></div>
                  </div>
                  <div className="table-body">
                    {odds?.fancyOddDetailsDTOS?.map((match, index) => (
                      <div
                        key={index}
                        className={`table-row `}
                        data-title="SUSPENDED"
                      >
                        <div className="float-left country-name box-6">
                          <p className="m-b-0">
                            <a href="javascript:void(0)">
                              {match.runnerName ? match.runnerName : match.nat}
                            </a>
                          </p>
                          <p className="m-b-0">
                            <span>0</span>
                          </p>
                        </div>

                        <div
                          data={odds}
                          match={match}
                          oddsKey={"back1"}
                          index={index}
                          isFancy={true}
                          className="box-1 back float-left back lock text-center"
                          onClick={(data_) => {
                            if (
                              getData(match.runnerName, "fancyOddDetailsDTOS")
                                .lay1 > 0
                            ) {
                              setEvent(
                                match.runnerName,
                                true,
                                "fancyOddDetailsDTOS"
                              );
                            }
                          }}
                        >
                          <span className="odd d-block">
                            {
                              getData(match.runnerName, "fancyOddDetailsDTOS")
                                .back1
                            }
                          </span>{" "}
                          <span className="d-block">
                            {
                              getData(match.runnerName, "fancyOddDetailsDTOS")
                                .backSize1
                            }
                          </span>
                        </div>
                        <div
                          data={odds}
                          isFancy={true}
                          match={match}
                          oddsKey={"lay1"}
                          index={index}
                          back={false}
                          className="box-1 lay float-left text-center betting-disabled"
                          onClick={(data_) => {
                            if (
                              getData(match.runnerName, "fancyOddDetailsDTOS")
                                .back1 > 0
                            ) {
                              setEvent(
                                match.runnerName,
                                false,
                                "fancyOddDetailsDTOS"
                              );
                            }
                          }}
                        >
                          <span className="odd d-block">
                            {
                              getData(match.runnerName, "fancyOddDetailsDTOS")
                                .lay1
                            }
                          </span>{" "}
                          <span className="d-block">
                            {
                              getData(match.runnerName, "fancyOddDetailsDTOS")
                                .laySize1
                            }
                          </span>
                        </div>

                        {/* <Flex
                        data-title={match.status}
                        className={
                          match.status != "ACTIVE" &&
                          match.status != "" &&
                          match.status != null
                            ? "suspendedtext"
                            : ""
                        }
                        ref={ref}
                      >
                        <OddsBox
                          data={odds}
                          isFancy={true}
                          match={match}
                          oddsKey={"lay1"}
                          index={index}
                          back={false}
                          onClick={(data_) =>
                            setEvent(
                              match.runnerName,
                              false,
                              "fancyOddDetailsDTOS"
                            )
                          }
                        />

                        <OddsBox
                          data={odds}
                          match={match}
                          oddsKey={"back1"}
                          index={index}
                          isFancy={true}
                          onClick={(data_) =>
                            setEvent(
                              match.runnerName,
                              true,
                              "fancyOddDetailsDTOS"
                            )
                          }
                        />
                      </Flex> */}
                        <div className="box-2 float-left text-right min-max"></div>
                      </div>
                    ))}
                  </div>
                  <div></div>
                </div>
              </div>
            )}
          </div>
        </div>
        <LastResults eventId={gameName} />
      </Box>

      <PlaceBet
        game="casino"
        eventId={gameName}
        inningsRule={
          <div className="row row5 cc-rules">
            <div className="col-6">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">AUS</h5>
                  <div className="row row5 mt-1">
                    <div
                      className="col-7"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      Cards
                    </div>
                    <div className="col-5 text-right">Value</div>
                  </div>
                </div>
                <div
                  className="card-body"
                  style={{ backgroundColor: "white", color: "black" }}
                >
                  <div className="row row5 mt-1">
                    <div
                      className="col-7"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img
                        style={{ height: "20px", width: "20px" }}
                        src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/cards/cricketv/A.jpg"
                      />{" "}
                      <span className="ml-2">X</span>{" "}
                      <span className="ml-2">10</span>
                    </div>
                    <div className="col-5 text-right value">1 Run</div>
                  </div>
                  <div className="row row5 mt-1">
                    <div
                      className="col-7"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img
                        style={{ height: "20px", width: "20px" }}
                        src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/cards/cricketv/2.jpg"
                      />{" "}
                      <span className="ml-2">X</span>{" "}
                      <span className="ml-2">10</span>
                    </div>
                    <div className="col-5 text-right value">2 Run</div>
                  </div>
                  <div className="row row5 mt-1">
                    <div
                      className="col-7"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img
                        style={{ height: "20px", width: "20px" }}
                        src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/cards/cricketv/3.jpg"
                      />{" "}
                      <span className="ml-2">X</span>{" "}
                      <span className="ml-2">10</span>
                    </div>
                    <div className="col-5 text-right value">3 Run</div>
                  </div>
                  <div className="row row5 mt-1">
                    <div
                      className="col-7"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img
                        style={{ height: "20px", width: "20px" }}
                        src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/cards/cricketv/4.jpg"
                      />{" "}
                      <span className="ml-2">X</span>{" "}
                      <span className="ml-2">10</span>
                    </div>
                    <div className="col-5 text-right value">4 Run</div>
                  </div>

                  <div className="row row5 mt-1">
                    <div
                      className="col-7"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img
                        style={{ height: "20px", width: "20px" }}
                        src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/cards/cricketv/6.jpg"
                      />{" "}
                      <span className="ml-2">X</span>{" "}
                      <span className="ml-2">10</span>
                    </div>
                    <div className="col-5 text-right value">6 Run</div>
                  </div>

                  <div className="row row5 mt-1">
                    <div
                      className="col-7"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img
                        style={{ height: "20px", width: "20px" }}
                        src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/cards/cricketv/10.jpg"
                      />{" "}
                      <span className="ml-2">X</span>{" "}
                      <span className="ml-2">10</span>
                    </div>
                    <div className="col-5 text-right value">0 Run</div>
                  </div>

                  <div className="row row5 mt-1">
                    <div
                      className="col-7"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img
                        style={{ height: "20px", width: "20px" }}
                        src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/cards/cricketv/K.jpg"
                      />{" "}
                      <span className="ml-2">X</span>{" "}
                      <span className="ml-2">10</span>
                    </div>
                    <div className="col-5 text-right value">Wicket</div>
                  </div>
                </div>
                <div className="card-footer"></div>
              </div>
            </div>
            <div className="col-6">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">IND</h5>
                  <div className="row row5 mt-1">
                    <div
                      className="col-7"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      Cards
                    </div>
                    <div className="col-5 text-right">Value</div>
                  </div>
                </div>
                <div
                  className="card-body"
                  style={{ backgroundColor: "white", color: "black" }}
                >
                  <div className="row row5 mt-1">
                    <div
                      className="col-7"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img
                        style={{ height: "20px", width: "20px" }}
                        src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/cards/cricketv/A.jpg"
                      />
                      <span className="ml-2">X</span>
                      <span className="ml-2">10</span>
                    </div>
                    <div className="col-5 text-right value">1 Run</div>
                  </div>

                  <div className="row row5 mt-1">
                    <div
                      className="col-7"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img
                        style={{ height: "20px", width: "20px" }}
                        src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/cards/cricketv/2.jpg"
                      />{" "}
                      <span className="ml-2">X</span>{" "}
                      <span className="ml-2">10</span>
                    </div>
                    <div className="col-5 text-right value">2 Run</div>
                  </div>
                  <div className="row row5 mt-1">
                    <div
                      className="col-7"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img
                        style={{ height: "20px", width: "20px" }}
                        src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/cards/cricketv/3.jpg"
                      />{" "}
                      <span className="ml-2">X</span>{" "}
                      <span className="ml-2">10</span>
                    </div>
                    <div className="col-5 text-right value">3 Run</div>
                  </div>
                  <div className="row row5 mt-1">
                    <div
                      className="col-7"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img
                        style={{ height: "20px", width: "20px" }}
                        src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/cards/cricketv/4.jpg"
                      />{" "}
                      <span className="ml-2">X</span>{" "}
                      <span className="ml-2">10</span>
                    </div>
                    <div className="col-5 text-right value">4 Run</div>
                  </div>

                  <div className="row row5 mt-1">
                    <div
                      className="col-7"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img
                        style={{ height: "20px", width: "20px" }}
                        src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/cards/cricketv/6.jpg"
                      />{" "}
                      <span className="ml-2">X</span>{" "}
                      <span className="ml-2">10</span>
                    </div>
                    <div className="col-5 text-right value">6 Run</div>
                  </div>

                  <div className="row row5 mt-1">
                    <div
                      className="col-7"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img
                        style={{ height: "20px", width: "20px" }}
                        src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/cards/cricketv/10.jpg"
                      />{" "}
                      <span className="ml-2">X</span>{" "}
                      <span className="ml-2">10</span>
                    </div>
                    <div className="col-5 text-right value">0 Run</div>
                  </div>

                  <div className="row row5 mt-1">
                    <div
                      className="col-7"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img
                        style={{ height: "20px", width: "20px" }}
                        src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/img/cards/cricketv/K.jpg"
                      />{" "}
                      <span className="ml-2">X</span>{" "}
                      <span className="ml-2">10</span>
                    </div>
                    <div className="col-5 text-right value">Wicket</div>
                  </div>
                </div>
                <div className="card-footer"></div>
              </div>
            </div>
          </div>
        }
        casinoGameKey="CRICKET_V3"
      />
    </Box>
  );
}
