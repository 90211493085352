import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/styles/app.scss";
import App from "./App";
import { ChakraProvider } from "@chakra-ui/react";
import { Provider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter } from "react-router-dom";
import axios from "axios";
import { ThemeProvider } from "@mui/material";
import createTheme from "@mui/material/styles/createTheme";

axios.defaults.headers.post["Content-Type"] = "application/json";

axios.interceptors.request.use(
  (reqConfig) => {
    const reqConfiglocal = reqConfig;
    const token = localStorage.getItem("token");

    if (token) {
      reqConfiglocal.headers.Authorization = `Bearer ${token}`;
    }
    return reqConfiglocal;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // localStorage.clear();
    return Promise.reject(error);
  }
);

const muiTheme = createTheme({});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ChakraProvider>
      <ThemeProvider theme={muiTheme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </ChakraProvider>
  </Provider>
);
