import React from 'react';

const CardGameMenuIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='24'
      viewBox='0 0 20 24'
      fill='none'
    >
      <g clip-path='url(#clip0_3_1497)'>
        <path
          d='M15.7058 8.58977C11.6737 5.37941 10.283 2.81026 9.34037 0.820923C8.39637 2.81026 7.00703 5.37941 2.97503 8.58977C-3.90097 14.0637 2.57103 21.7026 8.2617 17.4982C7.89103 20.1074 6.6257 22.0096 5.34037 22.8293V23.6719H13.3404V22.8293C12.055 22.0096 10.7897 20.1074 10.419 17.4982C16.1098 21.7026 22.5818 14.0637 15.7058 8.58977Z'
          fill='#40424F'
        />
      </g>
      <defs>
        <clipPath id='clip0_3_1497'>
          <rect y='0.820923' width='20' height='22.8496' rx='8' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CardGameMenuIcon;
