import { Box, FormLabel, Input } from "@chakra-ui/react";
import React from "react";

export default function CustomInput({ label, containerStyle, ...rest }) {
  return (
    <Box className={rest.className} style={containerStyle}>
      {label && (
        <FormLabel color="#000" fontSize="sm">
          {label}
        </FormLabel>
      )}
      <Input borderRadius="5px" borderColor="#ccc" size="sm" {...rest} />
    </Box>
  );
}
