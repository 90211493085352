import React, { useEffect, useState } from "react";
import { Stack, Typography, TextField, Button } from "@mui/material";
import reload from "../../assets/images/icons/reload.png";
import BasicDatePicker from "../shared/DatePicker";
import "../../assets/styles/css/send.scss";
import useSendMoney from "../../hooks/useSendMoney";
import CustomInput from "../CustomInput";
import { Grid } from "@chakra-ui/react";
import { getUserByUsernameAPI } from "../../service/AuthService";
import { getUserNameAPI } from "../../service/UserService";

export const SendMoney = ({ onClick }) => {
  const {
    payeeLoginId,
    setPayeeLoginId,
    payeeName,
    setPayeeName,
    remark,
    setRemark,
    userName,
    setUserName,
    transferAmount,
    setTransferAmount,
    securityPin,
    setSecurityPin,
    captchaCode,
    enteredCaptcha,
    handleEnteredCaptchaChange,
    isSubmitDisabled,
    handleButtonClick,
    handleRefreshCaptcha,
  } = useSendMoney();

  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);

  const getFullName = async (username) => {
    setLoading(true);
    try {
      const res = await getUserNameAPI(username.toUpperCase());
      console.log(res.response.name, "nameee")
      if (res) {
        setUserData(res.response);
        setPayeeName(res.response.name || "");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      setPayeeName("");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userName) {
      getFullName(userName);
    } else {
      setPayeeName("");
    }
  }, [userName]);

  return (
    <Stack className="main" margin={{ base: '0', md: '0 20%' }} style={{border:'1px gray solid', borderRadius:'10px'}}>
      <Stack className="main-1">
        <Typography className="send-text">Send Money</Typography>
      </Stack>

      <Grid
        templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
        gap={10}
      >

        <CustomInput
          label="Username :"
          type="text"
          placeholder={"Enter Username"}
          value={userName} // Ensure value is bound to state
          onChange={(e) => setUserName(e.target.value)}
        />
        <CustomInput
          label="Payee name :"
          type="text"
          placeholder={"Enter name"}
          value={payeeName} // Ensure value is bound to state
          onChange={(e) => setPayeeName(e.target.value)}
          isReadOnly={true}
        />
        <CustomInput
          label="Transfer amount :"
          type="number"
          placeholder={"Enter Amount"}
          value={transferAmount} // Ensure value is bound to state
          onChange={(e) => setTransferAmount(e.target.value)}
        />
          <CustomInput
            label="Password :"
            type="password"
            placeholder={"Enter Password"}
            value={securityPin} // Ensure value is bound to state
            onChange={(e) => setSecurityPin(e.target.value)}
          />
        <CustomInput
          label="Remark :"
          type="text"
          placeholder={"Enter Remark"}
          value={remark} // Ensure value is bound to state
          onChange={(e) => setRemark(e.target.value)}
        />
        <CustomInput
          label="Captcha :"
          type={"text"}
          placeholder={"Enter the given captcha code"}
          value={enteredCaptcha} // Ensure value is bound to state
          onChange={handleEnteredCaptchaChange}
        />
      </Grid>

      <Stack className="main-4" style={{ marginLeft: "50px" }}>
        <Typography className="txt">Regenerate Captcha:</Typography>
        <Typography className="txt-1">{captchaCode}</Typography>
        <img
          src={reload}
          className="reload-img"
          alt="refresh-icon"
          onClick={handleRefreshCaptcha}
        />
      </Stack>

      <Stack className="main-6">
        <Button
          className={`btn ${isSubmitDisabled ? 'btn-disabled' : ''}`}
          variant="contained"
          onClick={isSubmitDisabled ? null : handleButtonClick}
        >
          <Typography className="btn-text">Submit</Typography>
        </Button>
      </Stack>
    </Stack>
  );
};
