import React, { useCallback, useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { GenericTable } from '../../components/shared/GenericTable';
import { SearchWithText } from '../../components/shared/SearchWithText';
import { AXIOS_INSTANCE } from '../../service';
import { BASE_URL } from '../../utils/constants';

export const DirectUsers = () => {
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [directMembers, setDirectMembers] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const getDirectMembers = useCallback(async () => {
    try {
      const res = await AXIOS_INSTANCE.get(`${BASE_URL}/user/direct-members`);
      if (res.status === 200) {
        const data = res.data.response.map((j) => ({
          'Username': j.username,
          'Fullname': j.name,
          'Phone': j.contact,
          'Balance': j.balance,
          'Liability': j.liability,
          'P&L': j.ownPl,
          'Joined Date': j.createdAt,
        }));
        setDirectMembers(res.data.response);
        setFilteredTableData(data); 
        setTotalCount(res.data.response.length); 
      }
    } catch (error) {
      console.error('Error fetching direct members:', error);
    }
  }, []);

  useEffect(() => {
    getDirectMembers();
  }, [getDirectMembers]);

  const currentBetsTableHeader = [
    'Username',
    'Fullname',
    'P&L',
    'Liability',
    'Balance',
    'Phone',
    'Joined Date',
  ];

  const handleSearch = (searchTerm) => {
    const formattedSearchTerm = searchTerm.toLowerCase();

    const filteredData = directMembers.filter((data) =>
      ['username', 'userType', 'phone', 'balance', 'liability', 'ownPl'].some((key) =>
        String(data[key]).toLowerCase().includes(formattedSearchTerm)
      )
    );

    const mappedFilteredData = filteredData.map((j) => ({
      'Username': j.username,
      'Fullname': j.name,
      'Phone': j.contact,
      'Balance': j.balance,
      'Liability': j.liability,
      'P&L': j.ownPl,
      'Joined Date': j.createdAt,
    }));

    setFilteredTableData(mappedFilteredData);
  };

  return (
    <React.Fragment>
      <Stack className='current-bets-main-container'>
        <SearchWithText
          text={'Direct Users'}
          onSearchClick={(e) => handleSearch(e.target.value)}
        />
      </Stack>
      <Stack className='current-bets-main-container-2'>
        <GenericTable
          tableHeader={currentBetsTableHeader}
          tableRow={filteredTableData}
          setBetStatus={() => {}} 
          fromCasinoBets={false} 
        />
      </Stack>
    </React.Fragment>
  );
};
