import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Image,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  WrapItem,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaLock } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Flip, Flipr } from "../../../components/Flip";
import {
  getCasinoPlExposureAPI,
  placeCasinoBetAPI,
} from "../../../service/casino";
import PlaceBet from "../../game/PlaceBet";
import CardDrawer from "../../../components/CardDrawer/CardDrawer";
import { useMemo } from "react";
import Iframe from "react-iframe";
import IframeComponent from "../../../components/Iframe";
import CasinoVideoRules from "../../../components/CasinoVideoRules";
import { ASSETS_URL } from "../../../utils/constants";
import LastResults from "../../../components/LastResults";
import { setGameKey } from "../../../redux/features/gameSlice";
import { getService } from "../../../utils/commonFunctions";
import { useLocation } from "react-router-dom";

export default function TeenPattiTest() {
  const location = useLocation();
  const dispatch = useDispatch();
  const odds_ = useSelector((state) => state.gameData.teen9Odds);
  //useMemo
  const odds = useMemo(() => odds_, [odds_]);
  const [plExposure, setPlExposure] = useState(null);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [isLocked, setIsLocked] = useState(false);
  const userData = useSelector((state) => state.account.userData);
  const authDetails = useSelector((state) => state.account.authDetails);
  const toast = useToast();
  const gameName = "TEEN_9";
  const CustomButton = ({ text, item, ml, size, ...rest }) => (
    <Button
      size={size ? size : "sm"}
      ml={ml}
      display="flex"
      justifyContent="center"
      alignItems="center"
      className="pattiButton"
      onClick={isLocked ? null : rest.onClick}
    >
      {isLocked ? <FaLock /> : item ? item : text}
    </Button>
  );
  const getData = (id) => {
    let data = odds?.oddDetailsDTOS?.find((item) => item.runnerName == id);
    return data ? data : {};
  };

  const getProfit = (selectedEventForBet) => {
    return (selectedEventForBet.back1 - 1) * selectedAmount;
  };
  const setEvent = (id) => {
    let selectedEventForBet = getData(id);
    let payload = {};
    payload.amount = parseInt(selectedAmount);
    payload.casinoGames = gameName;
    payload.profit = getProfit(selectedEventForBet);
    payload.loss = parseInt(selectedAmount);
    payload.selectedOdd = selectedEventForBet.back1;
    payload.selectedId = selectedEventForBet.selectionId;
    payload.marketId = odds.marketId;
    payload.runnerName = selectedEventForBet.runnerName;
    payload.type = "back";

    dispatch({
      type: "gameData/setSelectedEventForBet",
      payload,
    });
  };

  useEffect(() => {
    dispatch(setGameKey("teen9"));
  }, [dispatch]);

  useEffect(() => {
    if (odds?.autoTime <= 5) {
      setIsLocked(true);
    } else {
      setIsLocked(false);
    }
  }, [odds, getData]);

  const getPlExposure = async (id) => {
    const res = await getCasinoPlExposureAPI(
      getService(location.pathname).port,
      getService(location.pathname).service,
      id,
      authDetails?.uid
    );
    if (res && res?.code == 200) {
      setPlExposure(res.response);
    }
  };
  const getExposureForEntity = (selectionName, selectionId) => {
    if (plExposure) {
      let data = plExposure?.find((item) =>
        item?.casinoPLExposure?.find(
          (exposure) => exposure.selectionName == selectionName
        )
      );

      return data ? data : null;
    }
  };
  useEffect(() => {
    let interval = setInterval(() => {
      if (odds?.marketId) {
        getPlExposure(odds?.marketId);
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [odds?.marketId]);
  return (
    <Box
      display="flex"
      flexDirection={{ base: "column", sm: "column", lg: "row" }}
      className="test-teen-patti-casino"
    >
      <Box mt="1" w={{ base: "100%", sm: "100%", lg: "70%" }} mr="1">
        <Box
          h="50vh"
          className="casino-video"
          overflow="hidden"
          mb="2"
          pos="relative"
        >
          {odds?.cards && (
            <CardDrawer
              multiLevel={true}
              cards={[odds?.cards[0], odds?.cards[1], odds?.cards[2]]}
              labels={["TIGER", "LION", "DRAGON"]}
            />
          )}
          <div className="market-title">TeenPatti Test</div>

          <IframeComponent
            url={`${process.env.REACT_APP_IFRAME_URL}?id=3048`}
          />
          <Box
            bottom="0"
            p="2"
            pos="absolute"
            right="2"
            display="flex"
            bg="black"
          >
            <Flip value={odds?.autoTime} />
          </Box>
          <CasinoVideoRules image={`${ASSETS_URL}test teenpatti-rules.jpg`} />
        </Box>

        {/* <div className="casino-container teenpatti-test"> */}
        <div className="table-responsive mb-1">
          <table className="table table-bordered mb-0">
            <thead>
              <tr>
                {/* <th className="box-4 min-max">Min:100 Max:300000</th> */}
                <th className="box-4 min-max"></th>
                <th colspan="3" className="box-6 back text-center">
                  BACK
                </th>
              </tr>
              <tr>
                <th className="box-4"></th>
                <th className="box-2 back text-center">
                  <b>Tiger</b>
                </th>
                <th className="box-2 back text-center">
                  <b>Lion</b>
                </th>
                <th className="box-2 back text-center">
                  <b>Dragon</b>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="box-4">
                  <b>Winner</b>
                </td>
                <td
                  className={`box-2 back text-center ${
                    isLocked ? "suspended" : ""
                  }`}
                  onClick={() => setEvent("Tiger Winner")}
                >
                  <span className="odds d-block">
                    <b>{getData("Tiger Winner").back1}</b>
                  </span>{" "}
                  <span> {getExposureForEntity("Tiger Winner")?.exposure}</span>
                </td>
                <td
                  className={`box-2 back text-center ${
                    isLocked ? "suspended" : ""
                  }`}
                  onClick={() => setEvent("Lion Winner")}
                >
                  <span className="odds d-block">
                    <b>{getData("Lion Winner").back1}</b>
                  </span>{" "}
                  <span> {getExposureForEntity("Lion Winner")?.exposure}</span>
                </td>
                <td
                  className={`box-2 back text-center ${
                    isLocked ? "suspended" : ""
                  }`}
                  onClick={() => setEvent("Dragon Winner")}
                >
                  <span className="odds d-block">
                    <b>{getData("Dragon Winner").back1}</b>
                  </span>{" "}
                  <span>
                    {" "}
                    {getExposureForEntity("Dragon Winner")?.exposure}
                  </span>
                </td>
              </tr>
              <tr>
                <td className="box-4">
                  <b>Pair</b>
                </td>
                <td
                  className={`box-2 back text-center ${
                    isLocked ? "suspended" : ""
                  }`}
                  onClick={() => setEvent("Tiger Pair")}
                >
                  <span className="odds d-block">
                    <b>{getData("Tiger Pair").back1}</b>
                  </span>{" "}
                  <span> {getExposureForEntity("Tiger Pair")?.exposure}</span>
                </td>
                <td
                  className={`box-2 back text-center ${
                    isLocked ? "suspended" : ""
                  }`}
                  onClick={() => setEvent("Lion Pair")}
                >
                  <span className="odds d-block">
                    <b>{getData("Lion Pair").back1}</b>
                  </span>{" "}
                  <span> {getExposureForEntity("Lion Winner")?.exposure}</span>
                </td>
                <td
                  className={`box-2 back text-center ${
                    isLocked ? "suspended" : ""
                  }`}
                  onClick={() => setEvent("Dragon Pair")}
                >
                  <span className="odds d-block">
                    <b>{getData("Dragon Pair").back1}</b>
                  </span>{" "}
                  <span>
                    {" "}
                    {getExposureForEntity("Dragon Winner")?.exposure}
                  </span>
                </td>
              </tr>
              <tr>
                <td className="box-4">
                  <b>Flush</b>
                </td>
                <td
                  className={`box-2 back text-center ${
                    isLocked ? "suspended" : ""
                  }`}
                  onClick={() => setEvent("Tiger Flush")}
                >
                  <span className="odds d-block">
                    <b>{getData("Tiger Flush").back1}</b>
                  </span>{" "}
                  <span>{getExposureForEntity("Tiger Flush")?.exposure}</span>
                </td>
                <td
                  className={`box-2 back text-center ${
                    isLocked ? "suspended" : ""
                  }`}
                  onClick={() => setEvent("Lion Flush")}
                >
                  <span className="odds d-block">
                    <b> {getData("Lion Flush").back1}</b>
                  </span>{" "}
                  <span>{getExposureForEntity("Lion Flush")?.exposure}</span>
                </td>
                <td
                  className={`box-2 back text-center ${
                    isLocked ? "suspended" : ""
                  }`}
                  onClick={() => setEvent("Dragon Flush")}
                >
                  <span className="odds d-block">
                    <b>{getData("Dragon Flush").back1}</b>
                  </span>{" "}
                  <span>{getExposureForEntity("Dragon Flush")?.exposure}</span>
                </td>
              </tr>
              <tr>
                <td className="box-4">
                  <b>Straight</b>
                </td>
                <td
                  className={`box-2 back text-center ${
                    isLocked ? "suspended" : ""
                  }`}
                  onClick={() => setEvent("Tiger Straight")}
                >
                  <span className="odds d-block">
                    <b>{getData("Tiger Straight").back1}</b>
                  </span>{" "}
                  <span>
                    {getExposureForEntity("Tiger Straight")?.exposure}
                  </span>
                </td>
                <td
                  className={`box-2 back text-center ${
                    isLocked ? "suspended" : ""
                  }`}
                  onClick={() => setEvent("Lion Straight")}
                >
                  <span className="odds d-block">
                    <b>{getData("Lion Straight").back1}</b>
                  </span>{" "}
                  <span>{getExposureForEntity("Lion Straight")?.exposure}</span>
                </td>
                <td
                  className={`box-2 back text-center ${
                    isLocked ? "suspended" : ""
                  }`}
                  onClick={() => setEvent("Dragon Straight")}
                >
                  <span className="odds d-block">
                    <b>{getData("Dragon Straight").back1}</b>
                  </span>{" "}
                  <span>
                    {getExposureForEntity("Dragon Straight")?.exposure}
                  </span>
                </td>
              </tr>
              <tr>
                <td className="box-4">
                  <b>Trio</b>
                </td>
                <td
                  className={`box-2 back text-center ${
                    isLocked ? "suspended" : ""
                  }`}
                  onClick={() => setEvent("Tiger Trio")}
                >
                  <span className="odds d-block">
                    <b>{getData("Tiger Trio").back1}</b>
                  </span>{" "}
                  <span> {getExposureForEntity("Tiger Trio")?.exposure}</span>
                </td>
                <td
                  className={`box-2 back text-center ${
                    isLocked ? "suspended" : ""
                  }`}
                  onClick={() => setEvent("Lion Trio")}
                >
                  <span className="odds d-block">
                    <b>{getData("Lion Trio").back1}</b>
                  </span>{" "}
                  <span> {getExposureForEntity("Lion Trio")?.exposure}</span>
                </td>
                <td
                  className={`box-2 back text-center ${
                    isLocked ? "suspended" : ""
                  }`}
                  onClick={() => setEvent("Dragon Trio")}
                >
                  <span className="odds d-block">
                    <b>{getData("Dragon Trio").back1}</b>
                  </span>{" "}
                  <span> {getExposureForEntity("Dragon Trio")?.exposure}</span>
                </td>
              </tr>
              <tr>
                <td className="box-4">
                  <b>Straight Flush</b>
                </td>
                <td
                  className={`box-2 back text-center ${
                    isLocked ? "suspended" : ""
                  }`}
                  onClick={() => setEvent("Tiger Straight Flush")}
                >
                  <span className="odds d-block">
                    <b>{getData("Tiger Straight Flush").back1}</b>
                  </span>{" "}
                  <span>
                    {getExposureForEntity("Tiger Straight Flush")?.exposure}
                  </span>
                </td>
                <td
                  className={`box-2 back text-center ${
                    isLocked ? "suspended" : ""
                  }`}
                  onClick={() => setEvent("Lion Straight Flush")}
                >
                  <span className="odds d-block">
                    <b>{getData("Lion Straight Flush").back1}</b>
                  </span>{" "}
                  <span>
                    {getExposureForEntity("Lion Straight Flush")?.exposure}
                  </span>
                </td>
                <td
                  className={`box-2 back text-center ${
                    isLocked ? "suspended" : ""
                  }`}
                  onClick={() => setEvent("Dragon Straight Flush")}
                >
                  <span className="odds d-block">
                    <b>{getData("Dragon Straight Flush").back1}</b>
                  </span>{" "}
                  <span>
                    {getExposureForEntity("Dragon Straight Flush")?.exposure}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* </div> */}
        <div className="casino-remark mt-1">
          <div className="remark-icon">
            <img
              src="https://sitethemedata.com/v120/static/front/img/icons/remark.png"
              alt="image"
            />
          </div>{" "}
          <marquee>
            Incase of Tie, The entire round will be cancelled || Odds are
            considered same as international exchange. || In "Trio" result,
            "Pair" should not be considered. || In "Straight Flush" result,
            "Straight" and "Flush" should not be considered.
          </marquee>
        </div>

        <LastResults eventId={gameName} />
      </Box>

      <PlaceBet game="casino" eventId={gameName} />
    </Box>
  );
}
