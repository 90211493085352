import Cricket from '../../../src/assets/images/icons/cricket.png';
import Football from '../../../src/assets/images/icons/football.png';
import Tennis from '../../../src/assets/images/icons/tennis.png';
import Casino from '../../../src/assets/images/icons/casino.png';
import CardGame from '../../../src/assets/images/icons/cardgame.png';
import Evolution from '../../../src/assets/images/icons/Evolution.png';
import Ezg from '../../../src/assets/images/icons/ezg.png';

export const images = [
  { img: Cricket, sport: 'cricket' },
  { img: Football, sport: 'soccer' },
  { img: Tennis, sport: 'tennis' },
  { img: Casino, sport: 'casino' },
  { img: CardGame, sport: 'card-game' },
  { img: Evolution, sport: 'casino' },
  { img: Ezg, sport: 'casino' },
];

export const images2 = [
  { img: Evolution, sport: 'casino' },
  { img: Ezg, sport: 'casino' },
];