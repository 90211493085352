import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userBankData: [],
  isLoading: false,
  userPaytmData: [],
  userGpayData: [],
  userPhonePeData: [],
  userBhimData: [],
};

const paymetDetailsSlice = createSlice({
  name: 'paymentDetails',
  initialState,
  reducers: {
    // Action to add bank data
    addUserBankData: (state, action) => {
      state.userBankData = action.payload;
    },

    // Action to add Paytm data
    addUserPaytmData: (state, action) => {
      state.userPaytmData = action.payload;
    },

    // Action to add Google Pay data
    addUserGpayData: (state, action) => {
      state.userGpayData = action.payload;
    },

    // Action to add PhonePe data
    addUserPhonePeData: (state, action) => {
      state.userPhonePeData = action.payload;
    },

    // Action to add Bhim data
    addUserBhimData: (state, action) => {
      state.userBhimData = action.payload;
    },

    // Action to set loading state
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  addUserBankData,
  addUserPaytmData,
  addUserGpayData,
  addUserPhonePeData,
  addUserBhimData,
  setLoading,
} = paymetDetailsSlice.actions;

export default paymetDetailsSlice.reducer;
