import React, { useCallback, useEffect, useState } from 'react';
import { Stack } from '@mui/material';

import { GenericTable } from '../../components/shared/GenericTable';
import '../../assets/styles/css/current.scss';
import { getCurrentBets } from '../../service/AuthService';
import { SearchWithText } from '../../components/shared/SearchWithText';
import { AXIOS_INSTANCE } from '../../service';
import { BASE_URL_BANKING } from '../../utils/constants';
import { useSelector } from 'react-redux';

export const DepositHistory = () => {
  const userData = useSelector((state) => state.account.userData);
  const [currentBetsData, setCurrentBetsData] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [depositData, setDepositData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const getDepositData = useCallback(async () => {
    const payload = {
      transactionTypes: ['DEPOSIT_TO_WALLET'],
      userId: userData?.id,
    };
    const res = await AXIOS_INSTANCE.post(
      `${BASE_URL_BANKING}/api/admin/user/transactions?pageNumber=${currentPage - 1
      }&size=${pageSize}`,
      payload
    );

    if (res.status === 200) {
      const data = res.data.data.data.content.map((j) => {
        return {
          ['Transaction Id']: j.transactionId,
          // ['UserId']: j.toUserId,
          ['Coins']: j.coins,
          ['Approved Coins']: j.approvedCoins,
          ['Transaction Status']: j.transactionStatus,
          ['Transaction Type']: j.transactionType,
          ['Generated At']: j.requestGenerated,
          // ['Validated At']: j.requestValidated,
        };
      });
      setDepositData(data);
      setTotalCount(res.data.data.data.totalElements);
    }
  }, [currentPage, pageSize]);

  useEffect(() => {
    getDepositData();
  }, [getDepositData]);

  const currentBetsTableHeader = [
    'Transaction Id',
    // 'UserId',
    'Coins',
    'Approved Coins',
    'Transaction Status',
    'Transaction Type',
    'Generated At',
    // 'Validated At',
  ];

  const handleSearch = (searchTerm) => {
    const formattedSearchTerm = searchTerm.toLowerCase();

    const filteredData = depositData.filter((data) => {
      const srNoMatch = String(data.transactionId)
        .toLowerCase()
        .includes(formattedSearchTerm);
      const sportsMatch = String(data.toUserName)
        .toLowerCase()
        .includes(formattedSearchTerm);

      return srNoMatch || sportsMatch;
    });

    setFilteredTableData(filteredData);
  };

  console.log(filteredTableData, 'filteredTableData');

  return (
    <React.Fragment>
      <Stack className='current-bets-main-container-2'>
        <Stack className="search-with-text-satck">
          <Stack className="search-with-text-satck-inner-1">
            <p className="search-with-text-style">Deposit History</p>
          </Stack>
        </Stack>

        <GenericTable
          tableHeader={currentBetsTableHeader}
          tableRow={depositData}
          currentBetsData={depositData}
          setBetStatus={() => { }}
          fromCasinoBets
          dipositHistory
        />
      </Stack>
    </React.Fragment>
  );
};
