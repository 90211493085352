import {
  BASE_URL,
  CASINO_SERVICE_URL,
  IS_PROD,
  getToken,
} from '../../utils/constants';
import store from '../../redux/store';
import { AXIOS_INSTANCE } from '..';
const { dispatch } = store;

export const placeCasinoBetAPI = async (port, service, payload, token) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${CASINO_SERVICE_URL}${
        !IS_PROD ? ':' + port : ''
      }/${service}/casino/bet/place`,
      {
        ...payload,
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getMyBetsCasinoAPI = async (port, service, casinoGames, uid) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${CASINO_SERVICE_URL}${
        !IS_PROD ? ':' + port : ''
      }/${service}/casino/my-bet`,
      {
        params: {
          casinoGames,
          uid,
        },
      }
    );

    return data;
  } catch (error) {
    console.log(error.message, 'error.message');
    return error;
  }
};
export const getCasinoLastTenResultsAPI = async (
  port,
  service,
  casinoGames,
  token
) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${CASINO_SERVICE_URL}${
        !IS_PROD ? ':' + port : ''
      }/${service}/casino/last-10-results`,
      {
        params: {
          casinoGames,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getCasinoReportHistory = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/user/casino-report-history`,
      {
        ...payload,
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getIndividualResultAPI = async (
  port,
  service,
  marketId,
  casinoKey
) => {
  try {
    const query = casinoKey ? `&casinoGame=${casinoKey}` : '';
    const { data } = await AXIOS_INSTANCE.get(
      `${CASINO_SERVICE_URL}${
        !IS_PROD ? ':' + port : ''
      }/${service}/casino/individaul-result?marketId=${marketId}${query}`
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getCasinoPlExposureAPI = async (port, service, marketId) => {
  try {
    const token = localStorage.getItem('token');
    const { data } = await AXIOS_INSTANCE.get(
      `${CASINO_SERVICE_URL}${
        !IS_PROD ? ':' + port : ''
      }/${service}/casino/pl-exposure?marketId=${marketId}`,
      {
        headers: {
          Authorization: `Bearer ${getToken() || token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
