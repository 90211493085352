import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import copy from 'copy-to-clipboard';
import { Button } from '@mui/material';

const USDTPayment = ({
  paymentDetails,
  handleUpiDataChange,
  upiData,
  handleFileChange,
  handleBANK_CURRENTSubmit,
  file,
  selectedPaymentOption,
}) => {
  console.log(upiData, 'Upi data');
  return (
    <>
      <Box p={'25px'}>
        <div
          id='offline_deposit_content_UPI_GPAY'
          data-payment_type='UPI_BHIM'
          className='depo_method'
          style={{ display: 'block' }}
        >
          {!paymentDetails?.usdt ? (
            <Flex align={'center'} justify={'center'} p={'25px'}>
              <Text>No Active payment details available</Text>
            </Flex>
          ) : (
            <form
              id='UPI_PAYTM_deposit_wallet'
              className='offline_deposit offline_wallet_deposit'
              data-toggle='validator'
              role='form'
              enctype='multipart/form-data'
              novalidate='novalidate'
              autocomplete='off'
            >
              <div className='method_head'>Transfer Details</div>
              <h6 className='method_subhead text-italic'>
                *Please make your payment on the details below and submit
                transaction id
              </h6>
              <Text>
                USDT address - <b>{paymentDetails?.usdt?.upiId}</b>
              </Text>
              <Text color={'green !important'} mb='10px'>
                OR SCAN
              </Text>
              <img
                height={150}
                width={150}
                style={{ height: '150px', width: '150px' }}
                src={paymentDetails?.usdt?.qrCodeImageUrl}
              />
              <div className='valresponse mt-3'></div>
              <Flex gap={'20px'}>
                <Box w={'50%'}>
                  {' '}
                  <div className='method_head'>Your Payment Details</div>
                  <div className='deposit_section_in bank_trans_sec1'>
                    <div className='form-deposits'>
                      <label className='form-float-label'>
                        Amount <small>Min:100 Max: 10000000</small>
                      </label>
                      <input
                        className='form-control number_only only-numeric valid do-alphn'
                        type='number'
                        id='wallet_amount_UPI_PAYTM'
                        name='amount'
                        min='100'
                        max='10000000'
                        data-maxlen='8'
                        placeholder=''
                        autocomplete='off'
                        value={upiData.amount}
                        onChange={handleUpiDataChange}
                      />
                    </div>

                    <div className='form-deposits'>
                      <label className='form-float-label'>
                        Ref. No. / Trans. ID / UTR{' '}
                      </label>
                      <input
                        className='form-control do-alphn'
                        type='text'
                        id='utr_number'
                        value={upiData.utr_number}
                        name='utr_number'
                        placeholder=''
                        autocomplete='off'
                        data-maxlen='25'
                        onChange={handleUpiDataChange}
                      />
                    </div>

                    <div className=''>
                      <label className='form-float-label'>
                        Upload Payment Snap{' '}
                      </label>
                      <input
                        className='form-control utr_number do-alphn'
                        type='file'
                        id='utr_number'
                        name='utr_number'
                        placeholder=''
                        required=''
                        autocomplete='off'
                        data-maxlen='25'
                        onChange={handleFileChange}
                      />
                    </div>
                    <div
                      className='form-deposits promocode-area'
                      id='promocode-html-append-UPI_PAYTM'
                    ></div>
                  </div>
                  <div
                    className='acc_details text-center'
                    style={{ marginTop: '12px' }}
                  >
                    <input
                      type='hidden'
                      id='offline_payment_UPI_PAYTM'
                      name='payment_type'
                      value='UPI_PAYTM'
                      autocomplete='off'
                    />

                    <Button
                      variant='contained'
                      onClick={handleBANK_CURRENTSubmit}
                      className='submit-button'
                      disabled={
                        !upiData.amount ||
                        (!file && !upiData.utr_number) ||
                        (!upiData.utr_number && !file)
                      }
                    >
                      Submit
                    </Button>
                  </div>
                </Box>
              </Flex>
            </form>
          )}
        </div>
      </Box>
    </>
  );
};

export default USDTPayment;
